import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "../../../components/FossilyticsChart";
import { useMemo } from "react";
import { ModuleGazPzInit, ModuleGazPzUser } from "./model";
import useThemeStyling from "@/utils/useThemeStyling";

interface ModuleGazPzOutputViewProps {
  isLoading: boolean;
  dataPoints: number[][];
  pz: ModuleGazPzInit | undefined;
  pzUser: ModuleGazPzUser | undefined;
}

function ModuleGazPzOutputView({ isLoading, dataPoints, pz, pzUser }: Readonly<ModuleGazPzOutputViewProps>) {
  const { palette } = useThemeStyling();

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Cum. Production (MMscf)", type: "value", color: palette.customColor.black }],
    [palette]
  );
  const yAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "P/z", type: "value", color: palette.customColor.black, min: 0 }], [palette]);
  const series = useMemo<FossilyticsChartSeries[]>(
    () => [
      {
        name: "Raw data",
        type: "scatter",
        color: palette.customColor.red,
        data: pz ? pz.pzs.map((v, i) => [dataPoints[i][0], v]) : [],
      },
      {
        name: "Best fit",
        type: "line",
        hideSymbol: true,
        color: palette.customColor.blue,
        data: pz ? pz.best_fit : [],
      },
      {
        id: "m",
        name: "Manual Fit",
        type: "line",
        hideSymbol: true,
        color: palette.customColor.red,
        data: pzUser ? pzUser.line : [],
      },
    ],
    [palette, dataPoints, pz, pzUser]
  );

  return <FossilyticsChart id="gaz_pz_output" isLoading={isLoading} xAxes={xAxes} yAxes={yAxes} series={series} />;
}

export default ModuleGazPzOutputView;
