import "../../ModuleAutoRTA.css";
import GasPvtInputField from "./GasPvtInputField";
import GasPvtChart from "./GasPvtChart";
import { ModelComponentsParamProps } from "../../hooks/AutoRtaContext";

const GasPvtContainer = ({ value, calculateValue, isLoading, updateInputGridValue, validationError }: ModelComponentsParamProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2.8fr",
      }}
    >
      <GasPvtInputField validationError={validationError} value={value} isLoading={isLoading} updateInputGridValue={updateInputGridValue} />
      <GasPvtChart calculateValue={calculateValue} isLoading={isLoading} />
    </div>
  );
};

export default GasPvtContainer;
