import React from "react";
import { Row, CellChange } from "@silevis/reactgrid";

// utils
import useThemeStyling from "@/utils/useThemeStyling";
import { formatNumber } from "@/util";

// models
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { AutoRtaForecastV2 } from "@/models/gaz/autoRta";

//component
import InputField from "@/components/fields/InputField";

// constant
import { dataTableColumns, forecastEventColumn } from "../../constants";

import LoadingIndicator from "@/components/LoadingIndicator";
import { SummaryCard } from "@/models/Generic";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import SummaryTable from "@/components/SummaryTable";
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

export type AutoRtaForecastSettingProps = {
  onChangeInput: (payload: { [key: string]: any }) => void;
  forecastInput: AutoRtaForecastV2;
  onChangeTableInput: (changes: CellChange[]) => void;
  errorInputValidation?: ErrorValidationDetail[];
  forecastEventsRow: Row[];
  autoRtaForecastParameter?: SummaryCard[];
  isLoading: boolean;
  onClickValidate: () => void;
};

const AutoRtaForecastSetting = ({
  onChangeInput,
  forecastInput,
  onChangeTableInput,
  errorInputValidation,
  forecastEventsRow,
  autoRtaForecastParameter = [],
  isLoading,
  onClickValidate,
}: AutoRtaForecastSettingProps) => {
  const { elevatedCard, palette } = useThemeStyling();

  return (
    <div>
      <div style={elevatedCard}>
        <h3 style={{ color: palette.primary.main, margin: 0 }}>Forecast Settings</h3>

        <div>
          <InputField
            type="float"
            label="Economic Cutoff"
            suffix="MMscf/D"
            value={forecastInput.economic_cutoff}
            debounceDelay={1000}
            onChange={(v) => {
              onChangeInput({
                economic_cutoff: v,
              });
            }}
            disabled={isLoading}
            errors={errorInputValidation}
          />

          <FormControlLabel
            control={
              forecastInput ? (
                <Checkbox
                  onChange={(e) => {
                    onChangeInput({
                      smart_fill: e.target.checked,
                    });
                  }}
                  checked={forecastInput.smart_fill}
                  size="small"
                  disabled={isLoading}
                />
              ) : (
                <></>
              )
            }
            label="Smart Fill"
          />

          <div
            style={{
              marginTop: 20,
              paddingBottom: 15,
            }}
          >
            <ReactGridTable
              viewWidth={410}
              viewHeight={360}
              columns={forecastEventColumn}
              rows={forecastEventsRow}
              enableRangeSelection
              enableColumnSelection
              stickyTopRows={2}
              onCellsChanged={onChangeTableInput}
            />
          </div>

          <Button
            onClick={(e) => {
              e.preventDefault();
              onClickValidate();
            }}
            disabled={isLoading}
            style={{ color: "white", width: "100%" }}
            variant="contained"
          >
            Calculate Forecast
          </Button>
        </div>
      </div>
      <div style={elevatedCard}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <SummaryTable
            rows={autoRtaForecastParameter.map((item: any, i) => {
              return {
                parameter: item.parameter,
                value: formatNumber(Number(Number(item.value).toFixed(2))),
                units: item.unit,
              };
            })}
            headers={dataTableColumns}
          />
        )}
      </div>
    </div>
  );
};

export default AutoRtaForecastSetting;
