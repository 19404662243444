import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { DataSet, isDataSet, Project } from "@/model";
import { ApiError } from "@/models/APIGeneric";
import { ChartData, mlForecastScheme, MLForecastState } from "@/models/machineLearning";
import { postCalculateMachineLearning } from "@/models/machineLearning/calculateMachineLearning";

import { parseErrorThrown } from "@/utils/errorHandling";
import useThemeStyling from "@/utils/useThemeStyling";

import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";

type UseMachineLearningProps = {
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;
  selectedDataSets: DataSet | DataSet[] | undefined;
  project?: Project;
};

const useMachineLearning = ({ setApiError, project, apiError, selectedDataSets }: UseMachineLearningProps) => {
  const { palette } = useThemeStyling();

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  const { isLoading, isFetching, data } = useQuery({
    queryKey: ["calculate-machine-learning", dataSets, project],
    queryFn: async () => {
      if (!project?.id) return;
      return postCalculateMachineLearning({
        data_set_ids: dataSets,
        projectId: project?.id,
      });
    },
    select(data: any) {
      try {
        if (data.data) {
          const parsed = mlForecastScheme.parse(data.data);
          return parsed;
        }
        return data.data as MLForecastState;
      } catch (error) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!project?.id,
  });

  const colorList = useMemo(() => {
    return [palette.customColor.red, palette.customColor.blue, palette.customColor.pinkLight, palette.customColor.purple];
  }, [palette.customColor.red, palette.customColor.blue, palette.customColor.pinkLight, palette.customColor.purple]);

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    // group yaxis by unit
    let result: FossilyticsChartAxis[] = [];
    if (!data?.analysed_data.series || !data?.forecast_result.series) return result;

    [...data.analysed_data.series, ...data.forecast_result.series].forEach((serie) => {
      // only take unique unit
      const sameUnitAxisIndex = result.findIndex((axis) => axis.name === serie.unit);
      if (sameUnitAxisIndex === -1) {
        const position = Number(result.length) > 1 ? "right" : "left";

        let axis = result.length - 1;
        if (axis < 0) axis = 0;
        result.push({
          name: serie.unit ?? "",
          type: "value",
          color: colorList[result.length],
          position,
          nameGap: 20,
          offset: 0,
          axis,
        });
      }
    });

    const totalPosLeft = result.filter((res) => res.position === "left").length;
    const totalPosRight = result.length - totalPosLeft;
    if (totalPosLeft > 1) {
      result[result.findIndex((res) => res.position === "left")].offset = 85;
    }
    if (totalPosRight > 1) {
      // @ts-ignore
      result[result?.findLastIndex((res: FossilyticsChartAxis) => res.position === "right")].offset = 85;
    }
    return result;
  }, [colorList, data]);

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    if (!data) return [];
    return [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }];
  }, [data, palette.customColor.black]);

  const convertSerieAxis = useCallback(
    (type: "scatter" | "line", chartData?: ChartData) => {
      const result: FossilyticsChartSeries[] = [];

      chartData?.series.forEach((dataSerie) => {
        const defaultUnit = dataSerie.unit ?? "";
        const chartAxis = yAxes.findIndex((axis) => axis.name === defaultUnit);
        if (chartAxis !== -1) {
          const unitLabel = defaultUnit ? `(${defaultUnit})` : "";
          result.push({
            name: `${dataSerie.label} ${unitLabel}`,
            type,
            color: yAxes?.[chartAxis]?.color ?? palette.customColor.black,
            z: 0,
            data: chartData?.dates.map((date, index) => [new Date(date), dataSerie.data[index]]),
            yAxisIndex: chartAxis,
          });
        }
      });

      return result;
    },
    [palette.customColor.black, yAxes]
  );

  const series = useMemo<FossilyticsChartSeries[]>(() => {
    return [...convertSerieAxis("scatter", data?.analysed_data), ...convertSerieAxis("line", data?.forecast_result)];
  }, [convertSerieAxis, data?.analysed_data, data?.forecast_result]);

  return {
    loading: isLoading || isFetching,
    xAxes,
    yAxes,
    series,
  };
};

export default useMachineLearning;
