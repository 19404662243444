import { ModuleIdentity, SmoothingTypeEnum } from "../Generic";
import { object, z, number, nativeEnum, string } from "zod";

export type TahkCsgDataviewRequestPayload = {
  analysis_identity: ModuleIdentity;
  dataview: TahkCsgDataview;
};

export const tahkCsgDataviewScheme = object({
  atmospheric_correction: number(),
  smoothing_type: nativeEnum(SmoothingTypeEnum),
  moving_avg_window: number(),
  smoothing_points: number(),
});

export type TahkCsgDataview = z.infer<typeof tahkCsgDataviewScheme>;

const tahkCsgDataViewPointsScheme = object({
  dates: string().date().array().nullable(),
  gas_rate: number().array().nullable(),
  water_rate: number().array().nullable(),
  cumulative_gas_production: number().array().nullable(),
  cumulative_water_production: number().array().nullable(),
  casing_pressure: number().array().nullable(),
  tubing_pressure: number().array().nullable(),
  bottomhole_pressure: number().array().nullable(),
});

export type TahkCsgDataViewPoints = z.infer<typeof tahkCsgDataViewPointsScheme>;

export const tahkCsgDataviewResponseScheme = object({
  clean_data: tahkCsgDataViewPointsScheme,
  raw_data: tahkCsgDataViewPointsScheme,
  number_raw_data_points: number(),
});
export type TahkCsgDataviewResponse = z.infer<typeof tahkCsgDataviewResponseScheme>;
