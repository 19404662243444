import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, IDropdownOption, Pivot, PivotItem, Stack, Text } from "@fluentui/react";
import { shallow } from "zustand/shallow";
import { isEqual } from "lodash";

import {
  DataCleanOptions,
  DataLoadOptions,
  DataResponse,
  DataSet,
  Field,
  ModuleId,
  getFieldName,
  getFieldUnit,
  getFormattedNumberByField,
} from "../model";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "../components/FossilyticsChart";
import FossilyticsNumberField from "../components/fields/FossilyticsNumberField";
import { DEBOUNCE_DELAY, transpose } from "../util";
import FossilyticsGrid, { FossilyticsGridColumn, FossilyticsGridColumnGroup } from "../components/FossilyticsGrid";
import { useAppStore } from "@/features/app";
import { SPAD_DECLINE_MODULES } from "@/features/modules/spad/decline/index.configs";
import { useSettings, useSettingState } from "@/SettingsState";

import { loadCleanRawData, loadRawData } from "@/constants/apiUrl";
import useThemeStyling from "@/utils/useThemeStyling";
import { moduleDictionary } from "@/components/Modules/constants";

const importSamplingIntervalOptions = [
  { key: "1-day", text: "Day" },
  { key: "6-hour", text: "6 hour", disabled: true },
  { key: "1-hour", text: "1 hour", disabled: true },
] as IDropdownOption[];

const cleaningSmoothingTypeOptions = [
  { key: "interp", text: "Interpolation" },
  { key: "movavg", text: "Moving average" },
] as IDropdownOption[];

const cleaningSmoothingInterpOptions = [
  { key: 0, text: "0", disabled: true },
  { key: 1, text: "1" },
  { key: 2, text: "2", disabled: true },
  { key: 3, text: "3", disabled: true },
] as IDropdownOption[];

const cleaningSmoothingMovAvgOptions = [
  { key: 1, text: "1" },
  { key: 3, text: "3" },
  { key: 5, text: "5" },
  { key: 10, text: "10" },
  { key: 15, text: "15" },
  { key: 20, text: "20" },
  { key: 25, text: "25" },
] as IDropdownOption[];

export interface ModuleDataViewField {
  yAxis: string;
  defaultDisabled?: boolean;
  onlyCombined?: boolean;
  color: string;
}

interface ModuleDataViewProps {
  moduleFields: { [k in Field]?: ModuleDataViewField };
  showCombinedFirst?: boolean;
  showAtmosphericCorrection: boolean;
  onDataCleaned: (cleaned: DataResponse | undefined) => void;
  extraOptionsTitle?: string;
  isActive?: boolean;
  setLoading?: (val: boolean) => void;
}

const ModuleDataView = ({
  moduleFields,
  showCombinedFirst,
  showAtmosphericCorrection,
  onDataCleaned,
  extraOptionsTitle,
  children,
  isActive,
  setLoading,
}: PropsWithChildren<ModuleDataViewProps>) => {
  const {
    currentModule,
    isLoading,
    hideSidebar,
    pollRequest,
    selectedDataSets,
    cancelRequests,
    project,
    selectedKey,
    setCsvData,
    group,
    setIsLoading,
  } = useAppStore(
    (state) => ({
      currentModule: state.currentModule,
      isLoading: state.isLoading,
      hideSidebar: state.hideSidebar,
      pollRequest: state.pollRequest,
      selectedDataSets: state.selectedDataSets,
      cancelRequests: state.cancelRequests,
      project: state.project,
      selectedKey: state.selectedKey,
      setCsvData: state.setCsvData,
      group: state.group,
      setIsLoading: state.setIsLoading,
    }),
    shallow
  );

  const { settings } = useSettings();

  const dataSets = useMemo(() => (selectedDataSets && !Array.isArray(selectedDataSets) ? [selectedDataSets] : selectedDataSets), [selectedDataSets]);

  const oldDataSets = useRef<DataSet[]>([]);
  const [rawData, setRawData] = useState<DataResponse>();
  const [cleaned, setCleaned] = useState<DataResponse>();

  const [fetchingData, setFetchingData] = useState(false);

  const oldLoadOptions = useRef<DataLoadOptions>();
  const [loadOptions, setLoadOptions] = useSettingState<DataLoadOptions>("data_view_load_options", false, {
    default: {
      atmospheric_correction: 14.7,
      sampling_interval: importSamplingIntervalOptions[0].key as string,
    },
  });
  const oldCleanOptions = useRef<DataCleanOptions>();
  const [cleanOptions, setCleanOptions] = useSettingState<DataCleanOptions>("data_view_clean_options", false, {
    default: {
      smoothing_type: cleaningSmoothingTypeOptions[0].key as string,
      interpolation_order: cleaningSmoothingInterpOptions[1].key as number,
      moving_avg_window: cleaningSmoothingMovAvgOptions[3].key as number,
    },
  });
  const oldSmoothingPoints = useRef<number>();
  const [smoothingPoints, setSmoothingPoints] = useSettingState<number>("data_view_smoothing_points", false);

  const [forecastStartDate] = useSettingState<Date>("forecast_start_date", true);
  const [errorFetching, setErrorFetching] = useState(false);

  const [dataTableActive, setDataTableActive] = useState(false);

  const haveInitialize = useRef(false);
  // this flag is to determine we have mapped above state from saved settings
  const isStateReady = useRef(false);

  const dataSetIds = useMemo(() => {
    return dataSets?.map((d) => d.id) ?? [];
  }, [dataSets]);

  useEffect(() => {
    setRawData(undefined);
    setCleaned(undefined);
    setErrorFetching(false);

    cancelRequests();
    haveInitialize.current = false;
    isStateReady.current = false;
  }, [cancelRequests, selectedDataSets]);

  const pollRawData = useCallback(async () => {
    return await pollRequest(
      loadRawData,
      {
        module_id: currentModule,
      },
      { ...loadOptions, data_set_ids: dataSetIds }
    );
  }, [currentModule, loadOptions, pollRequest, dataSetIds]);

  const pollRawCleanedData = useCallback(
    async (smoothing_points: number) => {
      return await pollRequest(
        loadCleanRawData,
        {
          module_id: currentModule,
        },
        {
          data_set_ids: dataSetIds,
          ...cleanOptions,
          smoothing_points,
        }
      );
    },
    [pollRequest, currentModule, dataSetIds, cleanOptions]
  );

  const fetchDataView = useCallback(
    async (isInitialize?: boolean) => {
      setIsLoading(true);
      setLoading?.(true);
      setFetchingData(true);
      setErrorFetching(false);

      try {
        let smoothing_points = smoothingPoints;
        await pollRawData()
          .then(async (newRawData) => {
            if (smoothing_points == null) {
              smoothing_points = Math.min(newRawData!.data.length, 2500);
              setSmoothingPoints(smoothing_points);
            }
            oldLoadOptions.current = loadOptions;
            setRawData(newRawData);
            return await pollRawCleanedData(smoothing_points);
          })
          .then((newCleaned) => {
            oldCleanOptions.current = cleanOptions;
            oldSmoothingPoints.current = smoothing_points;
            setCleaned(newCleaned);
            onDataCleaned(newCleaned);
          })
          .catch((err) => {
            console.log(err);
            setErrorFetching(true);
          })
          .finally(() => {
            setFetchingData(false);
            setIsLoading(false);
            setLoading?.(false);
            oldDataSets.current = dataSets ?? [];
            haveInitialize.current = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    // TODO: FIX this logic
    // exclude savesingle setting for now it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      smoothingPoints,
      pollRawData,
      loadOptions,
      pollRawCleanedData,
      setSmoothingPoints,
      cleanOptions,
      onDataCleaned,
      project?.id,
      project?.groups,
      dataSetIds,
      dataSets,
    ]
  );

  // we need to initialize state before first api fetch
  useEffect(() => {
    // populate saved data to state for fetching raw and cleaned data
    if (isStateReady.current || haveInitialize.current) return;

    const currentProject = project ? settings?.[project?.id] : undefined;
    const groupId = selectedKey?.split(";")[1]?.split(":")[1] ?? "";

    // find the exact data by using project id & data set id
    if (currentProject && groupId) {
      const selectedGroupItem = currentProject.groups?.[groupId].filter((dataSetList) => isEqual(dataSetList.data_set_ids, dataSetIds));
      if (selectedGroupItem.length > 0) {
        const selectedSetting = selectedGroupItem[0].data;
        if (selectedSetting.data_view_smoothing_points) setSmoothingPoints(selectedSetting.data_view_smoothing_points);
        if (selectedSetting.data_view_load_options?.atmospheric_correction && selectedSetting.data_view_load_options?.sampling_interval) {
          setLoadOptions({
            atmospheric_correction: selectedSetting.data_view_load_options.atmospheric_correction,
            sampling_interval: selectedSetting.data_view_load_options.sampling_interval,
          });
        }
        if (
          selectedSetting.data_view_clean_options?.smoothing_type &&
          selectedSetting.data_view_clean_options?.interpolation_order &&
          selectedSetting.data_view_clean_options.moving_avg_window
        ) {
          setCleanOptions({
            smoothing_type: selectedSetting.data_view_clean_options.smoothing_type,
            interpolation_order: selectedSetting.data_view_clean_options.interpolation_order,
            moving_avg_window: selectedSetting.data_view_clean_options.moving_avg_window,
          });
        }
      }
    }
    isStateReady.current = true;
  }, [dataSetIds, project, setCleanOptions, setLoadOptions, setSmoothingPoints, settings, haveInitialize, isStateReady, selectedKey]);

  // on every data set, group, project and user input changes always trigger this hooks
  // this hooks only triggered on initialize
  useEffect(() => {
    if (!dataSets || dataSets.length === 0 || isLoading || haveInitialize.current || !isStateReady.current) return;

    if (loadOptions && (oldDataSets.current !== dataSets || oldLoadOptions.current !== loadOptions || !rawData) && !fetchingData) {
      fetchDataView(true);
    }
  }, [dataSets, fetchingData, isLoading, loadOptions, fetchDataView, rawData]);

  // this hooks only run after initialize, when user changes the input
  useEffect(() => {
    if (haveInitialize.current) {
      fetchDataView();
    }
  }, [loadOptions, cleanOptions, haveInitialize, fetchDataView]);

  const { palette, elevatedCard } = useThemeStyling();

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [{ name: "Date", type: "time", color: palette.customColor.black }];
  }, [palette.customColor.black]);

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    const yAxesNames = Array.from(new Set(Object.values(moduleFields).map((f) => f.yAxis)));
    return [
      ...yAxesNames.map((yAxis) => ({ name: yAxis, type: "value", color: palette.customColor.black, min: 0 })),
      { name: "End Date Axis", show: false, type: "value", color: "", min: -8e10, max: +8e10 },
    ];
  }, [moduleFields, palette.customColor.black]);

  const rawSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleFields)
        .map((v) => v as Field)
        .map((f) => ({
          name: `${getFieldName(f)} (raw)`,
          type: "scatter",
          color: moduleFields[f]!.color,
          data: rawData ? rawData.data.map((d) => [d[rawData.fields.indexOf(Field.DATE_TIME)], d[rawData.fields.indexOf(f)]]) : [],
        })),
    [moduleFields, rawData]
  );

  const cleanedSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleFields)
        .map((v) => v as Field)
        .map((f) => ({
          name: `${getFieldName(f)} (clean)`,
          type: "line",
          color: moduleFields[f]!.color,
          data: cleaned ? cleaned.data.map((d) => [d[cleaned.fields.indexOf(Field.DATE_TIME)], d[cleaned.fields.indexOf(f)]]) : [],
        })),
    [cleaned, moduleFields]
  );

  const endDateSeries = useMemo<FossilyticsChartSeries | undefined>(() => {
    if (!currentModule || !SPAD_DECLINE_MODULES.includes(currentModule)) {
      return;
    }

    return forecastStartDate
      ? {
          id: "forecastStartDate",
          name: "Forecast start",
          type: "line",
          data: [
            [forecastStartDate, -9e10],
            [forecastStartDate, 9e10],
          ],
          lineType: "dashed",
          lineWidth: 1.5,
          color: palette.customColor.black,
        }
      : undefined;
  }, [forecastStartDate, currentModule, palette.customColor.black]);

  const allSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      ...rawSeries.map((s, i) => ({
        ...s,
        defaultDisabled: true,
        yAxisIndex: yAxes.findIndex((ya) => ya.name === moduleFields[Object.keys(moduleFields)[i] as Field]?.yAxis),
      })),
      ...cleanedSeries.map((s, i) => ({
        ...s,
        defaultDisabled: s.name === "Casing pressure (clean)",
        yAxisIndex: yAxes.findIndex((ya) => ya.name === moduleFields[Object.keys(moduleFields)[i] as Field]?.yAxis),
      })),
      ...(endDateSeries ? [{ ...endDateSeries, yAxisIndex: yAxes.length - 1 }] : []),
    ],
    [rawSeries, cleanedSeries, endDateSeries, yAxes, moduleFields]
  );
  const dataGridGroups = useMemo<FossilyticsGridColumnGroup[]>(
    () => [
      { header: "Raw data", colspan: rawData?.fields.length ?? 1 },
      { header: "Cleaned", colspan: cleaned?.fields.length ?? 1 },
    ],
    [rawData, cleaned]
  );

  const formatDataGridColumns = useCallback(
    (key: string, fields: Field[]): FossilyticsGridColumn[] =>
      fields.map((f) => {
        const unit = getFieldUnit(f);
        return {
          header: getFieldName(f) + (unit ? ` (${unit})` : ""),
          key: `${f}-${key}`,
          editable: false,
          type: f === Field.DATE_TIME ? "date" : "text",
          width: 125,
        };
      }),
    []
  );
  const dataGridColumns = useMemo<FossilyticsGridColumn[]>(
    () =>
      rawData && cleaned
        ? [...formatDataGridColumns("rawData", rawData.fields), ...(cleaned ? formatDataGridColumns("cleaned", cleaned.fields) : [])]
        : [],
    [rawData, cleaned, formatDataGridColumns]
  );

  // map BE field with FE formatter
  const formatDataGridData = useCallback(
    (value: any, colIdx: number) => {
      return rawData && getFormattedNumberByField(value, rawData.fields[colIdx]);
    },
    [rawData]
  );
  const dataGridData = useMemo(
    () =>
      rawData && cleaned
        ? transpose([
            ...transpose(rawData.data.map((r) => r.map((d, i) => formatDataGridData(d, i)))),
            ...(cleaned
              ? transpose(rawData.data.map((r, j) => r.map((_, i) => (j < cleaned.data.length ? formatDataGridData(cleaned.data[j][i], i) : null))))
              : []),
          ])
        : [],
    [rawData, cleaned, formatDataGridData]
  );

  useEffect(() => {
    // naming format {module-name}{Group}{Wellname}.csv
    // will download 2 file, 1 is raw data the other one is clean data

    if (!fetchingData && dataGridColumns.length !== 0 && !errorFetching && dataTableActive && isActive) {
      const genericName = `${moduleDictionary[currentModule as ModuleId]} ${group?.name} ${dataSetIds.join(", ")}`;

      const firstFileName = `${genericName} ${dataGridGroups[0].header}.csv`;
      const secondFileName = `${genericName} ${dataGridGroups[1].header}.csv`;

      const rawCsvData = {
        fileName: firstFileName,
        data: [
          dataGridColumns
            .slice(0, dataGridGroups[0].colspan)
            .map((column) => column.header)
            .join(", "),
          ...dataGridData.map((data) => {
            const res: any[] = [];
            const slicedData = data.slice(0, dataGridGroups[0].colspan);

            slicedData.forEach((data, index) => {
              if (dataGridColumns[index].type === "date") {
                res.push(new Date(data).toLocaleDateString());
              } else {
                res.push(data);
              }
            });
            return res.join(", ");
          }),
        ].join("\n"),
        isTableCsv: true,
      };

      const cleanedCsvData = {
        fileName: secondFileName,
        data: [
          dataGridColumns
            .slice(dataGridGroups[0].colspan, dataGridGroups[1].colspan + dataGridGroups[0].colspan)
            .map((column) => column.header)
            .join(", "),
          ...dataGridData.map((data) => {
            const res: any[] = [];
            const slicedData = data.slice(dataGridGroups[0].colspan, dataGridGroups[0].colspan * 2);

            slicedData.forEach((data, index) => {
              if (dataGridColumns[index + dataGridGroups[0].colspan].type === "date") {
                res.push(new Date(data).toLocaleDateString());
              } else {
                res.push(data);
              }
            });
            return res.join(", ");
          }),
        ].join("\n"),
        isTableCsv: true,
      };
      setCsvData([rawCsvData, cleanedCsvData]);
    }
  }, [
    dataGridGroups,
    dataGridColumns,
    dataGridData,
    fetchingData,
    currentModule,
    group?.name,
    dataSetIds,
    errorFetching,
    setCsvData,
    dataTableActive,
    isActive,
  ]);

  return (
    <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
      {!hideSidebar && (
        <Stack tokens={{ childrenGap: 20 }}>
          {showAtmosphericCorrection && (
            <Stack style={elevatedCard} grow={1}>
              <Text styles={{ root: { color: palette.primary.main } }}>Import options</Text>

              {showAtmosphericCorrection ? (
                <FossilyticsNumberField
                  type="float"
                  label="Atmospheric correction"
                  suffix="psi"
                  calloutContent={<Text>Pressure value added to all pressure data.</Text>}
                  debounceDelay={DEBOUNCE_DELAY}
                  value={loadOptions?.atmospheric_correction}
                  onChange={(v) =>
                    v !== undefined
                      ? setLoadOptions({
                          ...loadOptions,
                          atmospheric_correction: v,
                        })
                      : null
                  }
                />
              ) : undefined}
            </Stack>
          )}

          <Stack style={elevatedCard} grow={1}>
            <Text styles={{ root: { color: palette.primary.main } }}>Data cleaning</Text>

            <Dropdown
              label="Smoothing type"
              options={cleaningSmoothingTypeOptions}
              disabled={isLoading}
              selectedKey={cleanOptions?.smoothing_type}
              onChange={(_, v) =>
                v !== undefined
                  ? setCleanOptions({
                      ...cleanOptions,
                      smoothing_type: v.key as string,
                    })
                  : null
              }
            />
            {cleanOptions?.smoothing_type === cleaningSmoothingTypeOptions[0].key ? (
              ""
            ) : (
              <Dropdown
                label="Moving average window"
                options={cleaningSmoothingMovAvgOptions}
                disabled={isLoading}
                selectedKey={cleanOptions?.moving_avg_window}
                onChange={(_, v) =>
                  v !== undefined
                    ? setCleanOptions({
                        ...cleanOptions,
                        moving_avg_window: v.key as number,
                      })
                    : null
                }
              />
            )}

            {cleanOptions?.smoothing_type === cleaningSmoothingTypeOptions[0].key ? (
              <FossilyticsNumberField
                label="Smoothing points"
                type="int"
                value={smoothingPoints}
                max={rawData?.data.length}
                debounceDelay={DEBOUNCE_DELAY}
                onChange={(v) => (v !== undefined ? setSmoothingPoints(v) : null)}
              />
            ) : undefined}
            <FossilyticsNumberField label="Number of points" disabled value={rawData?.data.length} />
          </Stack>

          {children ? (
            <Stack.Item style={elevatedCard} grow={1}>
              <Text styles={{ root: { color: palette.primary.main } }}>{extraOptionsTitle ? extraOptionsTitle : "Extra"}</Text>

              {children}
            </Stack.Item>
          ) : undefined}
        </Stack>
      )}

      <Stack className="PivotWrapper" style={elevatedCard} grow={1}>
        <Pivot
          onLinkClick={(item) => {
            if (item) {
              const isTable = item.props.headerText === "Data table";
              setDataTableActive(isTable);
              if (!isTable) setCsvData();
            }
          }}
          styles={{ itemContainer: { flex: "1" } }}
        >
          {showCombinedFirst && Object.keys(moduleFields).length > 1 ? (
            <PivotItem headerText="Combined chart">
              {!errorFetching && <FossilyticsChart id="data_view_combined" isLoading={isLoading} xAxes={xAxes} yAxes={yAxes} series={allSeries} />}
            </PivotItem>
          ) : undefined}
          {Object.keys(moduleFields)
            .filter((f) => !moduleFields[f as Field]?.onlyCombined)
            .map((f, i) => {
              const fieldSeries = [
                rawSeries[i],
                cleanedSeries[i],
                ...(endDateSeries
                  ? [
                      {
                        ...endDateSeries,
                        yAxisIndex: 1,
                      },
                    ]
                  : []),
              ];
              const yAxisIndex = yAxes.findIndex((ya) => ya.name === moduleFields[f as Field]?.yAxis);
              return (
                <PivotItem key={f} headerText={getFieldName(f as Field)}>
                  {!errorFetching && (
                    <FossilyticsChart
                      id={`data_view_${f}`}
                      isLoading={isLoading}
                      xAxes={xAxes}
                      yAxes={[yAxes[yAxisIndex], yAxes[yAxes.length - 1]]}
                      series={fieldSeries}
                    />
                  )}
                </PivotItem>
              );
            })}
          {!showCombinedFirst && Object.keys(moduleFields).length > 1 ? (
            <PivotItem headerText="Combined chart">
              {!errorFetching && <FossilyticsChart id="data_view_combined" isLoading={isLoading} xAxes={xAxes} yAxes={yAxes} series={allSeries} />}
            </PivotItem>
          ) : undefined}
          <PivotItem headerText="Data table" style={{ position: "relative" }}>
            {!errorFetching && (
              <FossilyticsGrid
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  overflow: "auto",
                }}
                columnGroups={dataGridGroups}
                columns={dataGridColumns}
                data={dataGridData}
                isLoading={isLoading}
              />
            )}
          </PivotItem>
        </Pivot>
      </Stack>
    </Stack>
  );
};

export default ModuleDataView;
