import { dataviewInitialize } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";

import { DataviewInitializeState } from "..";

type InitializeDataviewPayload = {
  data_set_ids: string[];
};

export const postInitializeDataview = (projectId: string, moduleId: string, body: InitializeDataviewPayload) => {
  return fetchApi<DataviewInitializeState, InitializeDataviewPayload>({
    path: dataviewInitialize(projectId, moduleId),
    type: "post",
    body,
  });
};
