interface TenantConfig {
  name: string;
  domain: string;

  idpEnabled: boolean;
  idpDomain?: string;
  userPoolId: string;
  clientId: string;
}

export const TenantConfigs: { [k: string]: TenantConfig } = JSON.parse(process.env.REACT_APP_TENANTS ?? "{}");
