import React, { useEffect, useCallback } from "react";
import _ from "lodash";

// utils
import { findGroupRecursive, getIdFromActiveKey, loopGroup } from "../utils";
import { DataSet, Group, ModuleId, Project } from "@/model";
import { postMoveGroup, postWellToGroup } from "@/models/settings";
import { FlatenGroupProject, SelectItemProp } from "../types";
import dictionary from "@/constants/dictionary";
import { moduleMappingMulti } from "@/components/Modules/constants";
import { postMoveWells } from "@/models/wells";
import { TreeNode } from "../Components/DndProjectTree/helper";

export type UseProjectNavProps = {
  projects?: Project[];
  refreshProjects: () => Promise<Project[] | undefined> | void;
  setProject: (project?: Project) => void;
  setGroup: (group?: Group) => void;
  setSelectedDataSets: (dataSets?: DataSet | DataSet[]) => void;
  setSelectedKey: (key: string) => void;
  dataSets?: DataSet[];
  selectedKey?: string;
  navigate: (path: string) => void;
  setErrorWarning: (error?: string) => void;
  currentModule?: ModuleId;
};

const useProjectNav = ({
  selectedKey,
  projects,
  refreshProjects,
  setGroup,
  setProject,
  setSelectedDataSets,
  setSelectedKey,
  dataSets,
  navigate,
  setErrorWarning,
  currentModule,
}: UseProjectNavProps) => {
  const [showAll, setShowAll] = React.useState(false);
  const [hideProjects, setHideProjects] = React.useState(false);
  // this is for selected data set in well list
  // use it for drag and drop functionality
  const [selectedDataSet, setSelectedDataSet] = React.useState<string[]>([]);

  // use when user click ... dots menu on the nav
  // will set active key with format below:
  // projectId;gorupId,groupId,groupId;dataSet,dataSet,dataSet
  const [activeKey, setActiveKey] = React.useState("");

  const [mappedItemKeyTotal, setMappedItemKeyTotal] = React.useState<FlatenGroupProject>({});

  // handle if user press shift
  const [shiftHeld, setShiftHeld] = React.useState(false);
  const [commandControlHeld, setCommandControlHeld] = React.useState(false);

  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

  // handle well list destination
  const wellDestination = React.useRef<any>();

  function downHandler({ key }: { key: string }) {
    if (key === "Shift") {
      setShiftHeld(true);
    } else if (key === "Meta" || key === "Control") {
      setCommandControlHeld(true);
    }
  }

  function upHandler({ key }: { key: string }) {
    if (key === "Shift") {
      setShiftHeld(false);
    } else if (key === "Meta" || key === "Control") {
      setCommandControlHeld(false);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  useEffect(() => {
    if (projects && projects?.length > 0) {
      let res: {
        [key: string]: {
          total: number;
          ids?: string[];
          name: string;
        };
      } = {};

      projects?.forEach((project) => {
        res[project.id] = {
          total: 0,
          name: "",
        };
        project.groups.forEach((group) => {
          let groupMapped = loopGroup(group);
          const reduced = Object.values(groupMapped).reduce((total, curr) => {
            if (!curr.isChild) {
              total += curr.total;
            }
            return total;
          }, 0);

          for (let item in groupMapped) {
            res[item] = {
              total: groupMapped[item].total,
              ids: groupMapped[item].ids,
              name: groupMapped[item].name,
            };
          }
          res = {
            ...res,
            [project.id]: {
              total: reduced + res[project.id].total,
              name: project.name,
            },
          };
        });
      });
      setMappedItemKeyTotal(res);
    }
  }, [projects]);

  const onUpdateActiveKey = useCallback(
    (activeKey: string) => {
      setActiveKey(activeKey);
      if (!projects || activeKey === selectedKey) return;
      // set project, and group according to active key;
      setSelectedKey(activeKey);
      if (activeKey) {
        const { projectId, groupIds, isCollapsible, dataSet } = getIdFromActiveKey(activeKey);
        const clonedProjects = _.cloneDeep(projects);

        let currProjectId = projects.findIndex((project) => project.id === projectId);
        const ids = [...groupIds];
        const selectedGroup = ids.length > 0 ? findGroupRecursive(ids, clonedProjects[currProjectId].groups) : undefined;

        if (dataSet.length > 0) {
          const filteredDataSets = dataSets?.filter((curr) => dataSet.indexOf(curr.name) > -1 || dataSet.indexOf(curr.id) > -1) ?? [];
          if (filteredDataSets?.length > 0) setSelectedDataSets(filteredDataSets[0]);
          setProject(clonedProjects[currProjectId]);
          setGroup(selectedGroup);
        } else if (groupIds.length > 0) {
          setProject(clonedProjects[currProjectId]);
          setGroup(selectedGroup);

          if (selectedGroup && !isCollapsible) {
            let dataSetList = selectedGroup.data_set_ids ? selectedGroup.data_set_ids : [];
            if (dataSetList.length === 0) {
              dataSetList = Array.from(
                selectedGroup.groups.reduce((res, curr) => {
                  curr.data_set_ids?.forEach((id) => {
                    res.add(id);
                  });
                  return res;
                }, new Set<string>())
              );
            }

            const filteredDataSets = dataSets?.filter((curr) => dataSetList.indexOf(curr.id) > -1);

            setSelectedDataSets(filteredDataSets);
          }
        } else if (!isCollapsible) {
          const flattenDataset = clonedProjects[currProjectId].groups.reduce((res, curr) => {
            curr.data_set_ids?.forEach((id) => {
              res.add(id);
            });
            return res;
          }, new Set<string>());
          const filteredDataSets = dataSets?.filter((curr) => Array.from(flattenDataset).indexOf(curr.id) > -1);
          setSelectedDataSets(filteredDataSets);
          setProject(clonedProjects[currProjectId]);
          setGroup(undefined);
        }
      } else {
        setSelectedDataSets(undefined);
        setProject(undefined);
        setGroup(undefined);
      }
    },
    [dataSets, projects, selectedKey, setGroup, setProject, setSelectedDataSets, setSelectedKey]
  );

  useEffect(() => {
    if (activeKey === selectedKey) return;
    if (!selectedKey) {
      onUpdateActiveKey("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  const onMoveGroupProject = useCallback(
    async (info: any) => {
      try {
        const destination = info.node;
        const currentItem = info.dragNode;

        // cannot drag group to inside a group with dataset
        if (destination.type === "dataset") {
          setErrorWarning(dictionary.errorMessage.groupHaveDataset);
          setTimeout(() => {
            setErrorWarning();
          }, 5000);
          return;
        }

        const prevLocation = {
          groupId: currentItem.id,
          projectId: currentItem.parents[0],
        };

        const newLocation = {
          groupId: destination.type === "project" ? null : destination.id,
          projectId: destination.type === "project" ? destination.id : destination.parents[0],
        };

        const canHaveSubGroup = destination.data_set_ids?.length === 0 || destination.type === "dataset" || destination.type === "project";
        if (!canHaveSubGroup) {
          setErrorWarning(dictionary.errorMessage.droppableHaveDataset);
          setTimeout(() => {
            setErrorWarning();
          }, 5000);
          return;
        }

        console.log({ prevLocation, newLocation, info, destination, currentItem });
        await postMoveGroup(prevLocation, newLocation);
        await refreshProjects();
        setSelectedKeys([]);
      } catch (error: any) {
        console.log(error);
        setErrorWarning(error?.detail ?? dictionary.serverError);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }
    },
    [refreshProjects, setErrorWarning]
  );

  const onMoveWellGroup = useCallback(
    async (info: any) => {
      const destination = info.node;
      const currentItem = info.dragNode;

      // this mean use dragging in same place
      if (_.isEqual(currentItem.parents, destination.parents)) return;

      console.log({ destination });
      // - if the group have subgroup || drag over a project
      if (destination.type === "project" || (destination?.children?.length > 0 && destination.data_set_ids?.length === 0)) {
        setErrorWarning(dictionary.errorMessage.droppableWellMoveHaveSubgroup);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }

      // if user drop to different project
      if (destination.parents[0] !== currentItem.parents[0]) {
        setErrorWarning(dictionary.errorMessage.droppableDifferentProjectWellMove);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }

      const selectedWells = selectedKeys.reduce((res: string[], key) => {
        if (key.includes(";")) {
          res.push(key.split(";")[0]);
        }
        return res;
      }, []);

      let destinationGroupId = destination.id;
      const currentGroupId = currentItem.parents[currentItem.parents.length - 1];
      const projectId = currentItem.parents[0];

      // if user drop to dataset, take it parent group
      if (destination.type === "dataset") {
        destinationGroupId = destination.parents[destination.parents.length - 1];
      }

      try {
        await postMoveWells({
          body: {
            dataset_ids: selectedWells,
            group_id: destinationGroupId,
          },
          groupId: currentGroupId,
          projectId,
        });
        await refreshProjects();
        setSelectedKeys([]);
      } catch (error: any) {
        setErrorWarning(error?.detail ?? dictionary.serverError);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);

        return;
      }
    },
    [refreshProjects, selectedKeys, setErrorWarning]
  );

  const onDragEnd = async (info: any) => {
    const destination = info.node;
    const currentItem = info.dragNode;

    if (currentItem.type === "project" || destination.id === currentItem.id) {
      setErrorWarning(dictionary.errorMessage.moveProjectNotAllowed);
      setTimeout(() => {
        setErrorWarning();
      }, 5000);
      return;
    }

    if (!currentItem.dataSet && currentItem.type === "dataset") {
      setErrorWarning(dictionary.errorMessage.wellNotFound);
      setTimeout(() => {
        setErrorWarning();
      }, 5000);
      return;
    }

    if (currentItem.type === "group") {
      await onMoveGroupProject(info);
    } else {
      await onMoveWellGroup(info);
    }
  };

  const onSelectProject = useCallback(
    (projectId: string, prevSelection: boolean, childKeyList: string[]) => {
      if (!projects) return [];

      // find the project
      let currProjectId = projects.findIndex((project) => project.id === projectId);
      let newSelectedKeys: string[] = [];

      if (currProjectId >= 0) {
        // remove all other selection
        // and set selected to only current one
        if (prevSelection) {
          onUpdateActiveKey("");
        } else {
          onUpdateActiveKey(`${projectId}`);
          newSelectedKeys = [projectId, ...childKeyList];
        }
        setSelectedKeys(newSelectedKeys);

        if (!prevSelection) {
          navigate("/modules");
        }
      }
    },
    [projects, onUpdateActiveKey, navigate]
  );

  const onSelectGroup = useCallback(
    (id: string, prevSelection: boolean, item: TreeNode) => {
      if (!projects) return [];

      const projectId = item.parents[0];

      // find the project
      let projectIndex = projects.findIndex((project) => project.id === projectId);
      let newSelectedKeys = [...selectedKeys];

      if (projectIndex >= 0) {
        if (!prevSelection || !_.isEqual([id, ...(item.childKeyList ?? [])], newSelectedKeys)) {
          newSelectedKeys = [id, ...(item.childKeyList ?? [])];
          onUpdateActiveKey(`${projectId};${[...item.parents.slice(1), item.id].join(",")}`);
        } else {
          onUpdateActiveKey("");
          newSelectedKeys = [];
        }
        setSelectedKeys(newSelectedKeys);

        // navigate to grid if select another group
        if (!prevSelection) {
          navigate("/modules");
        }
      }
    },
    [projects, selectedKeys, onUpdateActiveKey, navigate]
  );

  const onSelectDataSet = useCallback(
    async (projectId: string, groupIds: string[], dataSetId: string, prevSelection: boolean, key: string, isDrag: boolean) => {
      if (!projects) return [];
      // find the project
      let projectIndex = projects.findIndex((project) => project.id === projectId);

      if (projectIndex !== -1) {
        const isSelect = !prevSelection;
        const dataSet = dataSets?.filter((data) => data.id === dataSetId) ?? [];
        let newSelectedKeys = [...selectedKeys];

        if (commandControlHeld || isDrag) {
          // when user clik dataset -> only chang data set but stay in the same module

          // when user click dataset + command control active:
          // - it is the same project & group as currently selected
          // - if there is selected module
          // - check if current module support multiple
          // chek if selected data set have the same type
          // - set selected data set
          // else -> go back to homepage

          const { dataSet: activeDataSet, groupIds: activeGroupIds, projectId: activeProjectId } = getIdFromActiveKey(activeKey);

          const updatedDataSet = [...activeDataSet, dataSetId];
          const fullSelectedDataSet = dataSets?.filter((data) => updatedDataSet.indexOf(data.id) !== -1) ?? [];
          const enabledMultipleMod = currentModule ? moduleMappingMulti?.[currentModule] && fullSelectedDataSet.length > 1 : true;
          const mappedSelectedDataSetType = fullSelectedDataSet.map((well) => well.type) ?? [];
          if (
            projectId === activeProjectId &&
            _.isEqual(groupIds, activeGroupIds) &&
            enabledMultipleMod &&
            new Set(mappedSelectedDataSetType).size === 1
          ) {
            onUpdateActiveKey(`${projectId};${groupIds.join(",")};${updatedDataSet.join(",")}`);
            setSelectedDataSets(fullSelectedDataSet);
            newSelectedKeys.push(key);
          } else {
            setSelectedDataSets(dataSet[0]);
            onUpdateActiveKey(`${projectId};${groupIds.join(",")};${dataSetId}`);
            newSelectedKeys = [key];
          }
          setSelectedKeys(newSelectedKeys);
          return;
        }

        if (isSelect) {
          onUpdateActiveKey(`${projectId};${groupIds.join(",")};${dataSetId}`);
          newSelectedKeys = [key];
          if (dataSet?.length > 0) {
            setSelectedDataSets(dataSet[0]);
          }
        } else {
          newSelectedKeys = [];
          setSelectedDataSets();
        }
        setSelectedKeys(newSelectedKeys);
      }
    },
    [projects, dataSets, selectedKeys, commandControlHeld, activeKey, currentModule, onUpdateActiveKey, setSelectedDataSets]
  );

  const onSelectItem = ({ id, type, dataSetId = "", groupId = [], prevSelection = false, key, item, isDrag = false }: SelectItemProp) => {
    setSelectedDataSet([]);
    if (type === "dataset") onSelectDataSet(id, groupId.slice(1), dataSetId, prevSelection, key, isDrag);
    else if (type === "group") onSelectGroup(id, prevSelection, item);
    else onSelectProject(id, prevSelection, item.childKeyList ?? []);
  };

  const onClickSelectDataSet = (wells: string[]) => {
    setSelectedKeys([]);
    setSelectedDataSet(wells);
  };

  // hacky way to handle drop between 2 tree
  const onDragWellList = (info: any) => {
    if (!_.isEqual(wellDestination.current, info)) {
      wellDestination.current = info;
    }
  };

  const onAddWellToGroup = useCallback(async () => {
    if (!wellDestination.current) return;
    try {
      if (wellDestination.current.type === "project") {
        setErrorWarning(dictionary.errorMessage.groupDroppable);
        setTimeout(() => {
          setErrorWarning();
        }, 5000);
        return;
      }

      let groupId = wellDestination.current.id;
      const projectId = wellDestination.current.parents[0];
      if (wellDestination.current.type === "dataset") {
        groupId = wellDestination.current.parents[wellDestination.current.parents.length - 1];
      }

      if (mappedItemKeyTotal[groupId].ids) {
        const safeIdListByGroup = mappedItemKeyTotal[groupId].ids ?? [];
        let newDataSets: string[] = selectedDataSet;
        newDataSets = newDataSets.filter((dataSetId) => safeIdListByGroup.indexOf(dataSetId) < 0);
        if (newDataSets.length === 0) return;
        await postWellToGroup(projectId, groupId, newDataSets);
        await refreshProjects();

        setSelectedDataSet([]);
      }
    } catch (err) {
      console.log(err, "err");
    }
  }, [mappedItemKeyTotal, refreshProjects, selectedDataSet, setErrorWarning]);

  return {
    showAll,
    setShowAll,
    hideProjects,
    setHideProjects,
    onDragEnd,
    mappedItemKeyTotal,
    selectedItems: projects ?? [],
    setActiveKey: onUpdateActiveKey,
    activeKey,
    selectedDataSet,
    setSelectedDataSet: onClickSelectDataSet,

    shiftHeld,
    commandControlHeld,

    onMoveWellGroup,
    selectedKeys,
    onSelectItem,
    onDragWellList,
    onDropWell: onAddWellToGroup,
    setSelectedKeys,
  };
};

export default useProjectNav;
