import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import useTahkCsgWaterPvt, { TahkCsgWaterPvtProps } from "../../../hooks/input/charts/useTahkCsgWaterPvt";

import Tabs from "@/components/Tabs";
import { useMemo } from "react";
import FossilyticsChart from "@/components/FossilyticsChart";
import useLayeredChart, { Layer } from "../../../hooks/input/useLayeredChart";
import FossilyticsDropdown from "@/components/fields/FossilyticsDropdown";
import dictionary from "@/constants/dictionary";
import CustomTable from "@/components/CustomTable";
import { waterPvtDataTableCols } from "../../../constants/grid";
import { calculateHeight } from "@/utils/cellRendering";

const WaterPvt = (props: TahkCsgWaterPvtProps) => {
  const { selectedLayer, setSelectedLayer, waterPvtColumns, waterPvtRows, onChangeWaterCustomCells, layerOption, waterPvtDataTableRows } =
    useTahkCsgWaterPvt(props);

  const {
    series: viscositySeries,
    xAxis: viscosityXAxis,
    yAxis: viscosityYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "viscosity",
      labelKey: "viscosity",
    },

    chartType: "water_pvt_charts",
  });
  const {
    series: densitySeries,
    xAxis: densityXAxis,
    yAxis: densityYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "density",
      labelKey: "density",
    },

    chartType: "water_pvt_charts",
  });
  const {
    series: formVolFactorSeries,
    xAxis: formVolFactorXAxis,
    yAxis: formVolFactorYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "form_vol_factor",
      labelKey: "form_vol_factor",
    },

    chartType: "water_pvt_charts",
  });
  const {
    series: compressibilitySeries,
    xAxis: compressibilityXAxis,
    yAxis: compressibilityYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "compressibility",
      labelKey: "compressibility",
    },

    chartType: "water_pvt_charts",
  });
  const tableHeight = 500 + layerOption.length * 5;
  const minHeight = calculateHeight(waterPvtRows) + 135;

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span>
            μ<sub>{"w"}</sub>
          </span>
        ),
        key: "μw",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart
              id="water_pvt_mw"
              isLoading={props.loadingState}
              xAxes={viscosityXAxis}
              yAxes={viscosityYAxis}
              series={viscositySeries}
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            P<sub>{"w"}</sub>
          </span>
        ),
        key: "Pw",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart id="water_pvt_pw" isLoading={props.loadingState} xAxes={densityXAxis} yAxes={densityYAxis} series={densitySeries} />
          </div>
        ),
      },
      {
        label: (
          <span>
            B<sub>{"w"}</sub>
          </span>
        ),
        key: "Bw",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart
              id="water_pvt_bw"
              isLoading={props.loadingState}
              xAxes={formVolFactorXAxis}
              yAxes={formVolFactorYAxis}
              series={formVolFactorSeries}
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            C<sub>{"w"}</sub>
          </span>
        ),
        key: "Cw",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart
              id="water_pvt_cw"
              isLoading={props.loadingState}
              xAxes={compressibilityXAxis}
              yAxes={compressibilityYAxis}
              series={compressibilitySeries}
            />
          </div>
        ),
      },
      {
        label: <span>Data Table</span>,
        key: "Data table",
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="chartWrapper"
          >
            <FossilyticsDropdown
              label={dictionary.tahk.layer}
              options={layerOption}
              disabled={props.loadingState}
              selectedKey={selectedLayer}
              onChange={(v) => {
                setSelectedLayer(Number(v?.key));
              }}
            />
            <CustomTable
              style={{
                display: "flex",
              }}
              rows={waterPvtDataTableRows}
              columns={waterPvtDataTableCols}
              isLoading={props.loadingState}
            />
          </div>
        ),
      },
    ];
  }, [
    compressibilitySeries,
    compressibilityXAxis,
    compressibilityYAxis,
    densitySeries,
    densityXAxis,
    densityYAxis,
    formVolFactorSeries,
    formVolFactorXAxis,
    formVolFactorYAxis,
    layerOption,
    props.loadingState,
    selectedLayer,
    setSelectedLayer,
    viscositySeries,
    viscosityXAxis,
    viscosityYAxis,
    waterPvtDataTableRows,
    tableHeight,
  ]);

  return (
    <div style={{ marginTop: 20, flexDirection: "column" }} className="center-flex">
      <ReactGridTable
        innerStyle={{
          display: "flex",
        }}
        className="noScrollbarAll"
        viewWidth={"100%"}
        viewHeight={minHeight < 400 ? minHeight : 400}
        columns={waterPvtColumns}
        rows={waterPvtRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeWaterCustomCells}
      />

      <Tabs tabList={tabList} />
    </div>
  );
};

export default WaterPvt;
