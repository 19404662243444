import { fetchApi } from "@/utils/apiFetcher";
import { importWell, importProject, moveWellBetweenGroup } from "@/constants/apiUrl";

type ImportWellPayload = {
  file: File;
  override?: boolean;
};

export type PromiseResult<ErrorCode> = {
  code: number;
  detail: PromiseResultDetail<ErrorCode>;
  status?: ResultStatus;
};
export type ResultStatus = "rejected" | "fulfilled";

export type PromiseResultDetail<ErrorCode> = {
  code: ErrorCode;
  message: string;
};

export type ResultState<ErrorCode> = {
  [fileIndex: number]: PromiseResult<ErrorCode>;
};

export type WellErrorCode = "validate_error" | "no_permission" | "file_exists";
export type ProjectStructureErrorCode = "validate_error";

export const postDataSet = ({ file, override = false }: ImportWellPayload) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchApi({
    path: importWell,
    queryStringParameters: {
      override,
    },
    type: "post",
    body: formData,
  });
};

export const postProjectStructure = ({ file, override = false }: ImportWellPayload) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchApi({
    path: importProject,
    queryStringParameters: {
      override,
    },
    type: "post",
    body: formData,
  });
};

type MoveWellGroupPayload = {
  body: {
    group_id: string;
    dataset_ids: string[];
  };
  projectId: string;
  groupId: string;
};

// move well between group in 1 project
export const postMoveWells = ({ body, groupId, projectId }: MoveWellGroupPayload) => {
  return fetchApi({
    path: moveWellBetweenGroup(projectId, groupId),
    type: "post",
    body,
  });
};

// import generic well e.g aplng converter
type ImportGenericWell = {
  file: File;
  url: string;
};

export const postGenericUpload = ({ file, url }: ImportGenericWell) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchApi<Blob>({
    path: url,
    type: "post",
    body: formData,
    responseType: "blob",
  });
};
