import UncertainParamInput from "../../../../../../../../components/UncertainParamInput";
import { ModuleKoldunMcsimGasOilOptions, ModuleKoldunMcsimUncertainParamType } from "../../../model";

const uncertainParams = [
  {
    name: "OGIP",
    units: "MMscf",
    hideDistributionSelect: true,
    valueKey: "ogip_mmscf",
  },
  {
    name: "Water saturation",
    units: "%",
    hideDistributionSelect: true,
    valueKey: "water_sat_perc",
  },
  {
    name: "Porosity",
    units: "%",
    hideDistributionSelect: true,
    valueKey: "porosity_perc",
  },
  {
    name: "Permeability",
    units: "md",
    hideDistributionSelect: true,
    valueKey: "permeability_md",
  },
  {
    name: "Net pay",
    units: "ft",
    hideDistributionSelect: true,
    valueKey: "net_pay_ft",
  },
  {
    name: "Initial pressure",
    units: "psia",
    hideDistributionSelect: true,
    valueKey: "initial_pressure_psia",
  },
];
interface OilUncertainParameterInputsProps {
  value: ModuleKoldunMcsimGasOilOptions;
  onChange: (val: ModuleKoldunMcsimGasOilOptions) => void;
}

const OilUncertainParameterInputs = ({ value, onChange }: OilUncertainParameterInputsProps) => {
  if (!value) return <></>;
  return (
    <>
      {uncertainParams.map((param) => (
        <UncertainParamInput
          key={param.valueKey}
          name={param.name}
          units={param.units}
          hideDistributionSelect={param.hideDistributionSelect}
          value={value[param.valueKey as keyof ModuleKoldunMcsimGasOilOptions] as ModuleKoldunMcsimUncertainParamType}
          onChange={(v) => onChange({ ...value, [param.valueKey]: v })}
        />
      ))}
    </>
  );
};

export default OilUncertainParameterInputs;
