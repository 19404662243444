import React, { useMemo } from "react";

import InputField from "@/components/fields/InputField";
import Tabs from "@/components/Tabs";
import CustomDataTable from "@/components/CustomTable";
import FossilyticsChart from "@/components/FossilyticsChart";
import DropdownField from "@/components/fields/DropdownField";

import useAutoRtaPvtDataTable from "../../hooks/rtaInputGrid/useAutoRtaPvtDataTable";
import useAutoRtaWaterPvtCharts from "../../hooks/rtaInputGrid/useAutoRtaWaterPvtCharts";

import "../../ModuleAutoRTA.css";
import { ModelComponentsParamProps } from "../../hooks/AutoRtaContext";
import { mapEnumToDropdown } from "@/utils/general";
import { WaterCompressibilityCorrelation, WaterViscosityCorrelation } from "@/models/InputGeneric";

const WaterPvtContainer = ({ value, calculateValue, isLoading, updateInputGridValue, validationError }: ModelComponentsParamProps) => {
  const { waterPvtMu, waterPvtPw, waterPvtBw, waterPvtcw } = useAutoRtaWaterPvtCharts(calculateValue);
  const { waterPvtDataTableRows, waterPvtDataTableCols } = useAutoRtaPvtDataTable(calculateValue);

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span>
            μ<sub>{"w"}</sub>
          </span>
        ),
        key: "μw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_mw"
              isLoading={isLoading}
              xAxes={waterPvtMu.xAxes}
              yAxes={waterPvtMu.yAxes}
              series={waterPvtMu.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            p<sub>{"w"}</sub>
          </span>
        ),
        key: "pw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_pw"
              isLoading={isLoading}
              xAxes={waterPvtPw.xAxes}
              yAxes={waterPvtPw.yAxes}
              series={waterPvtPw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            B<sub>{"w"}</sub>
          </span>
        ),
        key: "Bw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_bw"
              isLoading={false}
              xAxes={waterPvtBw.xAxes}
              yAxes={waterPvtBw.yAxes}
              series={waterPvtBw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            c<sub>{"w"}</sub>
          </span>
        ),
        key: "cw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_cw"
              isLoading={false}
              xAxes={waterPvtcw.xAxes}
              yAxes={waterPvtcw.yAxes}
              series={waterPvtcw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: <span>Data table</span>,
        key: "Data table",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={waterPvtDataTableRows} columns={waterPvtDataTableCols} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [
    isLoading,
    waterPvtBw.series,
    waterPvtBw.xAxes,
    waterPvtBw.yAxes,
    waterPvtDataTableCols,
    waterPvtDataTableRows,
    waterPvtMu.series,
    waterPvtMu.xAxes,
    waterPvtMu.yAxes,
    waterPvtPw.series,
    waterPvtPw.xAxes,
    waterPvtPw.yAxes,
    waterPvtcw.series,
    waterPvtcw.xAxes,
    waterPvtcw.yAxes,
  ]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2.8fr",
      }}
    >
      <div
        style={{
          minWidth: 180,
          maxWidth: 297,
        }}
      >
        <InputField
          label="Salinity"
          suffix="ppm"
          debounceDelay={1000}
          value={value.water_pvt.salinity}
          disabled={isLoading}
          onChange={(v) => {
            updateInputGridValue(v, "salinity", "water_pvt");
          }}
          errors={validationError}
          keyField="inputs.water_pvt.salinity"
        />
        <DropdownField
          label="Water viscosity correlation"
          options={mapEnumToDropdown(WaterViscosityCorrelation)}
          selectedKey={value.water_pvt.selected_water_viscosity_correlation}
          disabled={isLoading}
          onChange={(v) => updateInputGridValue(v, "selected_water_viscosity_correlation", "water_pvt")}
          errors={validationError}
          keyField="inputs.water_pvt.selected_water_viscosity_correlation"
        />
        <DropdownField
          label="Water compressibility"
          options={mapEnumToDropdown(WaterCompressibilityCorrelation)}
          selectedKey={value.water_pvt.selected_water_compressibility_correlation}
          disabled={isLoading}
          onChange={(v) => updateInputGridValue(v, "selected_water_compressibility_correlation", "water_pvt")}
          errors={validationError}
          keyField="inputs.water_pvt.selected_water_compressibility_correlation"
        />
      </div>
      <Tabs tabList={tabList} centered />
    </div>
  );
};

export default WaterPvtContainer;
