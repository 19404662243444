import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { ProjectDataSet } from "@/util";
import { ModuleId } from "@/model";
import { SpadDeclineCalculationScheme, postInitializeSpadDecline } from "@/models/spad/decline";
import { getSubModulePath } from "../index.utils";

type UseCompareProps = {
  currentModule?: ModuleId;
};

const useCompareSpadAnalysis = ({ currentModule }: UseCompareProps) => {
  const [compareName, setCompareName] = useState<string>();
  const [compareDataSet, setCompareDataSet] = useState<ProjectDataSet>();

  const { data } = useQuery({
    queryKey: ["initialize-spad-decline-compare", compareDataSet],
    queryFn: async () => {
      return postInitializeSpadDecline(compareDataSet?.projectId ?? "", compareDataSet?.dataSetIds ?? [], getSubModulePath(currentModule!));
    },
    select(data) {
      if (data?.data) {
        const parsedState = SpadDeclineCalculationScheme.safeParse(data.data);
        if (!parsedState.success) {
          console.error(parsedState);
        }

        return data?.data;
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!(compareDataSet && currentModule),
  }) || { data: undefined, isError: false };

  return {
    compareAnalysis: data,
    compareName,
    setCompareName,
    setCompareDataSet,
    compareDataSet,
  };
};

export default useCompareSpadAnalysis;
