import { FossilyticsChartAxis, FossilyticsChartCustomDataZoom, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";

type UseCartesianRateDayProps = {
  unit: string;
  logFlowSeries: FossilyticsChartSeries[];
  flowSeries: FossilyticsChartSeries[];
  xState: boolean;
};

const useCartesianRateDay = ({ unit, logFlowSeries, flowSeries, xState }: UseCartesianRateDayProps) => {
  const { palette } = useTheme();

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let name = "Days";
    let type = "value";
    let min = 0;

    if (xState) {
      name = "Date";
      type = "time";
      min = logFlowSeries[0]?.data[0][0];
    }

    return [{ name, type, color: palette.customColor.black, min }];
  }, [logFlowSeries, palette.customColor.black, xState]);

  const flowYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Rates (${unit}/d)`, type: "value", color: palette.customColor.black, min: 0 }],
    [palette.customColor.black, unit]
  );
  const findLastValueInFlowSeriesForqgVst = useMemo(() => {
    const newLine = logFlowSeries?.filter((line) => line.name === "Economic cutoff")[0]?.data;
    const flowSeriesLastPoint = flowSeries?.filter((seriesItem) => seriesItem.name === "Flow rates")[0]?.data;

    let percentage = 100;
    if (newLine && newLine.length > 0 && flowSeriesLastPoint.length > 0) {
      const newLineLastPoint = newLine[newLine.length - 1][0] as number;
      const lastNumberInData = flowSeriesLastPoint[flowSeriesLastPoint.length - 1][0];
      if ((lastNumberInData * 3) / newLineLastPoint < 1) {
        percentage = Math.floor(((lastNumberInData * 3) / newLineLastPoint) * 100);
      }
    }
    return percentage;
  }, [flowSeries, logFlowSeries]);

  const qgVstCustomZoom = useMemo<FossilyticsChartCustomDataZoom>(() => {
    return {
      customDataZoom: true,
      dataZoomPercentage: findLastValueInFlowSeriesForqgVst,
    };
  }, [findLastValueInFlowSeriesForqgVst]);

  return { qgVstCustomZoom, flowYAxes, xAxes };
};

export default useCartesianRateDay;
