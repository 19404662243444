import { fetchApi } from "@/utils/apiFetcher";
import { autoRtaInput } from "@/constants/apiUrl";
import { AutoRtaInputGrid, InputGridCalculation } from "../InputGrid";

type CalculateInputPayload = {
  inputs: AutoRtaInputGrid;
  data_set_ids: string[];
};

export const postCalculateInputAutoRta = (projectId: string, type: string, payload: CalculateInputPayload) => {
  return fetchApi<InputGridCalculation, CalculateInputPayload>({
    path: autoRtaInput(projectId, type),
    body: payload,
    type: "post",
  });
};
