import { spadDeclineAnalysisInitialize } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { SpadDeclineState, SpadDeclineType } from "../State";

export const postInitializeSpadDecline = (projectId: string, data_set_ids: string[], type: SpadDeclineType) => {
  return fetchApi<SpadDeclineState>({
    path: spadDeclineAnalysisInitialize(type, projectId),
    body: {
      data_set_ids,
    },
    type: "post",
  });
};
