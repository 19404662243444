import React, { useMemo } from "react";
import Tabs from "@/components/Tabs";

import GasPvtContainer from "./GasPvt";
import WaterPvtContainer from "./WaterPvt";
import LangmuirIsothermContainer from "./LangmuirIsotherm";
import OilPvtContainer from "./OilPvt";
import WellboreModelContainer from "./WellboreModel";

import { ModelComponentsParamProps } from "../../hooks/AutoRtaContext";

const ModelComponents = ({ value, calculateValue, isLoading, updateInputGridValue, validationError }: ModelComponentsParamProps) => {
  const tabList = useMemo(() => {
    return [
      {
        label: <span>Gas PVT</span>,
        key: "Gas PVT",
        content: (
          <GasPvtContainer
            validationError={validationError}
            value={value}
            calculateValue={calculateValue}
            isLoading={isLoading}
            updateInputGridValue={updateInputGridValue}
          />
        ),
      },
      {
        key: "Water PVT",
        label: <span>Water PVT</span>,
        content: (
          <WaterPvtContainer
            validationError={validationError}
            value={value}
            calculateValue={calculateValue}
            isLoading={isLoading}
            updateInputGridValue={updateInputGridValue}
          />
        ),
      },
      {
        key: "Oil PVT",
        label: <span>Oil PVT</span>,
        content: (
          <OilPvtContainer
            validationError={validationError}
            value={value}
            calculateValue={calculateValue}
            isLoading={isLoading}
            updateInputGridValue={updateInputGridValue}
          />
        ),
      },
      {
        key: "Langmuir Isotherm",
        label: <span>Langmuir Isotherm</span>,
        content: (
          <LangmuirIsothermContainer
            value={value}
            calculateValue={calculateValue}
            isLoading={isLoading}
            updateInputGridValue={updateInputGridValue}
            validationError={validationError}
          />
        ),
      },
      {
        key: "Wellbore Model",
        label: <span>Wellbore Model</span>,
        content: (
          <WellboreModelContainer
            calculateValue={calculateValue}
            isLoading={isLoading}
            validationError={validationError}
            value={value}
            updateInputGridValue={updateInputGridValue}
          />
        ),
      },
    ];
  }, [calculateValue, isLoading, validationError, updateInputGridValue, value]);

  return <Tabs tabList={tabList} centered />;
};

export default ModelComponents;
