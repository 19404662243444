import { object, z } from "zod";
import { TahkCsgInputResponseScheme } from "../input";
import { TahkCsgDataOption, TahkCsgValidationState, TahkCsgValidationStateScheme } from "../State";

export type ValidateInputPayload = {
  options: TahkCsgValidationState;
} & TahkCsgDataOption;

export const InputApiStateScheme = object({
  inputs: TahkCsgValidationStateScheme,
  calculation: TahkCsgInputResponseScheme,
});
export type InputApiState = z.infer<typeof InputApiStateScheme>;
