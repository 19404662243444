import { useCallback, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";

import dictionary from "@/constants/dictionary";

import { Row } from "@silevis/reactgrid";
import { ForecastDataObject, KoldunCsgForecastData } from "@/models/koldunV2";
import { tableHeaderStyle } from "@/components/CustomTable";
import { camelToSnakeCase, formatToScientific } from "@/utils/general";

type UseKoldunCsgForecastChartType = {
  koldunCsgCalculation?: KoldunCsgForecastData;
};

const additionalSimulation = ["low_results", "mid_results", "high_results", "mean_results"];

const useKoldunCsgForecastChart = ({ koldunCsgCalculation }: UseKoldunCsgForecastChartType) => {
  const { palette } = useTheme();

  const [simulation, setSimulation] = useState<string>("0");

  const getSeriesColor = useCallback(
    (key: string, isStimulated?: boolean) => {
      if (key.toLowerCase().includes("gas")) {
        if (isStimulated) return palette.customColor.pinkLight;
        return palette.error.main;
      }
      if (key.toLowerCase().includes("water")) {
        if (isStimulated) return palette.customColor.blueLight;
        return palette.customColor.blue;
      }

      return palette.common.black;
    },
    [palette]
  );

  const getYAxisIndex = (key: string) => {
    if (key.toLowerCase().includes("gas")) return 0;
    return 1;
  };

  const chartXAxes = useMemo(() => {
    return [{ name: dictionary.koldunCsg.date, type: "time", color: palette.grey[900] }];
  }, [palette.grey]);

  const chartYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tableUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 50,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tableUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 0,
        nameGap: 50,
      },
    ];
  }, [palette.error.main, palette.info.main]);

  const chartCumulativeYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.koldunCsg.cumulativeGas}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 50,
      },
      {
        name: `${dictionary.koldunCsg.cumulativeGas}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 0,
        nameGap: 50,
      },
    ];
  }, [palette.error.main, palette.info.main]);

  const generateSeries = useCallback(
    (forecastObject: ForecastDataObject, name: string, isCumulative?: boolean, isStimulated?: boolean) => {
      return [
        {
          name: `${name}Gas`,
          type: "line",
          color: getSeriesColor("gas", isStimulated),
          data: forecastObject.dates
            ? forecastObject.dates?.map((d, i) => {
                if (isCumulative) return [d, forecastObject.cumulative_gas_production[i] ?? 0];
                return [d, forecastObject.gas_rate[i] ?? 0];
              })
            : [],
          yAxisIndex: getYAxisIndex("gas"),
          hideSymbol: true,
          lineWidth: 2,
        },
        {
          name: `${name}Water`,
          type: "line",
          color: getSeriesColor("water", isStimulated),
          data: forecastObject.dates
            ? forecastObject.dates?.map((d, i) => {
                if (isCumulative) return [d, forecastObject.cumulative_water_production[i] ?? 0];
                return [d, forecastObject.water_rate[i] ?? 0];
              })
            : [],
          yAxisIndex: getYAxisIndex("water"),
          hideSymbol: true,
          lineWidth: 2,
        },
      ];
    },
    [getSeriesColor]
  );

  const rateSeries = useMemo(() => {
    if (!koldunCsgCalculation) return [];
    return [
      ...koldunCsgCalculation.simulated_data.reduce(
        (res, data, index) => res.concat(generateSeries(data, `Simulation ${index + 1} `, false, true)),
        [] as any[]
      ),
      ...generateSeries(koldunCsgCalculation.low_results, "Low Result "),
      ...generateSeries(koldunCsgCalculation.mid_results, "Mid Result "),
      ...generateSeries(koldunCsgCalculation.high_results, "High Result "),
      ...generateSeries(koldunCsgCalculation.mean_results, "Mean Result "),
    ];
  }, [generateSeries, koldunCsgCalculation]);

  const cumulativeSeries = useMemo(() => {
    if (!koldunCsgCalculation) return [];
    return [
      ...koldunCsgCalculation.simulated_data.reduce(
        (res, data, index) => res.concat(generateSeries(data, `Simulation ${index + 1} `, true, true)),
        [] as any[]
      ),
      ...generateSeries(koldunCsgCalculation.low_results, "Low Result ", true),
      ...generateSeries(koldunCsgCalculation.mid_results, "Mid Result ", true),
      ...generateSeries(koldunCsgCalculation.high_results, "High Result ", true),
      ...generateSeries(koldunCsgCalculation.mean_results, "Mean Result ", true),
    ];
  }, [generateSeries, koldunCsgCalculation]);

  const dataTableRows: Row<any>[] = useMemo(() => {
    if (!koldunCsgCalculation) return [];

    const selectedSimulation: ForecastDataObject = isNaN(Number(simulation))
      ? (koldunCsgCalculation[simulation as keyof KoldunCsgForecastData] as ForecastDataObject)
      : koldunCsgCalculation.simulated_data[Number(simulation)];

    return [
      {
        rowId: "header",
        cells: Object.keys(dictionary.koldunCsgForecastSimulationTable).map((item) => {
          return {
            text: dictionary.koldunCsgForecastSimulationTable[item].text,
            style: tableHeaderStyle,
            type: "custom",
            sub: dictionary.koldunCsgForecastSimulationTable[item].sub,
            link: dictionary.koldunCsgForecastSimulationTable[item].link,
          };
        }),
      },
      {
        rowId: "notation",
        cells: Object.keys(dictionary.koldunCsgForecastSimulationTable).map((item) => {
          return {
            text: dictionary.koldunCsgForecastSimulationTable[item].notation,
            type: "custom",
            style: tableHeaderStyle,
          };
        }),
      },
      ...Array.from(Array(selectedSimulation.dates.length).keys()).map((_, index) => {
        return {
          rowId: index,
          cells: Array.from(Object.keys(selectedSimulation)).map((key) => {
            const val = selectedSimulation?.[camelToSnakeCase(key) as keyof ForecastDataObject][index] ?? "-";
            return {
              type: "custom",
              text: formatToScientific(val),
              style: { display: "flex", justifyContent: "center" },
              nonEditable: true,
            };
          }),
        };
      }),
    ];
  }, [koldunCsgCalculation, simulation]);

  const dataTableLayerOption = useMemo(() => {
    return [
      ...Array.from(Array(koldunCsgCalculation?.simulated_data?.length).keys()).map((_, index) => {
        return {
          key: String(index),
          text: `${dictionary.koldunCsg.simulation} ${index + 1}`,
        };
      }),
      ...additionalSimulation.map((item) => {
        return {
          key: item,
          text: `${dictionary.koldunCsg.simulation} ${dictionary.koldunCsg[item]}`,
        };
      }),
    ];
  }, [koldunCsgCalculation?.simulated_data?.length]);

  const dataTableCol = Object.keys(dictionary.koldunCsgForecastSimulationTable).map((columnId, index) => {
    return { columnId: columnId, width: index === 0 ? 90 : 175 };
  });

  const rateLegend = useMemo(() => {
    return rateSeries.map((serie) => ({ name: serie.name, show: serie.name.includes("Result") }));
  }, [rateSeries]);

  const cumulativeLegend = useMemo(() => {
    return cumulativeSeries.map((serie) => ({ name: serie.name, show: serie.name.includes("Result") }));
  }, [cumulativeSeries]);

  return {
    chartXAxes,
    chartYAxes,
    cumulativeSeries,
    rateSeries,
    dataTableRows,
    dataTableLayerOption,
    dataTableCol,
    simulation,
    setSimulation,
    rateLegend,
    cumulativeLegend,
    chartCumulativeYAxes,
  };
};

export default useKoldunCsgForecastChart;
