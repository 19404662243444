import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";

import { FossilyticsChartAxis, FossilyticsChartCustomDataZoom, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { GenericChartResult } from "@/models/Generic";
import { ModuleId } from "@/model";

type UseLogRateProdProps = {
  unit: string;
  ratesCumSeries: FossilyticsChartSeries[];
  logChart?: GenericChartResult;
  currentModule?: ModuleId;
};

const useLogRateProd = ({ logChart, unit, ratesCumSeries, currentModule }: UseLogRateProdProps) => {
  const { palette } = useTheme();

  const ratesCumXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Cum. production (${unit})`, type: "value", color: palette.customColor.black }],
    [palette.customColor.black, unit]
  );

  const ratesCumYAxesLog = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Rates (${unit}/d)`, type: "log", color: palette.customColor.black }],
    [palette.customColor.black, unit]
  );

  const logRatesCumSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      {
        name: "Flow rates",
        type: "scatter",
        color: currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.customColor.red : palette.customColor.green,
        data: logChart ? logChart.cumulative_production.map((c, i) => [c, logChart.rates[i]]) : [],
      },
      ...ratesCumSeries.filter((s) => s.name !== "Flow rates"),
    ],
    [currentModule, palette.customColor.red, palette.customColor.green, logChart, ratesCumSeries]
  );

  const findLastValueInLogRatesCumSeriesForQg = useMemo(() => {
    const newLine = ratesCumSeries?.filter((line) => line.name === "Economic cutoff")[0]?.data;
    const rateCumSeriesLastPoint = ratesCumSeries?.filter((seriesItem) => seriesItem.name === "Flow rates")[0]?.data;

    let percentage = 100;

    if (newLine && newLine.length > 0 && rateCumSeriesLastPoint.length > 0) {
      const lastNumberInData = newLine[newLine.length - 1][0] as number;
      const flowRatesLastPoint = rateCumSeriesLastPoint[rateCumSeriesLastPoint.length - 1][0];

      if ((flowRatesLastPoint * 3) / lastNumberInData < 1) {
        percentage = Math.floor(((flowRatesLastPoint * 3) / lastNumberInData) * 100);
      }
    }
    return percentage;
  }, [ratesCumSeries]);

  const logQgCustomZoom = useMemo<FossilyticsChartCustomDataZoom>(() => {
    return {
      customDataZoom: true,
      dataZoomPercentage: findLastValueInLogRatesCumSeriesForQg,
    };
  }, [findLastValueInLogRatesCumSeriesForQg]);
  return { logQgCustomZoom, logRatesCumSeries, ratesCumXAxes, ratesCumYAxesLog };
};

export default useLogRateProd;
