import { initializeTahkCsg } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { TahkCsgStateResponse } from "../State";

type TahkCsgInitializeQuery = {
  data_set_ids: string[];
  project_id: string;
  group_id: string;
};

export const postInitializeTahkCsg = (body: TahkCsgInitializeQuery) => {
  return fetchApi<TahkCsgStateResponse>({
    path: initializeTahkCsg(body.project_id),
    body,
    type: "post",
  });
};
