import { fetchApi } from "@/utils/apiFetcher";
import { postMachineLearning } from "@/constants/apiUrl";
import { MLForecastState } from ".";

export const postCalculateMachineLearning = ({ data_set_ids, projectId }: { data_set_ids: string[]; projectId: string }) => {
  return fetchApi<MLForecastState>({
    path: postMachineLearning(projectId),
    type: "post",
    body: { data_set_ids },
  });
};
