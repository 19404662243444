import { Button } from "@mui/material";

import DropdownField from "@/components/fields/DropdownField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import InputField from "@/components/fields/InputField";
import LoadingIndicator from "@/components/LoadingIndicator";

import useThemeStyling from "@/utils/useThemeStyling";
import { formatNumber } from "@/util";
import { mapEnumToDropdown } from "@/utils/general";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { AutoRtaAnalysis, DualPorosityModel, TransientModel, TransientModelBoundaryConditions } from "@/models/gaz/autoRta";
import { SummaryCard } from "@/models/Generic";

import { dataTableColumns } from "../../constants";
import dictionary from "@/constants/dictionary";
import SummaryTable from "@/components/SummaryTable";

export type AnalysisSettingProps = {
  onChangeInput: (value: any, firstKey: string, secKey?: string, thirdKey?: string, fourthKey?: string) => void;
  analysisInput: AutoRtaAnalysis;
  isLoading: boolean;
  errorInputValidation?: ErrorValidationDetail[];
  analysisSummaryCardParameter?: SummaryCard[];
  onClickCalculateAnalysis: () => void;
};

const AnalysisSetting = ({
  onChangeInput,
  analysisInput,
  isLoading,
  analysisSummaryCardParameter = [],
  errorInputValidation,
  onClickCalculateAnalysis,
}: AnalysisSettingProps) => {
  const { elevatedCard, palette } = useThemeStyling();

  const disabledInput = analysisInput.smart_fit || isLoading;

  const renderDrainageOption = () => {
    switch (analysisInput.transient_flow_model.section_transient_model_type.selected_transient_model) {
      case TransientModel.Radial:
        return (
          <InputField
            label="Drainage Radius"
            suffix={`ft`}
            debounceDelay={800}
            disabled={disabledInput}
            value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_radial.drainage_radius}
            onChange={(v) => {
              onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_radial", "drainage_radius");
            }}
            keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_radial.drainage_radius"
            errors={errorInputValidation}
          />
        );
      case TransientModel.Linear:
        return (
          <>
            <InputField
              label="Drainage Length"
              suffix={`ft`}
              debounceDelay={800}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_length}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_linear", "drainage_length");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_length"
              errors={errorInputValidation}
            />
            <InputField
              label="Drainage Width"
              suffix={`ft`}
              debounceDelay={800}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_width}
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_linear", "drainage_width");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_width"
              errors={errorInputValidation}
            />
          </>
        );
      case TransientModel.UniformFluxFracture:
        return (
          <>
            <InputField
              label="Drainage Radius"
              suffix={`ft`}
              debounceDelay={800}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_uniform_flux_fracture.drainage_radius}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_uniform_flux_fracture", "drainage_radius");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_uniform_flux_fracture.drainage_radius"
              errors={errorInputValidation}
            />
            <InputField
              label="Fracture Half Length"
              suffix={`ft`}
              debounceDelay={800}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_uniform_flux_fracture.fracture_half_length}
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_uniform_flux_fracture",
                  "fracture_half_length"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_uniform_flux_fracture.fracture_half_length"
              errors={errorInputValidation}
            />
          </>
        );

      default:
        return (
          <>
            <InputField
              label="Drainage Radius"
              suffix={`ft`}
              debounceDelay={1000}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.drainage_radius}
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_composite_radial", "drainage_radius");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_composite_radial.drainage_radius"
              errors={errorInputValidation}
            />

            <InputField
              label="Inner region Radius"
              suffix={`ft`}
              debounceDelay={1000}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_radius}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_composite_radial", "inner_region_radius");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_radius"
              errors={errorInputValidation}
            />

            <InputField
              label="Inner region Permeability"
              suffix={`ft`}
              debounceDelay={1000}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_permeability}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_composite_radial",
                  "inner_region_permeability"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_permeability"
              errors={errorInputValidation}
            />
          </>
        );
    }
  };

  return (
    <div>
      <div style={{ ...elevatedCard, height: "80vh" }}>
        <h3 style={{ color: palette.primary.main, margin: 0 }}>Transient Analysis</h3>

        <FormControlLabel
          control={
            analysisInput ? (
              <Checkbox
                onChange={(e) => {
                  onChangeInput(e.target.checked, "smart_fit");
                }}
                checked={analysisInput.smart_fit}
                size="small"
                disabled={isLoading}
              />
            ) : (
              <></>
            )
          }
          label="Smart Fit"
        />

        <DropdownField
          label="Model"
          options={mapEnumToDropdown(TransientModel)}
          selectedKey={analysisInput.transient_flow_model.section_transient_model_type.selected_transient_model}
          disabled={disabledInput}
          onChange={(v) => {
            onChangeInput(v, "transient_flow_model", "section_transient_model_type", "selected_transient_model");
          }}
          errors={errorInputValidation}
          keyField="analysis.transient_flow_model.section_transient_model_type.selected_transient_model"
        />

        {renderDrainageOption()}

        <DropdownField
          label="Boundary Condition"
          options={mapEnumToDropdown(TransientModelBoundaryConditions)}
          selectedKey={analysisInput.transient_flow_model.selected_boundary_condition}
          disabled={disabledInput}
          onChange={(v) => {
            onChangeInput(v, "transient_flow_model", "selected_boundary_condition");
          }}
          errors={errorInputValidation}
          keyField="analysis.transient_flow_model.selected_boundary_condition"
        />

        <InputField
          label="Skin"
          suffix="Dim"
          debounceDelay={1000}
          disabled={disabledInput}
          value={analysisInput.transient_flow_model.skin}
          onChange={(v) => {
            onChangeInput(v, "transient_flow_model", "skin");
          }}
          keyField="analysis.transient_flow_model.skin"
          errors={errorInputValidation}
        />

        <InputField
          label="Permeability"
          suffix="mD"
          debounceDelay={1000}
          value={analysisInput.transient_flow_model.permeability}
          disabled={disabledInput}
          onChange={(v) => {
            onChangeInput(v, "transient_flow_model", "permeability");
          }}
          keyField="analysis.transient_flow_model.permeability"
          errors={errorInputValidation}
        />

        <DropdownField
          label="Dual Porosity Model"
          options={mapEnumToDropdown(DualPorosityModel)}
          selectedKey={analysisInput.transient_flow_model.section_dual_porosity.selected_dual_porosity_model}
          disabled={disabledInput}
          onChange={(v) => {
            onChangeInput(v, "transient_flow_model", "section_dual_porosity", "selected_dual_porosity_model");
          }}
          errors={errorInputValidation}
          keyField="analysis.transient_flow_model.section_dual_porosity.selected_dual_porosity_model"
        />
        {analysisInput.transient_flow_model.section_dual_porosity.selected_dual_porosity_model !== "NoModel" && (
          <>
            <InputField
              label="Storage Capacity Ratio"
              suffix="dim"
              debounceDelay={1000}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_dual_porosity.storage_capacity_ratio}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_dual_porosity", "storage_capacity_ratio");
              }}
              keyField="analysis.transient_flow_model.section_dual_porosity.storage_capacity_ratio"
              errors={errorInputValidation}
            />

            <InputField
              label="Inter-Porosity Flow Parameter"
              suffix="Dim"
              debounceDelay={1000}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_dual_porosity.interpososity_flow_parameter}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_dual_porosity", "interpososity_flow_parameter");
              }}
              keyField="analysis.transient_flow_model.section_dual_porosity.interpososity_flow_parameter"
              errors={errorInputValidation}
            />
          </>
        )}

        <Button
          onClick={(e) => {
            e.preventDefault();
            onClickCalculateAnalysis();
          }}
          disabled={isLoading}
          style={{ color: "white", marginTop: 20, width: "100%" }}
          variant="contained"
        >
          {dictionary.autoRta.calculateAnalysis}
        </Button>
        <div style={{ marginTop: 30, width: 400 }}>
          <div style={{ color: palette.primary.main }}>Summary</div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <SummaryTable
              rows={analysisSummaryCardParameter.map((item: any, i) => {
                return {
                  parameter: item.parameter,
                  value: formatNumber(item.value),
                  units: item.unit,
                };
              })}
              headers={dataTableColumns}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalysisSetting;
