import { fetchApi } from "@/utils/apiFetcher";
import { navPermission } from "@/constants/apiUrl";
import { NavPermissions } from "../State";

export const getPermissionNav = () => {
  return fetchApi<NavPermissions>({
    path: navPermission,
    type: "get",
  });
};
