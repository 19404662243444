import React, { useMemo } from "react";
import { shallow } from "zustand/shallow";

import Button from "@mui/material/Button";

import { useAppStore } from "@/features/app";

import Tabs from "@/components/Tabs";
import LoadingIndicator from "@/components/LoadingIndicator";
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import { FluidType } from "@/models/Generic";

import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";

import useRollup from "./hooks/useRollup";
import FossilyticsChart from "@/components/FossilyticsChart";
import useForecastData from "./hooks/useForecastData";
import IntervalButton from "@/components/IntervalButton";

type RulonRollupProps = {
  type: FluidType;
};

const RulonRollup = ({ type }: RulonRollupProps) => {
  const { selectedDataSets, isLoading, project, setApiError, apiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      project: state.project,
      setApiError: state.setApiError,
      apiError: state.apiError,
    }),
    shallow
  );

  const { elevatedCard } = useThemeStyling();

  const {
    loading,
    onChangeCell,
    rollupColumns,
    rollupRows,
    onClickValidate,
    rollupState,
    summaryColumns,
    summaryRows,
    summaryYAxes,
    summaryXAxes,
    summarySeries,
    onClickCalculate,
    calculationForecast,
    onClickExport,
  } = useRollup({
    selectedDataSets,
    isLoading,
    project,
    setApiError,
    type,
    apiError,
  });

  const {
    forecastColumns,
    forecastRow: rateRow,
    forecastSeries: rateSeries,
    forecastXAxes,
    forecastYAxes,
  } = useForecastData({
    type: "rates",
    calculation: calculationForecast,
    fluidType: type,
  });

  const { forecastSeries: cumulativeSeries, forecastRow: cumulativeRow } = useForecastData({
    type: "cumulative_production",
    calculation: calculationForecast,
    fluidType: type,
  });

  const forecastChartTabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.rulon.rate}</span>,
        content: (
          <div
            style={{
              padding: 10,
            }}
          >
            {loading && <LoadingIndicator />}
            <div style={{ margin: "auto", display: "flex" }}>
              <ReactGridTable
                className="noScrollbarAll"
                innerStyle={{
                  display: "flex",
                  marginTop: 23,
                }}
                viewWidth={550}
                viewHeight={650}
                columns={forecastColumns}
                rows={rateRow}
                enableRangeSelection
                enableColumnSelection
                stickyTopRows={2}
              />
              <div
                style={{
                  height: 550,
                  width: 550,
                }}
              >
                <FossilyticsChart
                  id={"Rollup_rate_chart"}
                  key={"Rollup_rate_chart"}
                  isLoading={loading}
                  xAxes={forecastXAxes}
                  yAxes={forecastYAxes}
                  series={rateSeries}
                />
              </div>
            </div>
          </div>
        ),
        key: "Rate",
      },
      {
        label: <span>{dictionary.rulon.cumulativeProduction}</span>,
        content: (
          <div
            style={{
              padding: 10,
            }}
          >
            {loading && <LoadingIndicator />}
            <div style={{ margin: "auto", display: "flex" }}>
              <ReactGridTable
                className="noScrollbarAll"
                innerStyle={{
                  display: "flex",
                  marginTop: 23,
                }}
                viewWidth={550}
                viewHeight={650}
                columns={forecastColumns}
                rows={cumulativeRow}
                enableRangeSelection
                enableColumnSelection
                stickyTopRows={2}
              />
              <div
                style={{
                  height: 550,
                  width: 550,
                }}
              >
                <FossilyticsChart
                  id={"Rollup_rate_chart"}
                  key={"Rollup_rate_chart"}
                  isLoading={loading}
                  xAxes={forecastXAxes}
                  yAxes={forecastYAxes}
                  series={cumulativeSeries}
                />
              </div>
            </div>
          </div>
        ),
        key: "Cumulative production",
      },
    ];
  }, [cumulativeRow, cumulativeSeries, forecastColumns, forecastXAxes, forecastYAxes, loading, rateRow, rateSeries]);

  const forecastTabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.rulon.summary}</span>,
        content: (
          <div
            style={{
              padding: 10,
            }}
          >
            {loading && <LoadingIndicator />}
            <div style={{ margin: "auto", display: "flex" }}>
              <ReactGridTable
                className="noScrollbarAll"
                innerStyle={{
                  display: "flex",
                  marginTop: 23,
                }}
                viewWidth={600}
                viewHeight={650}
                columns={summaryColumns}
                rows={summaryRows}
                enableRangeSelection
                enableColumnSelection
                stickyTopRows={3}
                onCellsChanged={onChangeCell}
              />
              <div
                style={{
                  height: 600,
                  width: 550,
                }}
              >
                <FossilyticsChart
                  id={"Rollup_chart"}
                  key={"Rollup_chart"}
                  isLoading={loading}
                  hideLegend
                  xAxes={summaryXAxes}
                  yAxes={summaryYAxes}
                  series={summarySeries}
                />
              </div>
            </div>
          </div>
        ),
        key: "Summary",
      },
      {
        label: <span>{dictionary.rulon.forecast}</span>,
        content: (
          <div
            style={{
              padding: 10,
            }}
          >
            {loading && <LoadingIndicator />}
            <div style={{ margin: "auto" }}>
              <Tabs tabList={forecastChartTabList} />
            </div>
          </div>
        ),
        key: "Forecast",
      },
    ];
  }, [forecastChartTabList, loading, onChangeCell, summaryColumns, summaryRows, summarySeries, summaryXAxes, summaryYAxes]);

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.rulon.validation}</span>,
        content: (
          <div
            style={{
              padding: 10,
            }}
          >
            {loading && <LoadingIndicator />}
            <div style={{ ...elevatedCard, width: 1080, margin: "auto" }}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onClickValidate();
                }}
                style={{ color: "white", width: "100%" }}
                variant="contained"
                disabled={loading || !rollupState}
              >
                {dictionary.rulon.saveAndValidate}
              </Button>
              <ReactGridTable
                className="noScrollbarAll"
                innerStyle={{
                  display: "flex",
                  marginTop: 23,
                }}
                viewWidth={"100%"}
                viewHeight={650}
                columns={rollupColumns}
                rows={rollupRows}
                enableRangeSelection
                enableColumnSelection
                stickyTopRows={2}
                onCellsChanged={onChangeCell}
              />
            </div>
          </div>
        ),
        key: "Validate",
      },
      {
        label: <span>{dictionary.rulon.rollup}</span>,
        content: (
          <div
            style={{
              padding: 10,
              ...elevatedCard,
            }}
          >
            {loading && <LoadingIndicator />}
            <div
              style={{
                display: "grid",
                gridGap: 20,
                gridTemplateColumns: "1fr 1fr",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <IntervalButton
                label={dictionary.rulon.calculate}
                loading={loading}
                onClickButton={(option) => {
                  onClickCalculate(option);
                }}
              />
              <IntervalButton
                label={dictionary.rulon.export}
                loading={loading}
                onClickButton={(option) => {
                  onClickExport(option);
                }}
              />
            </div>
            <div style={{ ...elevatedCard, margin: "auto" }}>
              <Tabs tabList={forecastTabList} />
            </div>
          </div>
        ),
        key: "Rollup",
      },
    ];
  }, [
    elevatedCard,
    forecastTabList,
    loading,
    onChangeCell,
    onClickCalculate,
    onClickExport,
    onClickValidate,
    rollupColumns,
    rollupRows,
    rollupState,
  ]);

  return (
    <div
      style={{
        padding: 15,
        overflowY: "auto",
        height: "85vh",
      }}
    >
      <Tabs tabList={tabList} />
    </div>
  );
};

export default RulonRollup;
