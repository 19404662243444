import React from "react";
import { Dropdown, Pivot, PivotItem, Stack, Text } from "@fluentui/react";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";

import ModuleDataView from "../../ModuleDataView";
import ModulePage from "../../ModulePage";

import ModuleSpadWorAnalysesSelect from "./ModuleSpadWorAnalysesSelect";
import FossilyticsChart from "../../../components/FossilyticsChart";
import FossilyticsGrid from "../../../components/FossilyticsGrid";
import FossilyticsNumberField from "../../../components/fields/FossilyticsNumberField";

// hooks
import useModuleData from "./hooks/useModuleData";
import useSpadWorData, { axisTypeOptions, AxisType } from "./hooks/useSpadWorData";
import useThemeStyling from "@/utils/useThemeStyling";

const ModuleSpadWor = () => {
  const { palette, elevatedCard } = useThemeStyling();
  const { selectedDataSets, isLoading, hideSidebar, postRequest, setCsvData } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      hideSidebar: state.hideSidebar,
      postRequest: state.postRequest,
      setCsvData: state.setCsvData,
    }),
    shallow
  );

  const { moduleFields } = useModuleData();

  const {
    dataSet,
    cleaned,
    analyses,
    validTarget,
    reservesTableGroups,
    reservesTableCols,
    reservesTableData,
    worAxisType,
    target,
    combinedChartProps,
    mappedAnalyses,
    currentTab,

    setCleaned,
    setWorAxisType,
    setAnalyses,
    setTarget,
    setCurrentTab,
  } = useSpadWorData({
    selectedDataSets,
    postRequest,
    setCsvData,
  });

  return (
    <ModulePage
      title={"SPAD: Water Oil Ratio"}
      tabIndex={currentTab}
      onTabChange={setCurrentTab}
      tabs={
        dataSet && [
          {
            headerText: "Data View",
            itemIcon: "ScatterChart",
            disabled: false,
            canSaveAsImg: true,
            content: (
              <ModuleDataView
                isActive={currentTab === 0}
                moduleFields={moduleFields}
                showAtmosphericCorrection={false}
                showCombinedFirst
                onDataCleaned={setCleaned}
              />
            ),
          },
          {
            headerText: "Analyses",
            itemIcon: "LineChart",
            disabled: !cleaned,
            canSaveAsImg: true,
            content: (
              <Stack horizontal style={{ height: "100%" }} tokens={{ childrenGap: 20, padding: 20 }}>
                {/* side bar section */}
                {!hideSidebar && (
                  <Stack tokens={{ childrenGap: 20 }}>
                    <Stack.Item grow={1} style={elevatedCard}>
                      <Text styles={{ root: { color: palette.primary.main } }}>Inputs</Text>

                      <ModuleSpadWorAnalysesSelect selectedAnalyses={analyses ?? []} onAnalysesUpdate={setAnalyses} />

                      {analyses && analyses.indexOf("WOR") >= 0 ? (
                        <Dropdown
                          label="WOR vertical axis type"
                          defaultSelectedKey={worAxisType}
                          onChange={(_, o) => (o !== undefined ? setWorAxisType(o.key as AxisType) : null)}
                          options={axisTypeOptions}
                        />
                      ) : null}

                      <FossilyticsNumberField
                        label="Target water cutoff"
                        suffix="%"
                        value={target ? target * 100 : null}
                        onChange={(v) => setTarget(v ? v / 100 : 0)}
                      />
                    </Stack.Item>
                  </Stack>
                )}

                <Stack className="PivotWrapper" grow={1} style={elevatedCard}>
                  <Pivot styles={{ itemContainer: { flex: "1" } }}>
                    {/* map analysis to separate graph and tab view */}
                    {mappedAnalyses?.map((analysis) => {
                      return (
                        <PivotItem key={analysis.key} headerText={analysis.key}>
                          <Stack horizontal grow={1} tokens={{ childrenGap: 20 }} style={{ height: "100%" }}>
                            <FossilyticsChart id={`spad_wor_${analysis.key}`} isLoading={isLoading} {...analysis} />
                          </Stack>
                        </PivotItem>
                      );
                    })}

                    {validTarget && (
                      <PivotItem headerText="Analysis Summary">
                        <FossilyticsGrid columnGroups={reservesTableGroups} columns={reservesTableCols} data={reservesTableData} />
                      </PivotItem>
                    )}

                    <PivotItem headerText="Combined chart">
                      {analyses && analyses.length > 0 && <FossilyticsChart id="spad_wor_combined" isLoading={isLoading} {...combinedChartProps} />}
                    </PivotItem>
                  </Pivot>
                </Stack>
              </Stack>
            ),
          },
        ]
      }
    />
  );
};

export default ModuleSpadWor;
