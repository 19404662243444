import { spadDeclineAnalysisCalculate } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { SpadDeclineCalculation, SpadDeclineType, SpadDeclinePayload } from "../State";

export const postCalculationSpadDecline = (body: SpadDeclinePayload, type: SpadDeclineType, projectId: string) => {
  return fetchApi<SpadDeclineCalculation, SpadDeclinePayload>({
    path: spadDeclineAnalysisCalculate(type, projectId),
    body,
    type: "post",
  });
};
