import { Pivot, PivotItem, Spinner, Stack } from "@fluentui/react";

import React, { useMemo } from "react";

import chroma from "chroma-js";
import { DataSet } from "../../../../../../../model";
import { ModuleKoldunMcsimGasOilOptions, ModuleKoldunMcsimOutput } from "../../model";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "../../../../../../../components/FossilyticsChart";
import FossilyticsGrid, { FossilyticsGridColumn, FossilyticsGridColumnGroup } from "../../../../../../../components/FossilyticsGrid";
import useThemeStyling from "@/utils/useThemeStyling";

interface ModuleKoldunMcsimGasOilOutputViewProps {
  isLoading: boolean;
  dataSet: DataSet;
  output: ModuleKoldunMcsimOutput | undefined;
  module: string;
  value: ModuleKoldunMcsimGasOilOptions;
}

export function ModuleKoldunMcsimGasOilOutputView({ isLoading, output, module }: Readonly<ModuleKoldunMcsimGasOilOutputViewProps>) {
  const { palette } = useThemeStyling();

  const profilesTableGroups = useMemo<FossilyticsGridColumnGroup[]>(() => {
    if (!output) return [];
    return [
      { header: "", colspan: 1 },
      ...output.rates_mmscf_d.map((_, i) => ({
        header: `Profile ${i + 1}`,
        colspan: 2,
      })),
    ];
  }, [output]);

  const profilesTableColumns = useMemo<FossilyticsGridColumn[]>(() => {
    if (!output) return [];
    return [
      { key: "day", type: "number" as "number", header: "Day", width: 50, editable: false },
      ...output.rates_mmscf_d.flatMap((p, i) => [
        {
          key: `profile-${i}-q`,
          type: "number" as "number",
          header: "Rate (MMscf/d)",
          width: 125,
          editable: false,
        },
        {
          key: `profile-${i}-Q`,
          type: "number" as "number",
          header: "Cum. prod (MMscf)",
          width: 125,
          editable: false,
        },
      ]),
    ];
  }, [output]);
  const profilesTableItems = useMemo<number[][]>(() => {
    if (!output) return [];
    return output.days.map((d, j) => [d, ...output.rates_mmscf_d.flatMap((rates, i) => [rates[j], output.cumes_mmscf[i][j]])]);
  }, [output]);
  const profilesXAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Days", type: "value", color: palette.customColor.black, min: 1 }], [palette]);
  const profilesYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Gas rate (MMscf/day)", type: "value", color: palette.customColor.black }],
    [palette]
  );
  const profilesSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      output
        ? output.rates_mmscf_d.map((p, i) => ({
            name: `Profile ${i + 1}`,
            type: "line",
            hideSymbol: true,
            lineWidth: !output.indices || output.indices.indexOf(i) >= 0 ? 3 : 1,
            color: !output.indices || output.indices.indexOf(i) >= 0 ? palette.customColor.red : chroma(palette.customColor.red).alpha(0.2).hex(),
            z: !output.indices || output.indices.indexOf(i) >= 0 ? 2 : 1,
            data: output.days.map((d, j) => [d, p[j]]),
          }))
        : [],
    [palette, output]
  );

  const simCasesTableColumns = useMemo<FossilyticsGridColumn[]>(() => {
    if (!output) return [];
    return [
      { key: "p", type: "number", header: "Profile", width: 50 },
      { key: "ogip", type: "number", header: "OGIP (MMscf)", width: 135 },
      { key: "watsat", type: "number", header: "Water saturation (%)", width: 135 },
      { key: "por", type: "number", header: "Porosity (%)", width: 135 },
      { key: "perm", type: "number", header: "Permeability (md)", width: 135 },
      { key: "netpay", type: "number", header: "Net pay (ft)", width: 135 },
      { key: "press", type: "number", header: "Initial pressure (psia)", width: 135 },
    ];
  }, [output]);
  const simCasesTableItems = useMemo<number[][]>(() => {
    if (!output) return [];
    return output.cases.map((c, i) => [
      i + 1,
      c.ogip_mmscf,
      c.water_sat_perc,
      c.porosity_perc,
      c.permeability_md,
      c.net_pay_ft,
      c.initial_pressure_psia,
    ]);
  }, [output]);

  return (
    <Stack className="PivotWrapper" grow={1}>
      <Pivot styles={{ itemContainer: { flex: "1" } }}>
        {module === "koldun_csg" ? null : (
          <PivotItem headerText="Profiles">
            <FossilyticsChart
              id="koldun_profiles"
              isLoading={isLoading}
              hideLegend
              xAxes={profilesXAxes}
              yAxes={profilesYAxes}
              series={profilesSeries}
            />
          </PivotItem>
        )}

        <PivotItem headerText={"Profiles table"} style={{ position: "relative", width: "88vw", overflowX: "scroll" }}>
          {isLoading ? (
            <Spinner
              label="Loading..."
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <FossilyticsGrid columnGroups={profilesTableGroups} columns={profilesTableColumns} data={profilesTableItems} />
          )}
        </PivotItem>

        <PivotItem headerText="Simulation cases" style={{ position: "relative" }}>
          {isLoading ? (
            <Spinner
              label="Loading..."
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <div
              data-is-scrollable="true"
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                overflow: "auto",
              }}
            >
              <FossilyticsGrid columns={simCasesTableColumns} data={simCasesTableItems} />
            </div>
          )}
        </PivotItem>
      </Pivot>
    </Stack>
  );
}

export default ModuleKoldunMcsimGasOilOutputView;
