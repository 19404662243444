// mui component
import { colorPalette } from "@/features/themes/mui-theme/mui-theme";
import { useTheme as useThemeMui } from "@mui/material/styles";

const effects = {
  elevation4: "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
  elevation8: "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
  elevation16: "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
  elevation64: "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
  roundedCorner2: "2px",
  roundedCorner4: "4px",
  roundedCorner6: "6px",
};

const useThemeStyling = () => {
  const { palette } = useThemeMui();

  const elevatedCard = {
    boxShadow: effects.elevation4,
    padding: 15,
    borderRadius: effects.roundedCorner4,
  } as React.CSSProperties;

  return {
    palette: { ...palette, ...colorPalette },
    elevatedCard,
    effects,
  };
};

export default useThemeStyling;
