import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { shallow } from "zustand/shallow";

import ModulePage from "../../../../ModulePage";
import {
  DataSet,
  FormationCompressibility,
  isDataSet,
  RockType,
  TankType,
  ZMethod,
  WaterViscosityMethod,
  GasViscosityMethod,
} from "../../../../../model";

import { ModuleKoldunMcsimGasOilOptions, ModuleKoldunMcsimUncertainParamDistribution } from "./model";

import { useSettingState } from "../../../../../SettingsState";
import { ModuleKoldunMcsimGasOilOutputView } from "./Components/ModuleKoldunMcsimOutputView/ModuleKoldunMcsimOutputView";
import ModuleKoldunMcsimGasOilInputGridTab from "./Components/ModuleKoldunMcsimGasOilInputGridTab/ModuleKoldunMcsimGasOilInputGridTab";
import { useOutput } from "./hooks/useOutput";
import { useAppStore } from "@/features/app";
import useThemeStyling from "@/utils/useThemeStyling";

const DEFAULT_NUM_DAYS = 300;

export const getLastValidDataIndex = (inputs?: [number, number, number, number][], num_days?: number) => {
  if (inputs && num_days != null) {
    for (let i = 0; i < inputs.length; i++) {
      if ((i > 0 && inputs[i - 1][0]! >= inputs[i][0]!) || inputs[i][0]! > num_days) {
        return i - 1;
      }
    }
  }
  return 0;
};

interface ModuleKoldunMcsimGasOilContentProps {
  title: string;
  module: string;
}

function ModuleKoldunMcsimGasOilContent({ title, module }: Readonly<ModuleKoldunMcsimGasOilContentProps>) {
  const { selectedDataSets, isLoading } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
    }),
    shallow
  );

  const [dataSet, setDataSet] = useState<DataSet>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { elevatedCard } = useThemeStyling();

  const [options, setOptions] = useSettingState<ModuleKoldunMcsimGasOilOptions>(`${module}_options`, false, {
    default: {
      num_days: DEFAULT_NUM_DAYS,
      tank_type: TankType.Vertical,

      form_temp_of: 158,
      gas_gravity: 0.65,
      n2_perc: 0,
      co2_perc: 0,
      h2s_perc: 0,
      z_method: ZMethod.DPR,
      form_compressibility_method: FormationCompressibility.HALL,

      rock_type: RockType.SANDSTONE,

      wellbore_radius_ft: 0.3,

      num_simulations: 120,
      ogip_mmscf: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 4000 },

      //CSG specific options
      area_acres: 90,

      water_viscosity_method: WaterViscosityMethod.MCCAIN,
      gas_viscosity_method: GasViscosityMethod.GONZALES_EAKIN,
      salinity_ppm: 0,

      z_correlation: "INTERP",
      res_prop: {
        permeability_xaxis_md: 5,
        permeability_yaxis_md: 5,
        permeability_zaxis_md: 5,
        well_position_xaxis_ft: 990,
        well_position_yaxis_ft: 990,
        well_position_zaxis_ft: 25,
        res_width_xaxis_ft: 1980,
        res_length_yaxis_ft: 1980,
        res_height_zaxis_ft: 50,
        horizontal_well_length_yaxis_ft: 1200,
      },
      water_sat_perc: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 40 },
      porosity_perc: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 0.5 },
      permeability_md: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 50 },
      net_pay_ft: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 50 },
      initial_pressure_psia: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 2500 },
      langmuir_v: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 650 },
      langmuir_p: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 650 },
      coal_density: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 1.4 },
      desorbtion_press: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 2200 },
      type_flag: "",

      contract_rate_type_flag: "water",

      temp_inputs: [[1, 0, 0, -1], ...Array.from(Array(DEFAULT_NUM_DAYS - 1).keys()).map(() => [0, 0, 0, -1] as [number, number, number, number])],
      // Days, Flowing BH pressures, Skins, contract_rate_mmscf_d
      inputs: [[1, 50, 0, 10], ...Array.from(Array(DEFAULT_NUM_DAYS - 1).keys()).map(() => [0, 0, 0, -1] as [number, number, number, number])],
    },
  });

  const { output } = useOutput(dataSet, options, currentTab);

  useEffect(() => {
    setDataSet(isDataSet(selectedDataSets) ? selectedDataSets : undefined);
    setCurrentTab((prev) => (prev === 1 ? 0 : prev));
  }, [selectedDataSets]);

  return (
    <ModulePage
      title={title}
      tabIndex={currentTab}
      onTabChange={setCurrentTab}
      tabs={
        dataSet && [
          {
            headerText: "Input Grid",
            itemIcon: "InputField",
            disabled: isLoading,
            disableHideSidebar: true,
            content: currentTab === 0 ? <ModuleKoldunMcsimGasOilInputGridTab value={options} onChange={setOptions} module={module} /> : <></>,
          },
          {
            headerText: "Forecast",
            itemIcon: "LineChart",
            disabled: isLoading || !options,
            disableHideSidebar: true,
            canSaveAsImg: true,
            content: (
              <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
                <Stack style={elevatedCard} grow={1}>
                  <ModuleKoldunMcsimGasOilOutputView module={module} isLoading={isLoading} dataSet={dataSet} output={output} value={options} />
                </Stack>
              </Stack>
            ),
          },
        ]
      }
    />
  );
}

export default ModuleKoldunMcsimGasOilContent;
