import { Stack, Text } from "@fluentui/react";
import { ModuleKoldunMcsimGasOilOptions, ModuleKoldunMcsimUncertainParamDistribution } from "../../model";
import { TankType } from "../../../../../../../model";
import React, { useEffect, useMemo } from "react";
import { CellChange, Column, NumberCell, ReactGrid, Row } from "@silevis/reactgrid";
import FossilyticsNumberField from "../../../../../../../components/fields/FossilyticsNumberField";
import { getLastValidDataIndex } from "../../ModuleKoldunMcsimGasOilContent";
import BaseInputs from "./Components/BaseInputs";

import HorizontalSelectInputs from "./Components/HorizontalSelectInputs";
import GasUncertainParameterInputs from "./Components/GasUncertainParameterInputs";
import OilUncertainParameterInputs from "./Components/OilUncertainParameterInputs";
import useThemeStyling from "@/utils/useThemeStyling";

const columnWidthCss = "calc((100% - 40px) / 3)";

const inputsCols = [
  { columnId: 0, width: 125 },
  { columnId: 1, width: 200 },
  { columnId: 2, width: 200 },
  { columnId: 3, width: 200 },
] as Column[];

interface ModuleKoldunMcsimGasOilInputGridTabProps {
  value: ModuleKoldunMcsimGasOilOptions | undefined;
  onChange: (val: ModuleKoldunMcsimGasOilOptions) => void;
  module: string;
}

function ModuleKoldunMcsimGasOilInputGridTab({ value, onChange, module }: Readonly<ModuleKoldunMcsimGasOilInputGridTabProps>) {
  //useDebounce When user change rel perm parameters

  // When drainage radius is entered, set fixed inputs
  useEffect(() => {
    if (!value?.drainage_radius_ft) return;
    onChange({ ...value, ogip_mmscf: { distribution: ModuleKoldunMcsimUncertainParamDistribution.FIXED, value: 0 } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.drainage_radius_ft, onChange]);

  // Calculate last valid data index in data table
  const lastValidDataIndex = useMemo(() => {
    return getLastValidDataIndex(value?.inputs, value?.num_days);
  }, [value]);

  const { palette, elevatedCard } = useThemeStyling();

  if (!value) {
    return <></>;
  }
  // Parse data from spreadsheet to input array
  const inputsRows = [
    {
      rowId: "header",
      cells: [
        { type: "header", text: "Days" },
        { type: "header", text: "Flowing BH pressure (psia)" },
        { type: "header", text: "Skin" },
        {
          type: "header",
          text: "Contract rate(MMscf/d) ",
        },
      ],
    },
    ...value.inputs.map((p, i) => {
      let background = undefined;
      if (i === 0) {
        background = palette.customColor.neutralLight;
      }
      if (i > lastValidDataIndex) {
        background = palette.primary.light;
      }
      return {
        rowId: i,
        cells: [
          {
            type: "number",
            value: p[0],
            nonEditable: i === 0,
            hideZero: true,
            style: {
              background,
            },
          },
          {
            type: "number",
            value: p[1],
            hideZero: p[0] === 0,
          },
          {
            type: "number",
            value: p[2],
            hideZero: p[0] === 0,
          },
          {
            type: "number",
            value: p[3] === -1 ? 0 : p[3],
            hideZero: p[0] === 0,
          },
        ],
      };
    }),
  ] as Row[];

  const handleChanges = (changes: CellChange[]) => {
    const newInputs = JSON.parse(JSON.stringify(value.inputs));
    changes.forEach((change) => {
      const numberCell = change.newCell as NumberCell;
      const idx = change.rowId as number;
      const colIdx = change.columnId as number;
      newInputs[idx][colIdx] = isNaN(numberCell.value) ? 0 : numberCell.value;
    });

    onChange({ ...value, inputs: newInputs });
  };

  return (
    <Stack tokens={{ padding: 20, childrenGap: 20 }}>
      <Stack.Item style={elevatedCard}>
        <Text styles={{ root: { color: palette.primary.main } }}>Inputs</Text>
        <BaseInputs value={value} onChange={onChange} module={module} />
      </Stack.Item>

      {value.tank_type === TankType.Horizontal ? <HorizontalSelectInputs value={value} onChange={onChange} /> : null}
      <Stack.Item style={elevatedCard}>
        <Text styles={{ root: { color: palette.primary.main } }}>Uncertain Parameters</Text>

        <Stack tokens={{ childrenGap: 5 }}>
          <FossilyticsNumberField
            type="int"
            label="Number of simulations"
            suffix="simulations"
            max={200}
            styles={{ root: { width: columnWidthCss } }}
            value={value.num_simulations}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    num_simulations: v,
                  })
                : null
            }
          />

          {module === "koldun_gas" ? (
            <GasUncertainParameterInputs value={value} onChange={onChange} />
          ) : (
            <OilUncertainParameterInputs value={value} onChange={onChange} />
          )}
        </Stack>
      </Stack.Item>

      <Stack style={elevatedCard} tokens={{ childrenGap: 5 }}>
        <Text styles={{ root: { color: palette.primary.main } }}>Data</Text>

        <Stack horizontal disableShrink>
          <FossilyticsNumberField
            type="int"
            label="Number of days"
            suffix="days"
            styles={{
              root: {
                width: 385,
              },
            }}
            value={value.num_days}
            onChange={(rawnum) => {
              const newInputs = [...value.inputs];
              const num = Number(rawnum ?? 0);
              if (newInputs.length > num) {
                // Ensure we don't lose inputted data
                const lastValidIndex = newInputs.reduce((lastValidIndex, row, i) => {
                  if (row.some((v) => v > 0)) return i;
                  return lastValidIndex;
                }, 0);
                newInputs.splice(Math.max(num, lastValidIndex + 1));
              } else if (newInputs.length < num) {
                const a = Array.from(Array(num - newInputs.length).keys());
                newInputs.push(...a.map(() => [0, 0, 0, 0] as [number, number, number, number]));
              }

              onChange({
                ...value,
                inputs: newInputs,
                num_days: num ?? 0,
              });
            }}
          />
        </Stack>
        <div style={{ blockSize: 300, overflowY: "auto", marginBlockStart: 5, width: 750 }}>
          <ReactGrid columns={inputsCols} rows={inputsRows} enableRangeSelection stickyTopRows={1} onCellsChanged={handleChanges} />
        </div>
        <Text variant="tiny">Tip: You can copy and paste cells from a spreadsheet here.</Text>
      </Stack>
    </Stack>
  );
}

export default ModuleKoldunMcsimGasOilInputGridTab;
