import { colorPalette } from "@/features/themes/mui-theme/mui-theme";
import styled from "@emotion/styled";

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: 700px;
  height: 50vh;

  a {
    text-decoration: none;
    color: black;
  }

  a:hover {
    text-decoration: underline;
    color: ${colorPalette.primary.main};
  }
  .list-container {
    height: 350px;
    overflow: auto;
  }

  .file-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
  }

  .error-mes {
    color: #c5283d;
  }
  .success-mes {
    color: #87c180;
  }
  .files-list-container {
    margin-top: 20px;
  }

  .button-container {
    align-items: center;
    display: flex;
    float: right;
    margin-top: 15;
  }
`;

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
