import React, { useCallback, useEffect, useState } from "react";
import { Stack, Text } from "@fluentui/react";
import { shallow } from "zustand/shallow";

import ModulePage from "../../ModulePage";
import { DataSet, isDataSet } from "../../../model";
import { ModuleGildroHydrateCase, ModuleGildroHydrateOptions, ModuleGildroHydrateOutput } from "./model";
import ModuleGildroHydrateInputGridTab from "./ModuleGildroHydrateInputGridTab";
import ModuleGildroHydrateDataGrid from "./ModuleGildroHydrateDataGrid";
import ModuleGildroHydrateOutputView from "./ModuleGildroHydrateOutputView";
import { useAppStore } from "@/features/app";

import "./ModuleGildroHydrate.css";
import useThemeStyling from "@/utils/useThemeStyling";

const ModuleGildroHydrate = () => {
  const { selectedDataSets, isLoading, hideSidebar, postRequest } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      hideSidebar: state.hideSidebar,
      postRequest: state.postRequest,
    }),
    shallow
  );

  const [dataSet, setDataSet] = useState<DataSet>();
  const [options, setOptions] = useState<ModuleGildroHydrateOptions>();
  const [output, setOutput] = useState<ModuleGildroHydrateOutput>();

  const onCasesChange = useCallback(
    (cases: ModuleGildroHydrateCase[]) => setOptions((prevOptions) => (prevOptions ? { ...prevOptions, cases } : undefined)),
    []
  );

  useEffect(() => {
    setDataSet(isDataSet(selectedDataSets) ? selectedDataSets : undefined);
  }, [selectedDataSets]);

  useEffect(() => {
    setOutput(undefined);
    if (!dataSet || !options || options.cases?.length < 2) return;

    (async () => {
      try {
        const response = await postRequest(
          "/modules/gildro/hydrate/calculate",
          {
            data_set_id: dataSet.id,
          },
          options
        );
        setOutput(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, options, postRequest]);

  const { palette, elevatedCard } = useThemeStyling();

  return (
    <ModulePage
      title="GILDRO: Hydrate"
      tabs={
        dataSet && [
          {
            headerText: "Input Grid",
            itemIcon: "InputField",
            disabled: isLoading,
            disableHideSidebar: true,
            content: <ModuleGildroHydrateInputGridTab options={options} onChange={setOptions} />,
          },
          {
            headerText: "Output",
            itemIcon: "LineChart",
            disabled: isLoading,
            canSaveAsImg: true,
            content: (
              <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
                {!hideSidebar && (
                  <Stack tokens={{ childrenGap: 20 }}>
                    <Stack style={elevatedCard} tokens={{ childrenGap: 5 }} grow={1}>
                      <Text styles={{ root: { color: palette.primary.main } }}>Case data</Text>

                      <Stack.Item grow={1} className="ModuleGildroHydrateGrid">
                        <div className="wrap">
                          <ModuleGildroHydrateDataGrid onCasesChange={onCasesChange} output={output} />
                        </div>
                      </Stack.Item>
                      <Text variant="tiny">Tip: You can copy and paste cells from a spreadsheet here.</Text>
                    </Stack>
                  </Stack>
                )}

                <Stack style={elevatedCard} grow={1}>
                  <ModuleGildroHydrateOutputView isLoading={isLoading} dataSet={dataSet} output={output} />
                </Stack>
              </Stack>
            ),
          },
        ]
      }
    />
  );
};

export default ModuleGildroHydrate;
