import { z } from "zod";

const forecastData = z.object({
  dates: z.string().date().array(),
  gas_rate: z.number().array(),
  water_rate: z.number().array(),
  cumulative_gas_production: z.number().array(),
  cumulative_water_production: z.number().array(),
});

export type ForecastDataObject = z.infer<typeof forecastData>;

export const koldunCsgForecastDataScheme = z.object({
  simulated_data: forecastData.array(),
  low_results: forecastData,
  mid_results: forecastData,
  high_results: forecastData,
  mean_results: forecastData,
});

export type KoldunCsgForecastData = z.infer<typeof koldunCsgForecastDataScheme>;
