import { calculateRulonRollup } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { RollupIntervalPayloadScheme, RulonRollupForecast } from "../State";
import { FluidType } from "@/models/Generic";

export const postCalculateRulonRollup = (projectId: string, type: FluidType, body: RollupIntervalPayloadScheme) => {
  return fetchApi<RulonRollupForecast>({
    path: calculateRulonRollup(projectId, type),
    body,
    type: "post",
  });
};
