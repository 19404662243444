import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IDropdownOption,
  PrimaryButton,
  SelectionMode,
  Slider,
  SpinButton,
  Spinner,
  Stack,
  Text,
  SpinnerSize,
  IStyleFunctionOrObject,
  ISpinnerStyleProps,
  ISpinnerStyles,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { useDebounce } from "use-debounce";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";
import ModulePage from "../../ModulePage";
import {
  DataResponse,
  DataSet,
  Field,
  FormationCompressibility,
  GasViscosityMethod,
  isDataSet,
  OilDensityMethod,
  RockType,
  SolGasMethod,
  WaterCompressibility,
  WaterViscosityMethod,
  ZMethod,
} from "../../../model";
import {
  ModuleGazFmbInitOptions,
  ModuleGazFmbInitOutput,
  ModuleGazFmbParamOptions,
  ModuleGazFmbPvt,
  ModuleGazFmbPvtOptions,
  ModuleGazFmbPvtWellboreOptions,
  ModuleGazFmbUserOptions,
  ModuleGazFmbUserOutput,
  NewModuleGazFmbParamOutput,
} from "./model";
import ModuleDataView, { ModuleDataViewField } from "../../ModuleDataView";
import ModuleGazFmbInputGrid from "./ModuleGazFmbInputGrid";
import ModuleGazFmbInputGridPlots from "./ModuleGazFmbInputGridPlots";
import { formatNewNumber, formatNumber, transpose } from "../../../util";
import ModuleGazFmbOutputView from "./ModuleGazFmbOutputView";
import ModuleGazFmbWellboreInputGrid from "./ModuleGazFmbWellboreInputGrid";
import ModuleGazPzDataGrid from "../pz/ModuleGazPzDataGrid";
import { ModuleGazPzInit } from "../pz/model";
import { useSettingState } from "../../../SettingsState";

import "./ModuleGazFmb.css";
import _ from "lodash";
import useThemeStyling from "@/utils/useThemeStyling";

const pressureTypeOptions = [
  { key: Field.CASING, text: "Casing" },
  { key: Field.TUBING, text: "Tubing" },
] as IDropdownOption[];

const dataTableColumns = [
  { key: "parameter", name: "Parameter", fieldName: "parameter", isRowHeader: true, minWidth: 180 },
  { key: "value", name: "Value", fieldName: "value", minWidth: 60 },
] as IColumn[];

const modalProps = {
  isBlocking: false,
};
const dialogContentProps = {
  type: DialogType.largeHeader,
  title: "P/z Manual Points",
  subText: "Please enter manual points for P/z below. Click close when you are done creating points.",
};

const spinerStyle: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles> = {
  root: {
    position: "absolute",
    top: "50%",
    left: " 50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    margin: "0 auto",
  },
  circle: {
    borderWidth: 4,
  },
  label: {
    fontSize: 20,
  },
};

type FmbUserLastPayloadUser = {
  dataSetId: string;
} & ModuleGazFmbUserOptions;

type FmbUserLastPayloadParam = {
  dataSetId: string;
} & ModuleGazFmbParamOptions;

function ModuleGazFmb() {
  const { selectedDataSets, isLoading, hideSidebar, postRequest, pollRequest } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      hideSidebar: state.hideSidebar,
      postRequest: state.postRequest,
      pollRequest: state.pollRequest,
    }),
    shallow
  );

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [dataSet, setDataSet] = useState<DataSet>();
  const [cleaned, setCleaned] = useState<DataResponse>();
  const [inputChanged, setInputChanged] = useState(false);

  useEffect(() => {
    setDataSet(isDataSet(selectedDataSets) ? selectedDataSets : undefined);
    setCleaned(undefined);
    setPvt(undefined);
  }, [selectedDataSets]);
  const [pressureType, setPressureType] = useState(Field.TUBING);
  const [pvtOptions, setPvtOptions] = useSettingState<ModuleGazFmbPvtOptions>("fmb_pvt_options", false, {
    default: {
      initial_pressure_psia: 4400,
      formation_temp_of: 240,
      net_pay_ft: 20,
      wellbore_rad_ft: 0.3,
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.SANDSTONE,
      water_compressibility_psi1: WaterCompressibility.BEGGS_BRILL,
      z_method: ZMethod.DPR,

      gas_gravity: 1,
      n2_perc: 0,
      co2_perc: 0,
      h2s_perc: 0,
      water_sat_perc: 30,
      porosity_perc: 13,
      gas_viscosity_method: GasViscosityMethod.GONZALES_EAKIN,
    },
  });
  const [pvt, setPvt] = useState<ModuleGazFmbPvt>();
  const [taskId, setTaskId] = useState<string>();
  const [wellboreOptions, setWellboreOptions] = useSettingState<ModuleGazFmbPvtWellboreOptions | undefined>("fmb_wellbore_options", false, {
    default: {
      include_oil: false,
      oil_api_psia: 30,
      oil_pb_psia: 1000,
      oil_density_method: OilDensityMethod.EOS,
      sol_gas_method: SolGasMethod.VASQUEZ_BEGGS,
      include_water: false,
      water_salinity_ppm: 0,
      water_viscosity_method: WaterViscosityMethod.MCCAIN,
      tubing_diameter_in: 2.4,
      tubing_roughness_in: 0.0006,
      tubing_depth_ft: 8900,
      wellhead_temp_of: 90,
      well_angle_deg: 90,
      num_inc: 10,
    },
  });
  const [wellboreEnabled, setWellboreEnabled] = useSettingState("fmb_wellbore_enabled", false, { default: false });
  const [skin, setSkin] = useSettingState("fmb_skin", false, { default: 0.5 });
  const [fmb, setFmb] = useState<ModuleGazFmbInitOutput>();
  const [line, setLine] = useSettingState<number[][]>("fmb_line", false);
  const [debounceLine] = useDebounce(line, 500);
  const [isUserLine, setIsUserLine] = useSettingState("fmb_is_user_line", false, { default: false });
  const [param, setParam] = useState<NewModuleGazFmbParamOutput>();

  const [pz, setPz] = useState<ModuleGazPzInit>();
  const [showManualPointsDialog, setShowManualPointsDialog] = useState(false);
  const [manualPoints, setManualPoints] = useSettingState<number[][]>("fmb_manual_points", false, {
    default: Array.from(Array(20).keys()).map(() => [0, 0]),
  });
  const [lastValidDataIndex, setLastValidDataIndex] = useState(0);
  const [lastUserChangesApiPayload, setLastUserChangesApiPayload] = useState<{
    user?: FmbUserLastPayloadUser;
    param?: FmbUserLastPayloadParam;
  }>({});

  const pzManualPoints = useMemo(() => (pz && manualPoints ? manualPoints.map((p, i) => [p[0], pz.pzs[i]]) : []), [manualPoints, pz]);

  const handleInputNotChanged = useCallback(() => {
    setInputChanged(true);
  }, []);

  useEffect(() => {
    if (!pvt && currentTab === 2) {
      setCurrentTab(1);
    }
  }, [currentTab, pvt]);

  const onPvtCalculate = useCallback(() => {
    if (!dataSet || !pvtOptions) return;

    setPvt(undefined);
    (async () => {
      try {
        const response = await postRequest(
          "/modules/gaz/fmb/calculate_pvt",
          {
            data_set_id: dataSet.id,
          },
          {
            pvt_options: pvtOptions,
            wellbore_options: wellboreEnabled ? { ...wellboreOptions, gas_viscosity_method: pvtOptions.gas_viscosity_method } : undefined,
            pressure_type: pressureType,
          }
        );
        setPvt(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, pvtOptions, postRequest, wellboreEnabled, wellboreOptions, pressureType]);

  // only call this on initialize, change data set + when user reset autofit
  const initializeFmb = useCallback(async () => {
    if (!dataSet || !pvtOptions || !pvt || !cleaned || currentTab !== 2) return;
    setFmb(undefined);

    setIsUserLine(false);

    try {
      const response = await pollRequest(
        "/modules/gaz/fmb/calculate_fmb_init",
        {
          data_set_id: dataSet.id,
        },
        {
          pvt_options: pvtOptions,
          z_table: pvt.z_table,
          pressure_type: pressureType,
          pseudo_press_table: pvt.pseudo_press_table,
          wellbore: pvt.wellbore,
        } as ModuleGazFmbInitOptions,
        true
      );
      setTaskId(response.task_id);
      setFmb(response.task_result);
      const midLineData = transpose(response.task_result.uncertainty[1]);
      setLine([midLineData[0], midLineData[midLineData.length - 1]]);
    } catch (error) {
      console.error(error);
    }
  }, [cleaned, currentTab, dataSet, pollRequest, pressureType, pvt, pvtOptions, setIsUserLine, setLine]);

  useEffect(() => {
    initializeFmb();
  }, [initializeFmb]);

  useEffect(() => {
    if (!isUserLine) return;
    if (!dataSet || !pvtOptions || !pvt || !cleaned || !fmb || !debounceLine) return;

    (async () => {
      try {
        const userPayload = {
          pvt_options: pvtOptions,
          z_table: pvt.z_table,
          pressure_type: pressureType,
          fmb_y_int: debounceLine[0][1],
          ogip: debounceLine[1][0],
          wellbore: pvt.wellbore,
          pseudo_press_table: pvt.pseudo_press_table,
        } as ModuleGazFmbUserOptions;
        if (
          lastUserChangesApiPayload.user &&
          _.isEqual(lastUserChangesApiPayload.user, {
            ...userPayload,
            dataSetId: dataSet.id,
          })
        )
          return;

        const response = (await postRequest(
          "/modules/gaz/fmb/calculate_fmb_user",
          {
            data_set_id: dataSet.id,
          },
          userPayload
        )) as ModuleGazFmbUserOutput;

        setLastUserChangesApiPayload((prev) => ({
          ...prev,
          user: {
            ...userPayload,
            dataSetId: dataSet.id,
          },
        }));

        setFmb((prevFmb) => {
          if (!prevFmb) return prevFmb;
          return {
            ...prevFmb,
            diagnostic: response.diagnostic,
            diagnostic_fpz: response.diagnostic_fpz,
          };
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [isUserLine, debounceLine, postRequest, dataSet, pvtOptions, pvt, cleaned, fmb, pressureType, lastUserChangesApiPayload?.user]);

  useEffect(() => {
    if (!dataSet || !pvtOptions || !pvt || !cleaned || !fmb || !debounceLine || !taskId) return;

    (async () => {
      try {
        const paramPayload = {
          pvt_options: pvtOptions,
          z_table: pvt.z_table,
          pressure_type: pressureType,
          fmb_y_int: debounceLine[0][1],
          cum_t_mmscf: fmb.diagnostic["Q"][fmb.diagnostic["Q"].length - 1],
          ogip: debounceLine[1][0],
          skin,
        } as ModuleGazFmbParamOptions;

        if (
          lastUserChangesApiPayload.param &&
          _.isEqual(lastUserChangesApiPayload.param, {
            ...paramPayload,
            dataSetId: dataSet.id,
          })
        )
          return;
        setParam(undefined);

        const response = (await postRequest(
          "/modules/gaz/fmb/calculate_fmb_params",
          {
            data_set_id: dataSet.id,
            task_id: isUserLine ? "" : taskId,
          },
          paramPayload
        )) as NewModuleGazFmbParamOutput;
        setLastUserChangesApiPayload((prev) => ({
          ...prev,
          param: {
            ...paramPayload,
            dataSetId: dataSet.id,
          },
        }));
        setParam(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [pvtOptions, pvt, pressureType, cleaned, debounceLine, skin, postRequest, dataSet, fmb, taskId, isUserLine, lastUserChangesApiPayload?.param]);

  const onLineChange = useCallback(
    (line: number[][] | [string, number][]) => {
      if (!isUserLine) setIsUserLine(true);
      setLine(line as number[][]);
    },
    [setIsUserLine, isUserLine, setLine]
  );

  // Set first data points row from inputs
  useEffect(() => {
    const newPoints = [...manualPoints];
    newPoints[0] = [0, pvtOptions ? pvtOptions.initial_pressure_psia : 0];

    setManualPoints(newPoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvtOptions, setManualPoints]);

  // Calculate last valid data index in data points list
  useEffect(() => {
    for (let i = 0; i < manualPoints.length; i++) {
      if (i > 0 && (!manualPoints[i][0] || manualPoints[i][0] <= 0 || !manualPoints[i][1])) {
        setLastValidDataIndex(i - 1);
        break;
      }
    }
  }, [manualPoints]);

  useEffect(() => {
    if (!dataSet || !pvtOptions || !pvt || lastValidDataIndex < 1) return;

    setPz(undefined);
    (async () => {
      try {
        const response = await postRequest(
          "/modules/gaz/pz/calculate_pz_init",
          {
            data_set_id: dataSet.id,
          },
          {
            pvt_options: {
              ...pvtOptions,
              // TODO: Fix abnormally pressured reservoirs
            },
            pvt: pvt,
            cum_prods: manualPoints?.filter((_, i) => i <= lastValidDataIndex).map((v) => v[0]),
            pressures: manualPoints?.filter((_, i) => i <= lastValidDataIndex).map((v) => v[1]),
          }
        );
        setPz(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, pvtOptions, pvt, manualPoints, lastValidDataIndex, postRequest]);

  const { palette, elevatedCard } = useThemeStyling();

  const moduleFields = useMemo<{ [k in Field]?: ModuleDataViewField }>(
    () => ({
      [Field.GAS_RATE]: {
        name: "Gas rate",
        yAxis: "Rate (MMscf/day)",
        color: palette.customColor.red,
      },
      [Field.TUBING]: {
        name: "Tubing pressure",
        yAxis: "Pressure (psia)",
        color: palette.customColor.black,
      },
      [Field.CASING]: {
        name: "Casing pressure",
        yAxis: "Pressure (psia)",
        color: palette.customColor.black,
      },
    }),
    [palette]
  );

  return (
    <ModulePage
      title={"GAZ: Flowing Material Balance"}
      tabIndex={currentTab}
      onTabChange={setCurrentTab}
      tabs={
        dataSet && [
          {
            headerText: "Data View",
            itemIcon: "ScatterChart",
            disabled: isLoading,
            content: (
              <ModuleDataView
                moduleFields={moduleFields}
                showAtmosphericCorrection={true}
                showCombinedFirst
                onDataCleaned={setCleaned}
                extraOptionsTitle="FMB"
              >
                <Dropdown
                  label="Pressure source"
                  options={pressureTypeOptions}
                  selectedKey={pressureType}
                  onChange={(_, v) => (v ? setPressureType(v.key as Field) : undefined)}
                />
              </ModuleDataView>
            ),
            canSaveAsImg: true,
          },
          {
            headerText: "Input Grid",
            itemIcon: "InputField",
            disabled: isLoading || !cleaned,
            disableHideSidebar: true,
            content: (
              <Stack horizontal tokens={{ padding: 20, childrenGap: 20 }} verticalAlign="start">
                <Stack grow={1} tokens={{ childrenGap: 20 }}>
                  <Stack.Item style={elevatedCard}>
                    <Text styles={{ root: { color: palette.primary.main } }}>Inputs</Text>
                    {isLoading && (
                      <Spinner label="loading..." ariaLive="assertive" labelPosition="right" size={SpinnerSize.large} styles={spinerStyle} />
                    )}
                    <ModuleGazFmbInputGrid value={pvtOptions} onChange={setPvtOptions} handleInputNotChanged={handleInputNotChanged} />
                  </Stack.Item>

                  <Stack.Item style={elevatedCard}>
                    <ModuleGazFmbWellboreInputGrid
                      value={wellboreOptions}
                      onChange={setWellboreOptions}
                      enabled={wellboreEnabled ?? false}
                      setEnabled={setWellboreEnabled}
                      handleInputNotChanged={handleInputNotChanged}
                    />
                  </Stack.Item>

                  <Stack.Item style={elevatedCard}>
                    <ModuleGazFmbInputGridPlots isLoading={isLoading} dataSet={dataSet} cleaned={cleaned} pvt={pvt} />
                  </Stack.Item>
                </Stack>

                <Stack style={{ ...elevatedCard, position: "sticky", top: 20 }} tokens={{ childrenGap: 5 }}>
                  <DetailsList
                    compact
                    selectionMode={SelectionMode.none}
                    columns={dataTableColumns}
                    items={[
                      {
                        parameter: "Initial Pressure (psia)",
                        value: formatNumber(pvtOptions?.initial_pressure_psia),
                      },
                      { parameter: "Gas Z-factor (Dim)", value: formatNumber(pvt?.gas_z_factor) },
                      { parameter: "Viscosity (cp)", value: formatNumber(pvt?.viscosity) },
                      { parameter: "Gas Bg (scf/ft³)", value: formatNumber(pvt?.gas_bg) },
                      { parameter: "Gas Compressibility (psi⁻¹)", value: formatNumber(pvt?.gas_comp) },
                      { parameter: "Formation Compressibility (psi⁻¹)", value: formatNumber(pvt?.form_comp) },
                      { parameter: "Water Compressibility (psi⁻¹)", value: formatNumber(pvt?.water_comp) },
                    ]}
                  />

                  <PrimaryButton
                    disabled={isLoading}
                    onClick={() => {
                      onPvtCalculate();
                      setInputChanged(false);
                    }}
                  >
                    Calculate PVT
                  </PrimaryButton>
                </Stack>
              </Stack>
            ),
          },
          {
            headerText: "FMB Output",
            itemIcon: "LineChart",
            canSaveAsImg: true,
            disabled: isLoading || !cleaned || !pvt || inputChanged,
            content: (
              <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
                {!hideSidebar && (
                  <Stack tokens={{ childrenGap: 20 }}>
                    <Stack style={elevatedCard} tokens={{ childrenGap: 5 }}>
                      <Text styles={{ root: { color: palette.primary.main } }}>FMB Parameters</Text>

                      <Slider
                        label="Skin (Dim)"
                        showValue={false}
                        min={-5}
                        max={25}
                        step={0.5}
                        disabled={isLoading}
                        value={skin}
                        onChange={(v) => setSkin(v)}
                      />
                      <SpinButton
                        min={-5}
                        max={25}
                        step={0.5}
                        disabled={isLoading}
                        value={skin?.toString()}
                        onChange={(_, v) => v !== undefined && setSkin(parseFloat(v))}
                      />
                    </Stack>

                    <Stack grow={1} style={{ ...elevatedCard }}>
                      <Text styles={{ root: { color: palette.primary.main } }}>FMB Output</Text>

                      <Pivot defaultSelectedKey="Mid" styles={{ root: { display: "flex", justifyContent: "center" } }}>
                        <PivotItem headerText="Low" itemKey="Low">
                          <DetailsList
                            compact
                            selectionMode={SelectionMode.none}
                            columns={dataTableColumns}
                            items={[
                              { parameter: "OGIP (Bcf)", value: formatNewNumber(param?.results[0].ogip_bcf) },
                              {
                                parameter: "Cumulative Gas (Bcf)",
                                value: formatNewNumber(param?.results[0].cum_bcf),
                              },
                              {
                                parameter: "Remain. Recoverable (Bcf)",
                                value: formatNewNumber(param?.results[0].remain_recoverable_bcf),
                              },
                              {
                                parameter: "Drainage Radius (ft)",
                                value: formatNewNumber(param?.results[0].drainage_radius_ft),
                              },
                              {
                                parameter: "Productivity Index (Dim)",
                                value: formatNewNumber(param?.results[0].pi_dim),
                              },
                              {
                                parameter: "Permeability (md)",
                                value: formatNewNumber(param?.results[0].permeability_md),
                              },
                              {
                                parameter: "Stabilization Time (days)",
                                value: formatNewNumber(param?.results[0].stabilization_time_days),
                              },
                            ]}
                          />
                        </PivotItem>
                        <PivotItem headerText="Mid" itemKey="Mid">
                          <DetailsList
                            compact
                            selectionMode={SelectionMode.none}
                            columns={dataTableColumns}
                            items={[
                              { parameter: "OGIP (Bcf)", value: formatNewNumber(param?.results[1].ogip_bcf) },
                              {
                                parameter: "Cumulative Gas (Bcf)",
                                value: formatNewNumber(param?.results[1].cum_bcf),
                              },
                              {
                                parameter: "Remain. Recoverable (Bcf)",
                                value: formatNewNumber(param?.results[1].remain_recoverable_bcf),
                              },
                              {
                                parameter: "Drainage Radius (ft)",
                                value: formatNewNumber(param?.results[1].drainage_radius_ft),
                              },
                              {
                                parameter: "Productivity Index (Dim)",
                                value: formatNewNumber(param?.results[1].pi_dim),
                              },
                              {
                                parameter: "Permeability (md)",
                                value: formatNewNumber(param?.results[1].permeability_md),
                              },
                              {
                                parameter: "Stabilization Time (days)",
                                value: formatNewNumber(param?.results[1].stabilization_time_days),
                              },
                            ]}
                          />
                        </PivotItem>
                        <PivotItem headerText="High" itemKey="High">
                          <DetailsList
                            compact
                            selectionMode={SelectionMode.none}
                            columns={dataTableColumns}
                            items={[
                              { parameter: "OGIP (Bcf)", value: formatNewNumber(param?.results[2].ogip_bcf) },
                              {
                                parameter: "Cumulative Gas (Bcf)",
                                value: formatNewNumber(param?.results[2].cum_bcf),
                              },
                              {
                                parameter: "Remain. Recoverable (Bcf)",
                                value: formatNewNumber(param?.results[2].remain_recoverable_bcf),
                              },
                              {
                                parameter: "Drainage Radius (ft)",
                                value: formatNewNumber(param?.results[2].drainage_radius_ft),
                              },
                              {
                                parameter: "Productivity Index (Dim)",
                                value: formatNewNumber(param?.results[2].pi_dim),
                              },
                              {
                                parameter: "Permeability (md)",
                                value: formatNewNumber(param?.results[2].permeability_md),
                              },
                              {
                                parameter: "Stabilization Time (days)",
                                value: formatNewNumber(param?.results[2].stabilization_time_days),
                              },
                            ]}
                          />
                        </PivotItem>
                      </Pivot>

                      <Stack grow={1} />

                      <PrimaryButton
                        disabled={!isUserLine}
                        onClick={() => {
                          setIsUserLine(false);
                          initializeFmb();
                        }}
                      >
                        Reset autofit
                      </PrimaryButton>
                    </Stack>

                    <Stack style={elevatedCard} tokens={{ childrenGap: 5 }}>
                      <Text styles={{ root: { color: palette.primary.main } }}>P/z Manual Points</Text>

                      <DefaultButton onClick={() => setShowManualPointsDialog(true)}>Add points</DefaultButton>

                      <Dialog
                        hidden={!showManualPointsDialog}
                        onDismiss={() => setShowManualPointsDialog(false)}
                        dialogContentProps={dialogContentProps}
                        modalProps={modalProps}
                        minWidth={450}
                      >
                        <div className="ModuleGazFmbDialogGrid">
                          <ModuleGazPzDataGrid
                            dataPoints={manualPoints ?? []}
                            onDataPointsChange={setManualPoints}
                            lastValidIndex={lastValidDataIndex}
                            pz={pz}
                          />
                        </div>
                        <Text variant="tiny">Tip: You can copy and paste cells from a spreadsheet here.</Text>

                        <DialogFooter>
                          <DefaultButton onClick={() => setShowManualPointsDialog(false)} text="Close" />
                        </DialogFooter>
                      </Dialog>
                    </Stack>
                  </Stack>
                )}

                <Stack style={elevatedCard} grow={1}>
                  <ModuleGazFmbOutputView
                    isLoading={isLoading}
                    dataSet={dataSet}
                    param={param}
                    fmb={fmb}
                    line={line}
                    onLineChange={onLineChange}
                    manualPoints={pzManualPoints}
                    isUserLine={isUserLine ?? false}
                  />
                </Stack>
              </Stack>
            ),
          },
        ]
      }
    />
  );
}

export default ModuleGazFmb;
