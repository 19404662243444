import { useMemo } from "react";
import styled from "@emotion/styled";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";

import useThemeStyling from "@/utils/useThemeStyling";
import InputField from "@/components/fields/InputField";
import Tabs from "@/components/Tabs";
import dictionary from "@/constants/dictionary";

import useDataview from "./hooks/useDataview";
import { capitalizeFirstLetter } from "@/utils/general";
import CustomTable from "@/components/CustomTable";
import FossilyticsChart from "@/components/FossilyticsChart";
import LoadingIndicator from "@/components/LoadingIndicator";

const Container = styled.div`
  padding: 20px;
  overflow: scroll;

  display: grid;
  grid-template-columns: 360px 1fr;
  grid-gap: 20px;
  height: 95%;
`;

const DataviewPage = () => {
  const { elevatedCard } = useThemeStyling();

  const { selectedDataSets, currentModule, project, setApiError, apiError } = useAppStore(
    (state) => ({
      currentModule: state.currentModule,
      selectedDataSets: state.selectedDataSets,
      project: state.project,
      setApiError: state.setApiError,
      apiError: state.apiError,
    }),
    shallow
  );

  const { loadingState, setStateOption, stateOption, dataOptionColumn, dataOptionRows, onCellsChanged, yAxes, xAxes, series, dataColumns, dataRows } =
    useDataview({
      setApiError,
      apiError,
      currentModule,
      project,
      selectedDataSets,
    });

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.dataView.dataChart}</span>,
        key: "Data chart",
        content: (
          <div className="chartWrapper">
            {series.length === 0 ? (
              <LoadingIndicator />
            ) : (
              <FossilyticsChart id={"dataview_chart"} key={"dataview_chart"} isLoading={loadingState} xAxes={xAxes} yAxes={yAxes} series={series} />
            )}
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataView.dataTable}</span>,
        key: "Data table",
        content: (
          <div>
            <CustomTable
              style={{
                display: "flex",
                overflow: "auto",
                height: "100%",
                width: 900,
              }}
              columns={dataColumns}
              rows={dataRows}
              enableRangeSelection
              enableColumnSelection
              isLoading={loadingState}
              stickyTopRows={1}
            />
          </div>
        ),
      },
    ];
  }, [loadingState, dataColumns, dataRows, series, xAxes, yAxes]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={elevatedCard}>
          <h3 className="primaryColor">{dictionary.dataView.dataOptions}</h3>
          {stateOption &&
            Object.keys(stateOption?.data_option).map((option: string) => {
              return (
                <InputField
                  key={option}
                  type="float"
                  label={capitalizeFirstLetter(option.replaceAll("_", " "))}
                  debounceDelay={1000}
                  disabled={loadingState}
                  onChange={(v) => {
                    setStateOption((prev) => {
                      if (!prev || !stateOption) return prev;
                      return {
                        ...prev,
                        data_option: { ...stateOption.data_option, [option]: Number(v) },
                      };
                    });
                  }}
                  value={stateOption.data_option[option]}
                />
              );
            })}
        </div>
        <div style={{ ...elevatedCard, marginTop: 20, height: "100%" }}>
          <h3 className="primaryColor">{dictionary.dataView.chartOptions}</h3>
          <CustomTable
            style={{
              display: "flex",
              width: "100%",
              overflow: "auto",
              height: "100%",
            }}
            columns={dataOptionColumn}
            rows={dataOptionRows}
            enableRangeSelection
            enableColumnSelection
            stickyTopRows={1}
            onCellsChanged={onCellsChanged}
            isLoading={false}
          />
        </div>
      </div>

      <div style={elevatedCard}>
        <Tabs tabList={tabList} />
      </div>
    </Container>
  );
};

export default DataviewPage;
