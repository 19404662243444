import { TahkCsgInputLayerCharts, TahkCsgInputState, TahkCsgStateResponse } from "@/models/tahk";
import { useCallback } from "react";
import { FlowPressureTypeEnum } from "@/models/InputGeneric";

export type TahkCsgWellboreInputProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
  layers: TahkCsgInputLayerCharts[];
};

const useTahkCsgWellboreInput = ({ setTahkCsgState }: TahkCsgWellboreInputProps) => {
  const onChangeWellboreInput = useCallback(
    (value: any, key: string) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;
        const wellBoreInput = {
          ...prev.inputs.wellbore_inputs,
          [key]: value,
        };
        if (key === "selected_flow_pressure_type") {
          const isTubingHead = value === FlowPressureTypeEnum.TubingHeadPressure;
          if (isTubingHead) {
            wellBoreInput.parameters = {
              minimum_pump_head: 1,
              pump_depth: 1,
              pump_draw_down_rate: 1,
              initial_liquid_level: 1,
            };
          } else {
            wellBoreInput.parameters = {
              minimum_pump_head: null,
              pump_depth: null,
              pump_draw_down_rate: null,
              initial_liquid_level: null,
            };
          }
        }

        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            wellbore_inputs: wellBoreInput,
          },
        };
      });
    },
    [setTahkCsgState]
  );

  const onChangeWellboreInputParameter = useCallback(
    (value: any, key: string) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;

        const safeParam = prev.inputs.wellbore_inputs?.parameters ?? {};
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            wellbore_inputs: {
              ...prev.inputs.wellbore_inputs,
              parameters: {
                ...safeParam,
                [key]: value,
              },
            },
          },
        };
      });
    },
    [setTahkCsgState]
  );

  return {
    onChangeWellboreInput,
    onChangeWellboreInputParameter,
  };
};

export default useTahkCsgWellboreInput;
