import React from "react";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";
import useMachineLearning from "./hooks/useMachineLearning";
import FossilyticsChart from "@/components/FossilyticsChart";

const MachineLearningModule = () => {
  const { selectedDataSets, isLoading, project, setApiError, apiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      project: state.project,
      setApiError: state.setApiError,
      apiError: state.apiError,
    }),
    shallow
  );

  const { loading, xAxes, yAxes, series } = useMachineLearning({
    selectedDataSets,
    setApiError,
    apiError,
    project,
  });

  return (
    <div
      style={{
        height: 700,
        padding: 20,
      }}
    >
      <FossilyticsChart
        id={"machine-learning-chart"}
        key={"machine-learning-chart"}
        isLoading={loading || isLoading}
        xAxes={xAxes}
        yAxes={yAxes}
        series={series}
      />
    </div>
  );
};

export default MachineLearningModule;
