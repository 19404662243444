import React, { useState, ReactNode, CSSProperties } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import styled from "@emotion/styled";

const StyledTab = styled(Tab)`
  text-transform: none;
`;

const StyledTabContainer = styled(Tabs)`
  .Mui-selected {
    color: black !important;
    font-weight: 600;
  }
  .MuiTabs-indicator {
    background-color: rgb(242, 143, 29);
  }
  button {
    min-width: ${(props) => (props.centered ? "unset" : "90px")};
  }
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type TabItemProps = {
  label: ReactNode;
  content: ReactNode;
  key: string;
};

type CustomTabsProps = {
  tabList: TabItemProps[];
  centered?: boolean;
  onClickItem?: (tabIndex: number) => void;
  id?: string;
  customActiveTab?: number;
  style?: CSSProperties;
};

const CustomTabs = ({ tabList, centered = false, onClickItem, id, customActiveTab = 0, style }: CustomTabsProps) => {
  const [activeTab, setActiveTab] = useState(customActiveTab);
  if (tabList.length === 0) return <></>;
  return (
    <StyledContainer style={style}>
      <div style={{ position: "relative" }}>
        <StyledTabContainer
          centered={centered}
          value={activeTab}
          onChange={(_, indexTab) => {
            setActiveTab(indexTab);
            onClickItem?.(indexTab);
          }}
          aria-label={id}
          id={id}
        >
          {tabList.map((tab, indexTab) => {
            return <StyledTab className={tab.key} key={tab.key} label={tab.label} value={indexTab} />;
          })}
        </StyledTabContainer>
      </div>

      <div style={{ flex: 1 }}>{tabList[activeTab]?.content}</div>
    </StyledContainer>
  );
};

export default CustomTabs;
