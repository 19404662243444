import { exportRulonRollup } from "@/constants/apiUrl";

import { FluidType } from "@/models/Generic";
import { fetchApi } from "@/utils/apiFetcher";
import { RollupIntervalPayloadScheme } from "../State";

export const postExportRulonRollup = (projectId: string, type: FluidType, body: RollupIntervalPayloadScheme) => {
  return fetchApi<Blob>({
    path: exportRulonRollup(projectId, type),
    body,
    type: "post",
    responseType: "blob",
  });
};
