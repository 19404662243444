import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { useCallback, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { GenericChartResult } from "@/models/Generic";
import { getSharedLineSeriesOpts } from "../../index.utils";
import { AnalysedData, AnalysisResult, SpadDeclineState } from "@/models/spad/decline";

type UseCartesianProdDayProps = {
  unit: string;
  getScatterItemColor: (params: any) => string;
  forecastAnalysis?: AnalysisResult | null;
  analysedData?: AnalysedData | null;
  compareAnalysis?: SpadDeclineState;
  compareName?: string;
  xState: boolean;
};
const useCartesianProdDay = ({
  unit,
  getScatterItemColor,
  forecastAnalysis,
  analysedData,
  compareAnalysis,
  compareName,
  xState,
}: UseCartesianProdDayProps) => {
  const { palette } = useTheme();
  const chartKey = xState ? "dates" : "days";
  const getCumProdLineSeries = useCallback(
    (arp?: GenericChartResult) => {
      let newData: unknown[] = [];

      if (arp && analysedData) {
        newData = arp[chartKey].map((d, i) => [d, arp.cumulative_production[i]]);
      }
      return {
        ...getSharedLineSeriesOpts(),
        data: newData,
      };
    },
    [analysedData, chartKey]
  );

  const cumProdYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Cum. production (${unit})`, type: "value", color: palette.customColor.black, min: 0 }],
    [palette.customColor.black, unit]
  );

  const cumProdSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!analysedData?.cartesian_chart) return [];
    let cumProdData: unknown[] = analysedData?.cartesian_chart[chartKey].map((d, i) => [d, analysedData?.cartesian_chart.cumulative_production[i]]);

    return [
      {
        name: "Cum. production",
        type: "scatter",
        color: getScatterItemColor,
        data: cumProdData,
      },
      {
        ...getCumProdLineSeries(forecastAnalysis?.operational_result.low.chart_profiles),
        id: "lc",
        name: "OPS Low case",
        color: palette.customColor.purpleLight,
        defaultDisabled: true,
      },
      {
        ...getCumProdLineSeries(forecastAnalysis?.operational_result.mid.chart_profiles),
        id: "mc",
        name: "OPS Mid case",
        color: palette.customColor.purple,
        defaultDisabled: true,
      },
      {
        ...getCumProdLineSeries(forecastAnalysis?.operational_result.high.chart_profiles),
        id: "hc",
        name: "OPS High case",
        color: palette.customColor.purpleDark,
        defaultDisabled: true,
      },
      {
        ...getCumProdLineSeries(forecastAnalysis?.profile_result.low.chart_profiles),
        id: "lcl",
        name: "Profile Low case",
        color: palette.primary.main,
      },
      {
        ...getCumProdLineSeries(forecastAnalysis?.profile_result.mid.chart_profiles),
        id: "mcl",
        name: "Profile Mid case",
        color: palette.customColor.blue,
      },
      {
        ...getCumProdLineSeries(forecastAnalysis?.profile_result.high.chart_profiles),
        id: "hcl",
        name: "Profile High case",
        color: palette.customColor.yellow,
      },
      ...(compareName && compareAnalysis?.analysis_result
        ? [
            {
              ...getCumProdLineSeries(compareAnalysis.analysis_result?.operational_result.low.chart_profiles),
              name: `${compareName} OPS Low Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getCumProdLineSeries(compareAnalysis.analysis_result?.operational_result.mid.chart_profiles),
              name: `${compareName} OPS Mid Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getCumProdLineSeries(compareAnalysis.analysis_result?.operational_result.high.chart_profiles),
              name: `${compareName} OPS High Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getCumProdLineSeries(compareAnalysis.analysis_result?.profile_result.low.chart_profiles),
              name: `${compareName} Profile Low Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getCumProdLineSeries(compareAnalysis.analysis_result?.profile_result.mid.chart_profiles),
              name: `${compareName} Profile Mid Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getCumProdLineSeries(compareAnalysis.analysis_result?.profile_result.high.chart_profiles),
              name: `${compareName} Profile High Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
          ]
        : []),
    ];
  }, [
    analysedData?.cartesian_chart,
    chartKey,
    getScatterItemColor,
    getCumProdLineSeries,
    forecastAnalysis?.operational_result.low.chart_profiles,
    forecastAnalysis?.operational_result.mid.chart_profiles,
    forecastAnalysis?.operational_result.high.chart_profiles,
    forecastAnalysis?.profile_result.low.chart_profiles,
    forecastAnalysis?.profile_result.mid.chart_profiles,
    forecastAnalysis?.profile_result.high.chart_profiles,
    palette.customColor.purpleLight,
    palette.customColor.purple,
    palette.customColor.purpleDark,
    palette.customColor.blue,
    palette.customColor.yellow,
    palette.customColor.neutralDark,
    palette.customColor.blueLight,
    palette.primary.main,
    compareName,
    compareAnalysis?.analysis_result,
  ]);

  const QgxAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let name = "Days";
    let type = "value";
    let min = 0;

    if (xState) {
      name = "Date";
      type = "time";
      min = cumProdSeries[0]?.data[0][0];
    }

    return [{ name, type, color: palette.customColor.black, min }];
  }, [cumProdSeries, palette.customColor.black, xState]);

  return { cumProdSeries, cumProdYAxes, QgxAxes };
};
export default useCartesianProdDay;
