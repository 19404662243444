import { useCallback, useMemo, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { Menu, MenuItem } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import InputField from "@/components/fields/InputField";
import Tabs from "@/components/Tabs";

import dictionary from "@/constants/dictionary";

import { Container } from "./style";
import { SelectedForecastRollup, SpadDeclineState } from "@/models/spad/decline";
import ParameterCase from "./ParameterCase";
import DropdownField from "@/components/fields/DropdownField";
import { mapEnumToDropdown } from "@/utils/general";
import CompareDropdown, { DataSetDropdownProps } from "./CompareDropdown";
import { FluidType, Interval } from "@/models/Generic";
import { ValidationError } from "../../hooks/SpadDeclineContext";
import { ModuleId } from "@/model";

type SpadDeclineAnalysisParameterProps = {
  spadDeclineState?: SpadDeclineState;
  setSpadDeclineState: React.Dispatch<React.SetStateAction<SpadDeclineState | undefined>>;
  onClickCalculate: () => void;
  isLoading: boolean;
  onClickExport: (interval: Interval) => void;
  validationError: ValidationError;
  currentModule?: ModuleId;
  spadDeclineType: FluidType;
} & DataSetDropdownProps;

const exportOption = [Interval.daily, Interval.monthly, Interval.yearly];
const SpadDeclineAnalysisParameter = ({
  isLoading,
  spadDeclineState,
  setSpadDeclineState,
  onClickCalculate,
  onClickExport,
  setCompareDataSet,
  setCompareName,
  dataSets,
  projects,
  selectedDataSets,
  validationError,
  currentModule,
  spadDeclineType,
}: SpadDeclineAnalysisParameterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const onChangeParameter = useCallback(
    (key: string, value: any) => {
      setSpadDeclineState((prev) => {
        if (!prev?.analysis_option) return prev;
        const prevAnalysisOpt = prev?.analysis_option ?? {};

        return {
          ...prev,
          analysis_option: {
            ...prevAnalysisOpt,
            [key]: value,
          },
        };
      });
    },
    [setSpadDeclineState]
  );

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.spadDecline.opsForecast}</span>,
        key: "opsForecast",
        content: (
          <div>
            <ParameterCase
              disabled={spadDeclineState?.analysis_option?.auto_update_smart_fit ?? false}
              setSpadDeclineState={setSpadDeclineState}
              type="operational_forecast"
              forecast={spadDeclineState?.analysis_option?.operational_forecast}
              errors={validationError.operational_forecast}
              currentModule={currentModule}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.spadDecline.profileForecast}</span>,
        key: "profileForecast",
        content: (
          <div>
            <ParameterCase
              disabled={spadDeclineState?.analysis_option?.auto_update_smart_fit ?? false}
              setSpadDeclineState={setSpadDeclineState}
              type="profile_forecast"
              currentModule={currentModule}
              forecast={spadDeclineState?.analysis_option?.profile_forecast}
              errors={validationError.profile_forecast}
            />
          </div>
        ),
      },
    ];
  }, [
    setSpadDeclineState,
    spadDeclineState?.analysis_option?.auto_update_smart_fit,
    spadDeclineState?.analysis_option?.operational_forecast,
    spadDeclineState?.analysis_option?.profile_forecast,
    validationError,
    currentModule,
  ]);

  return (
    <Container>
      <h3 className="primaryColor">{dictionary.spadDecline.declineParameters}</h3>

      <FormControlLabel
        control={
          spadDeclineState?.analysis_option ? (
            <Checkbox
              onChange={(e) => {
                onChangeParameter("auto_update_smart_fit", e.target.checked);
              }}
              checked={spadDeclineState?.analysis_option.auto_update_smart_fit}
              size="small"
              data-testid="auto-update-smart-fit-checkbox"
            />
          ) : (
            <></>
          )
        }
        label={dictionary.spadDecline.autoUpdateSmartFit}
      />
      <FormControlLabel
        control={
          spadDeclineState?.analysis_option ? (
            <Checkbox
              onChange={(e) => {
                onChangeParameter("auto_update_cluster_start_day", e.target.checked);
              }}
              checked={spadDeclineState?.analysis_option.auto_update_cluster_start_day}
              size="small"
              data-testid="auto-update-cluster-start-day-checkbox"
            />
          ) : (
            <></>
          )
        }
        label={dictionary.spadDecline.autoUpdateClusterStartDay}
      />
      <InputField
        type="int"
        disabled={spadDeclineState?.analysis_option?.auto_update_cluster_start_day}
        value={spadDeclineState?.analysis_option?.analysis_start_day}
        label={dictionary.spadDecline.clusterStartDay}
        debounceDelay={10}
        onChange={(v) => {
          onChangeParameter("analysis_start_day", v);
        }}
        errors={validationError.general}
        keyField={`analysis_option.analysis_start_day`}
        dataTestId="input-cluster-start-day"
      />
      <Tabs centered tabList={tabList} />
      <InputField
        type="float"
        label={dictionary.spadDecline.economicCutoff}
        debounceDelay={10}
        suffix={dictionary.spadDecline[`economicCutoff${spadDeclineType}Notation`]}
        onChange={(v) => {
          onChangeParameter("economic_cutoff", v);
        }}
        value={spadDeclineState?.analysis_option?.economic_cutoff}
        errors={validationError.general}
        keyField={`analysis_option.economic_cutoff`}
        dataTestId="input-economic-cutoff"
      />

      <div>
        <Button
          style={{
            color: "white",
            width: "100%",
            fontWeight: "bold",
            marginBottom: 15,
            marginTop: 20,
          }}
          disabled={isLoading}
          variant="contained"
          onClick={() => {
            onClickCalculate();
          }}
        >
          {dictionary.spadDecline.applyUpdates}
        </Button>
        <Button
          style={{
            color: "white",
            width: "100%",
            fontWeight: "bold",
          }}
          variant="contained"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <div style={{ marginLeft: "auto", paddingLeft: 23 }}>{dictionary.spadDecline.export}</div>
          <KeyboardArrowDownIcon style={{ marginLeft: "auto" }} />
        </Button>
        <div>
          <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)} data-testid="export-selections">
            {exportOption.map((option: Interval) => {
              return (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    onClickExport(option);
                  }}
                  key={dictionary.spadDecline[option]}                
                >
                  <div>{dictionary.spadDecline[option]}</div>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>

      {spadDeclineState?.analysis_option?.selected_forecast_rollup && (
        <DropdownField
          label={dictionary.spadDecline.forecastForRollup}
          options={mapEnumToDropdown(SelectedForecastRollup)}
          selectedKey={spadDeclineState?.analysis_option?.selected_forecast_rollup}
          onChange={(val) => onChangeParameter("selected_forecast_rollup", val)}
          dataTestId="dropdown-forecast-rollup"
        />
      )}

      <CompareDropdown
        setCompareName={setCompareName}
        projects={projects}
        dataSets={dataSets}
        selectedDataSets={selectedDataSets}
        setCompareDataSet={setCompareDataSet}
      />
    </Container>
  );
};

export default SpadDeclineAnalysisParameter;
