import { Dropdown, Stack } from "@fluentui/react";

import { ModuleTahkForecastGasOilOptions } from "@/modules/tahk/forecast/gasoil/model";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { TankType, GasViscosityMethod, WaterViscosityMethod, ZMethod } from "@/model";
import { renderLabelWithHelp } from "@/features/help-link";

import { TankTypeOptions, formationCompressibilityOptions, gasViscMethodOptions, wtrViscMethodOptions, zMethodOptions } from "./model";
import { THEORETICAL_HELP_LINKS } from "@/constants/helpLinkUrl";

interface BaseInputsProps {
  value: ModuleTahkForecastGasOilOptions;
  onChange: (val: ModuleTahkForecastGasOilOptions) => void;
  module?: string;
}

const BaseInputs = ({ value, onChange, module }: BaseInputsProps) => {
  if (!value) return <></>;
  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <Dropdown
          label="Tank type"
          onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.pssModels, props)}
          options={TankTypeOptions}
          selectedKey={value.tank_type}
          onChange={(_, v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  tank_type: v.key as TankType,
                })
              : null
          }
        />
        {module !== "tahk_gas" && (
          <FossilyticsNumberField
            label="Area"
            suffix="acre"
            value={value.area_acres}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    area_acres: v,
                  })
                : null
            }
          />
        )}
        <FossilyticsNumberField
          label="Wellbore radius"
          suffix="ft"
          value={value.wellbore_radius_ft}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  wellbore_radius_ft: v,
                })
              : null
          }
        />
      </Stack>

      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <FossilyticsNumberField
          label="Gas gravity"
          suffix="dim"
          value={value.gas_gravity}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  gas_gravity: v,
                })
              : null
          }
        />
        <FossilyticsNumberField
          label="N₂"
          suffix="%"
          min={0}
          max={100}
          value={value.n2_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  n2_perc: v,
                })
              : null
          }
        />
        <FossilyticsNumberField
          label="CO₂"
          suffix="%"
          min={0}
          max={100}
          value={value.co2_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  co2_perc: v,
                })
              : null
          }
        />
        <FossilyticsNumberField
          label="H₂S"
          suffix="%"
          min={0}
          max={100}
          value={value.h2s_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  h2s_perc: v,
                })
              : null
          }
        />
      </Stack>

      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <Dropdown
          label="Formation compressibility method"
          options={formationCompressibilityOptions}
          selectedKey={`${value.form_compressibility_method}-${value.rock_type}`}
          onChange={(_, v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  ...v.data,
                })
              : null
          }
        />
        <Dropdown
          label="Z Method"
          onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.gasDeviationFactor, props)}
          options={zMethodOptions}
          selectedKey={value.z_method}
          onChange={(_, v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  z_method: v.key as ZMethod,
                })
              : null
          }
        />

        <Dropdown
          label="Water viscosity method"
          options={wtrViscMethodOptions}
          selectedKey={value.water_viscosity_method}
          onChange={(_, v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  water_viscosity_method: v.key as WaterViscosityMethod,
                })
              : null
          }
        />
        <Dropdown
          label="Gas viscosity method"
          onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.gasViscosity, props)}
          options={gasViscMethodOptions}
          selectedKey={value.gas_viscosity_method}
          onChange={(_, v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  gas_viscosity_method: v.key as GasViscosityMethod,
                })
              : null
          }
        />
        <FossilyticsNumberField
          label="Formation temperature"
          suffix="°F"
          value={value.form_temp_of}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  form_temp_of: v,
                })
              : null
          }
        />

        <FossilyticsNumberField
          label="Water Salinity "
          suffix="PPM"
          value={value.salinity_ppm}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  salinity_ppm: v,
                })
              : null
          }
        />
      </Stack>
    </Stack>
  );
};

export default BaseInputs;
