import React, { useCallback, useMemo, useState } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import _ from "lodash";

import { camelToSnakeCase, formatToScientific, mapEnumToDropdownReactGrid } from "@/utils/general";
import dictionary from "@/constants/dictionary";
import { TahkCsgInputState, TahkCsgStateResponse } from "@/models/tahk";

import { geomechanicsHeader, tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "../../../constants/grid";
import helpLinkUrl from "@/constants/helpLinkUrl";
import { GeomechanicModelEnum } from "@/models/InputGeneric";
import { geomechanicHeaderWithLink } from "@/constants/gridTable";

export type TahkCsgGeomechanicsProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
};

const useGeomechanics = ({ loadingState, setTahkCsgState, inputs }: TahkCsgGeomechanicsProps) => {
  const [activeDropdown, setActiveDropdown] = useState<number>(0);

  const columns = Object.keys(geomechanicsHeader).map((columnId, index) => {
    return { columnId: camelToSnakeCase(columnId), width: index === 0 ? 50 : 135 };
  });

  const rows: Row<any>[] = useMemo(() => {
    if (!inputs) return [];
    const input = _.cloneDeep(inputs);
    const headerKeys = Object.keys(geomechanicsHeader);
    return [
      {
        rowId: "header",
        cells: Object.values(geomechanicsHeader).map((header, index) => {
          return {
            type: "custom",
            text: header,
            style: tableHeaderStyle,
            link: geomechanicHeaderWithLink.indexOf(Object.keys(geomechanicsHeader)[index]) > -1 ? helpLinkUrl.input.geomechanics : null,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...input.layers.map((layer, rowIndex) => {
        const matrixShrinkage = layer.matrix_shrinkage;
        const matrixShrinkageParams = (layer.matrix_shrinkage.parameter ?? {}) as { [key: string]: number };
        return {
          rowId: rowIndex,
          height: 30,
          cells: [
            {
              type: "number",
              value: rowIndex + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).map((header, index) => {
              const val = index === 0 ? matrixShrinkage.selected_geomechanic_model ?? undefined : matrixShrinkageParams[camelToSnakeCase(header)];
              if (index === 0) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: mapEnumToDropdownReactGrid(GeomechanicModelEnum),
                  style: tableCellStyle,
                  isOpen: activeDropdown === rowIndex + 1,
                  nonEditable: loadingState,
                };
              } else if (isNaN(Number(val)) && String(val) === "undefined") {
                return {
                  type: "text",
                  text: "-",
                  style: tableCellStyle,
                  nonEditable: true,
                };
              }

              return {
                type: "text",
                text: formatToScientific(val),
                style: tableCellStyle,
                nonEditable: loadingState,
              };
            }),
          ],
        };
      }),
    ];
  }, [activeDropdown, inputs, loadingState]);

  const onCellsChanged = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const updatedRows = [...inputs.layers];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const matrixShrinkage = updatedRows[rowId].matrix_shrinkage;
        const matrixShrinkageParams = updatedRows[rowId].matrix_shrinkage.parameter as { [key: string]: any };

        if (type === "dropdown") {
          const newDropdown = dropDownNewCell.isOpen ? rowId + 1 : 0;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== activeDropdown) {
            setActiveDropdown(dropDownNewCell.isOpen ? rowId + 1 : 0);
          } else {
            setActiveDropdown(0);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            matrixShrinkage.selected_geomechanic_model = dropDownNewCell.selectedValue as GeomechanicModelEnum;
          }
        } else {
          matrixShrinkageParams[columnId] = newCell.value ?? newCell.text ?? 0;
        }
      }

      setTahkCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            layers: updatedRows,
          },
        };
      });
    },
    [activeDropdown, inputs, setTahkCsgState]
  );

  return {
    columns,
    rows,
    onCellsChanged,
  };
};

export default useGeomechanics;
