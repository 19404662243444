import React from "react";
import { Stack, Pivot, PivotItem } from "@fluentui/react";
import { Column, Row } from "@silevis/reactgrid";

// component
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

// utils
import useThemeStyling from "@/utils/useThemeStyling";

// constant
import dictionary from "@/constants/dictionary";
import LoadingIndicator from "@/components/LoadingIndicator";

export type AutoRtaForecastCalculationProps = {
  isLoading: boolean;
  dataTableRow: Row[];
  forecastXAxes: FossilyticsChartAxis[];
  forecastYAxes: FossilyticsChartAxis[];
  cumulativeGasYAxes: FossilyticsChartAxis[];
  forecastCalcSeries: FossilyticsChartSeries[];
  cumulativeGasChartSeries: FossilyticsChartSeries[];
  forecastCalculationDataTableCol: Column[];
};

const AutoRtaForecastCalculation = ({
  isLoading,
  dataTableRow,
  forecastXAxes,
  forecastYAxes,
  forecastCalcSeries,
  cumulativeGasChartSeries,
  cumulativeGasYAxes,
  forecastCalculationDataTableCol,
}: AutoRtaForecastCalculationProps) => {
  const { elevatedCard, palette } = useThemeStyling();

  return (
    <Stack style={elevatedCard} grow={1}>
      {cumulativeGasChartSeries.length === 0 && <div style={{ color: palette.warning.main }}>{dictionary.autoRta.calculateForecastToGetResult}</div>}
      <Pivot styles={{ itemContainer: { flex: "1" } }}>
        <PivotItem headerText="Chart">
          <div style={{ height: 700 }}>
            <FossilyticsChart
              id="auto-rta-forecast-chart"
              isLoading={isLoading}
              xAxes={forecastXAxes}
              yAxes={forecastYAxes}
              series={forecastCalcSeries}
            />
          </div>
        </PivotItem>
        <PivotItem headerText="Cumulative Gas Chart">
          <div style={{ height: 700 }}>
            <FossilyticsChart
              id="auto-rta-forecast-cumulative-gas-chart"
              isLoading={isLoading}
              xAxes={forecastXAxes}
              yAxes={cumulativeGasYAxes}
              series={cumulativeGasChartSeries}
            />
          </div>
        </PivotItem>
        <PivotItem headerText={dictionary.modelDataTable} style={{ position: "relative", margin: "auto", paddingTop: 20 }}>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <ReactGridTable
              viewWidth={"100%"}
              viewHeight={650}
              columns={forecastCalculationDataTableCol}
              rows={dataTableRow}
              enableRangeSelection
              enableColumnSelection
              stickyTopRows={2}
            />
          )}
        </PivotItem>
      </Pivot>
    </Stack>
  );
};

export default AutoRtaForecastCalculation;
