import { fetchApi } from "@/utils/apiFetcher";
import { autoRtaInitialize } from "@/constants/apiUrl";
import { AutoRtaState } from "../State";

type PollInitializeGazAutoRtaPayload = {
  data_set_ids: string[];
  projectId: string;
  type: string;
};

export const postInitializeAutoRta = ({ data_set_ids, projectId, type }: PollInitializeGazAutoRtaPayload) => {
  return fetchApi<AutoRtaState>({
    path: autoRtaInitialize(projectId, type),
    type: "post",
    body: { data_set_ids },
  });
};
