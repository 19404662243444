import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import { useCallback, useMemo, useState } from "react";
import _ from "lodash";

import dictionary from "@/constants/dictionary";

import { relativePermeabilityBase, relativePermeabilityBaseWidth } from "@/constants/gridTable";
import { RelativePermeability, RelativePermeabilityCorrelationEnum } from "@/models/InputGeneric";

import { tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { camelToSnakeCase, formatToScientific, mapEnumToDropdownReactGrid } from "@/utils/general";

import { UseModelComponentInputProps } from "../KoldunCsgContext";

const relativePermeabilityHeader: {
  [key: string]: {
    label: string;
    sub?: string;
  };
} = {
  measure: {
    label: dictionary.koldunCsg.measure,
  },
  ...relativePermeabilityBase,
};

const relativePermeabilityWidth: { [key: string]: number } = {
  measure: 65,
  ...relativePermeabilityBaseWidth,
};

// default param by enum
const defaultParam: {
  [key: string]: RelativePermeability["parameter"];
} = {
  [RelativePermeabilityCorrelationEnum.Burdine]: {
    connate_water_saturation: 20,
    krg_max: 1,
    lamb: 1.5,
    residual_gas_saturation: 0,
  },
  [RelativePermeabilityCorrelationEnum.ModifiedBurdine]: {
    connate_water_saturation: 20,
    krg_max: 1,
    lamb: 1.5,
    n: 2,
    residual_gas_saturation: 0,
  },
  [RelativePermeabilityCorrelationEnum.Corey]: {
    krg_max: 1,
    connate_water_saturation: 20,
    residual_gas_saturation: 5,
    krw_max: 1,
    ng: 1,
    nw: 1,
  },
};

const useRelativePermeability = ({ loadingState, measures, setKoldunCsgState }: UseModelComponentInputProps) => {
  const [activeDropdown, setActiveDropdown] = useState<{ columnId: string; row: number } | null>(null);

  const relativePermeabilityColumns = Object.keys(relativePermeabilityHeader).map((columnId) => {
    return { columnId: camelToSnakeCase(columnId), width: relativePermeabilityWidth[columnId] + 5.5 };
  });

  const relativePermeabilityRows: Row<any>[] = useMemo(() => {
    if (!measures) return [];
    const clonedMeasures = _.cloneDeep(measures);
    const headerKeys = Object.keys(relativePermeabilityHeader);
    return [
      {
        rowId: "header",
        cells: Object.keys(relativePermeabilityHeader).map((header) => {
          return {
            type: "custom",
            text: relativePermeabilityHeader[header].label,
            style: tableHeaderStyle,
            sub: relativePermeabilityHeader[header]?.sub ?? null,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...clonedMeasures.map((measure, index) => {
        const relativePermeability: RelativePermeability = measure.relative_permeability;
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "number",
              value: index + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).map((header, headerIndex) => {
              const val =
                headerIndex === 0
                  ? relativePermeability[camelToSnakeCase(header) as keyof RelativePermeability]
                  : relativePermeability.parameter[camelToSnakeCase(header) as keyof RelativePermeability["parameter"]];
              if (headerIndex === 0) {
                return {
                  selectedValue: val ?? undefined,
                  values: mapEnumToDropdownReactGrid(RelativePermeabilityCorrelationEnum),
                  style: tableCellStyle,
                  isOpen: !!(activeDropdown && activeDropdown.columnId === camelToSnakeCase(header) && index === activeDropdown.row),
                  nonEditable: loadingState,
                  type: "dropdown",
                };
              } else if (isNaN(Number(val))) {
                return {
                  type: "text",
                  text: "-",
                  style: tableCellStyle,
                  nonEditable: true,
                };
              }

              return {
                type: "text",
                text: formatToScientific(Number(val)),
                style: tableCellStyle,
                nonEditable: loadingState,
              };
            }),
          ],
        };
      }),
    ];
  }, [measures, loadingState, activeDropdown]);

  const onChangeRelativePermeabilityCell = useCallback(
    (changes: CellChange[]) => {
      if (!measures) return;
      const updatedRows = [...measures];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const relativePermeability = updatedRows[rowId].relative_permeability as { [key: string]: any };

        if (type === "dropdown") {
          const newDropdown = {
            row: rowId,
            columnId: columnId,
          };
          if (prevCell.isOpen !== dropDownNewCell.isOpen || !_.isEqual(newDropdown, activeDropdown)) {
            if (dropDownNewCell.isOpen) {
              setActiveDropdown(newDropdown);
            } else {
              setActiveDropdown(null);
            }
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            relativePermeability[columnId] = dropDownNewCell.selectedValue;

            relativePermeability.parameter = _.cloneDeep(defaultParam[dropDownNewCell.selectedValue as string]);
          }
        } else {
          relativePermeability.parameter[columnId] = newCell.value ?? newCell.text ?? 0;
        }
      }

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            measures: updatedRows,
          },
        };
      });
    },
    [measures, setKoldunCsgState, activeDropdown]
  );

  return { onChangeRelativePermeabilityCell, relativePermeabilityColumns, relativePermeabilityRows };
};

export default useRelativePermeability;
