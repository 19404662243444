import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import { UseModelComponentInputProps } from "../../../hooks/KoldunCsgContext";
import useGeomechanics from "../../../hooks/input/useGeomechanics";

const Geomechanics = (props: UseModelComponentInputProps) => {
  const { columns, onCellsChanged, rows } = useGeomechanics(props);
  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  return (
    <div
      style={{
        padding: 10,
        marginTop: 20,
      }}
    >
      <ReactGridTable
        viewWidth={windowWidth - 100}
        viewHeight={360}
        columns={columns}
        rows={rows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onCellsChanged}
      />
    </div>
  );
};

export default Geomechanics;
