import { number, object, string } from "zod";

export const TahkDataTableScheme = object({
  reservoir_pressure: number().array(),
  flowing_pressure: number().array(),
  pseudo_reservoir_pressure: number().array(),
  pseudo_flowing_pressure: number().array(),
  gas_rate: number().array(),
  cumulative_gas_production: number().array(),
  water_rate: number().array(),
  cumulative_water_production: number().array(),
  gas_formation_volume_factor: number().array(),
  water_formation_volume_factor: number().array(),
  gas_viscosity: number().array(),
  water_viscosity: number().array(),
  water_saturation: number().array(),
  gas_relative_permeability: number().array(),
  water_relative_permeability: number().array(),
  productivity_index_multiplier: number().array(),
  permeability_ratio: number().array(),
  z_factor: number().array(),
  dates: string().array(),
});

export const TahkDataTableCurveScheme = object({
  dates: string().array(),
  gas_rate: number().array(),
  water_rate: number().array(),
  cumulative_gas_production: number().array(),
  cumulative_water_production: number().array(),
  bottomhole_pressure: number().array().nullable(),
});
