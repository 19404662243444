import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";

import { DefinedParameters, AutoRtaInputGrid, FormationCompressibilityCorrelation } from "@/models/gaz/autoRta";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { mapEnumToDropdown } from "@/utils/general";

export interface DefinedParamProps {
  value: AutoRtaInputGrid;
  onChange: (v: number | string | undefined, key: keyof DefinedParameters) => void;
  isLoading: boolean;
  validationError: ErrorValidationDetail[];
}

const DefinedParameter = ({ value, onChange, isLoading, validationError }: DefinedParamProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: 20,
      }}
    >
      <div className="equal-size">
        <InputField
          label="Initial pressure"
          suffix="psia"
          debounceDelay={1000}
          value={value.defined_parameters.initial_pressure}
          onChange={(v) => onChange(v, "initial_pressure")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.defined_parameters.initial_pressure"
        />
        <InputField
          label="Formation temperature"
          suffix="°F"
          debounceDelay={1000}
          value={value.defined_parameters.formation_temperature}
          onChange={(v) => onChange(v, "formation_temperature")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.defined_parameters.formation_temperature"
        />
        <InputField
          label="Water Saturation"
          suffix="%"
          min={0}
          max={100}
          debounceDelay={1000}
          value={value.defined_parameters.initial_water_saturation}
          onChange={(v) => onChange(v, "initial_water_saturation")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.defined_parameters.initial_water_saturation"
        />
        <InputField
          label="Porosity"
          suffix="%"
          debounceDelay={1000}
          value={value.defined_parameters.porosity}
          onChange={(v) => onChange(v, "porosity")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.defined_parameters.porosity"
        />
      </div>
      <div className="equal-size">
        <InputField
          label="Wellbore radius"
          suffix="ft"
          debounceDelay={1000}
          value={value.defined_parameters.wellbore_radius}
          onChange={(v) => onChange(v, "wellbore_radius")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.defined_parameters.wellbore_radius"
        />
        <InputField
          label="Net pay"
          suffix="ft"
          debounceDelay={1000}
          value={value.defined_parameters.net_pay}
          onChange={(v) => onChange(v, "net_pay")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.defined_parameters.net_pay"
        />
        <DropdownField
          label="Formation compressibility (psi⁻¹)"
          options={mapEnumToDropdown(FormationCompressibilityCorrelation)}
          selectedKey={value.defined_parameters.selected_formation_compressibility_correlation}
          onChange={(v) => onChange(v, "selected_formation_compressibility_correlation")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.defined_parameters.selected_formation_compressibility_correlation"
        />
        {value.defined_parameters.selected_formation_compressibility_correlation === "Constant" && (
          <InputField
            label=""
            suffix="1/psia"
            debounceDelay={1000}
            value={value.defined_parameters.formation_compressibility}
            onChange={(v) => onChange(v, "formation_compressibility")}
            disabled={isLoading}
            errors={validationError}
            keyField="inputs.defined_parameters.formation_compressibility"
          />
        )}
      </div>
    </div>
  );
};

export default DefinedParameter;
