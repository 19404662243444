import { Pivot, PivotItem, Stack } from "@fluentui/react";

import useThemeStyling from "@/utils/useThemeStyling";
import { normalizedPressureXaxisOptions } from "../../constants";

// components
import NormalizedChart from "./NormalizedChart";
import AutoRtaAnalysisTable from "./AutoRtaAnalysisTable";

// hooks
import useAutoRtaAnalysisNormalizedPressure from "../../hooks/rtaAnalysis/useAutoRtaAnalysisNormalizedPressure";
import useAutoRtaAnalysisNormalizedRate from "../../hooks/rtaAnalysis/useAutoRtaAnalysisNormalizedRate";
import useAutoRtaAnalysisDataTable from "../../hooks/rtaAnalysis/useAutoRtaAnalysisDataTable";

import { AnalysisCalculation } from "@/models/gaz/autoRta";

export type AnalysisCalculationProps = {
  isLoading: boolean;
  analysisCalculation?: AnalysisCalculation;
};

const AnalysisCalculationContainer = ({ isLoading, analysisCalculation }: AnalysisCalculationProps) => {
  const { elevatedCard } = useThemeStyling();
  const {
    id: normalizedPressureId,
    onChangeDropdownOption: normalizedPressureChangeDropdownOption,
    selectedOption: normalizedPressureSelectedOption,
    series: normalizedPressureSeries,
    xAxes: normalizedPressureXAxes,
    yAxes: normalizedPressureYAxes,
  } = useAutoRtaAnalysisNormalizedPressure({
    analysisCalculation,
  });

  const {
    id: normalizedRateId,
    onChangeDropdownOption: normalizedRateChangeDropdownOption,
    selectedOption: normalizedRateSelectedOption,
    series: normalizedRateSeries,
    xAxes: normalizedRateXAxes,
    yAxes: normalizedRateYAxes,
  } = useAutoRtaAnalysisNormalizedRate({
    analysisCalculation,
  });

  const { columns, dataTableRows, modelCurvesRows } = useAutoRtaAnalysisDataTable(analysisCalculation);

  return (
    <Stack className="PivotWrapper" style={elevatedCard} grow={1}>
      <Pivot styles={{ itemContainer: { flex: "1" } }}>
        <PivotItem headerText="Normalized Pressure">
          <NormalizedChart
            id={normalizedPressureId}
            dropDownOption={normalizedPressureXaxisOptions}
            isLoading={isLoading}
            onChangeDropdownOption={normalizedPressureChangeDropdownOption}
            selectedOption={normalizedPressureSelectedOption}
            series={normalizedPressureSeries}
            xAxes={normalizedPressureXAxes}
            yAxes={normalizedPressureYAxes}
          />
        </PivotItem>
        <PivotItem headerText="Normalized Rate">
          <NormalizedChart
            id={normalizedRateId}
            dropDownOption={normalizedPressureXaxisOptions}
            isLoading={isLoading}
            onChangeDropdownOption={normalizedRateChangeDropdownOption}
            selectedOption={normalizedRateSelectedOption}
            series={normalizedRateSeries}
            xAxes={normalizedRateXAxes}
            yAxes={normalizedRateYAxes}
          />
        </PivotItem>
        <PivotItem headerText="Data Table" style={{ position: "relative", height: "96%" }}>
          <AutoRtaAnalysisTable isLoading={isLoading} rows={dataTableRows} columns={columns} />
        </PivotItem>
        <PivotItem headerText="Model Curves" style={{ position: "relative", height: "96%" }}>
          <AutoRtaAnalysisTable isLoading={isLoading} rows={modelCurvesRows} columns={columns} />
        </PivotItem>
      </Pivot>
    </Stack>
  );
};

export default AnalysisCalculationContainer;
