import { CholeskyDecomposition, Matrix } from "ml-matrix";
import { snakeToCamelCase } from "@/utils/general";

function sortingKeys(value: Record<string, number>): string[] {
  let keyCount: Record<string, number> = {};
  for (let v in value) {
    let [firstKey, secondKey] = v.split(":");
    if (!(firstKey in keyCount)) {
      keyCount[firstKey] = 0;
    }
    if (!(secondKey in keyCount)) {
      keyCount[secondKey] = 0;
    }
    keyCount[firstKey] += 1;
  }

  return Object.entries(keyCount)
    .sort((a, b) => b[1] - a[1])
    .map((entry) => entry[0]);
}

export function checkMatrixLimitsAndCholeskyCondition(value: Record<string, number>): void {
  let sortedKeys = sortingKeys(value);
  let numberOfVariables = sortedKeys.length;

  for (let k = 0; k < numberOfVariables - 1; k++) {
    for (let jj = k + 1; jj < numberOfVariables; jj++) {
      let key = `${sortedKeys[k]}:${sortedKeys[jj]}`;
      let matrixValue = value[key];
      if (!(matrixValue > -1 && matrixValue < 1)) {
        throw new RangeError(`${snakeToCamelCase(sortedKeys[k])} : ${snakeToCamelCase(sortedKeys[jj])} must be between -1 and 1`);
      }
    }
  }

  let matrix = Matrix.eye(numberOfVariables);
  for (let k = 0; k < numberOfVariables; k++) {
    for (let jj = k + 1; jj < numberOfVariables; jj++) {
      let v = value[`${sortedKeys[k]}:${sortedKeys[jj]}`];
      matrix.set(jj, k, v);
      matrix.set(k, jj, v);
    }
  }

  if (!new CholeskyDecomposition(matrix).isPositiveDefinite()) {
    throw new RangeError("There are too many variables correlated, please adjust the value");
  }
}
