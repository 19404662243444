import { Icon, Stack, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import useThemeStyling from "@/utils/useThemeStyling";

initializeIcons();

interface NewPasswordFieldRequirementProps {
  pass: boolean;
  text: string;
}

function NewPasswordFieldRequirement({ pass, text }: NewPasswordFieldRequirementProps) {
  const { palette } = useThemeStyling();

  return (
    <Stack horizontal tokens={{ childrenGap: 10 }}>
      <Icon style={{ color: pass ? palette.customColor.green : palette.customColor.red }} iconName={pass ? "CheckMark" : "Cancel"} />
      <span>{text}</span>
    </Stack>
  );
}

interface NewPasswordFieldProps {
  onChange: (password?: string) => void;
}

function NewPasswordField({ onChange }: NewPasswordFieldProps) {
  const [password, setPassword] = useState<string>("");
  const [lengthRqmt, setLengthRqmt] = useState<boolean>(false);
  const [numberRqmt, setNumberRqmt] = useState<boolean>(false);
  const [specialRqmt, setSpecialRqmt] = useState<boolean>(false);
  const [upperRqmt, setUpperRqmt] = useState<boolean>(false);
  const [lowerRqmt, setLowerRqmt] = useState<boolean>(false);

  useEffect(() => {
    const hasValidLength = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[$^*.[\]{}()?\-"!@#%&/\\,<>':;|_~`+=]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);

    setLengthRqmt(hasValidLength);
    setNumberRqmt(hasNumber);
    setSpecialRqmt(hasSpecialCharacter);
    setUpperRqmt(hasUpperCase);
    setLowerRqmt(hasLowerCase);

    if (password && hasValidLength && hasNumber && hasSpecialCharacter && hasUpperCase && hasLowerCase) {
      onChange(password);
    } else {
      onChange(undefined);
    }
  }, [onChange, password]);

  const handlePasswordChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setPassword(newValue || "");
  };

  return (
    <>
      <TextField
        label="New Password"
        type="password"
        canRevealPassword
        revealPasswordAriaLabel="Show password"
        onRenderPrefix={() => <Icon iconName="PasswordField" />}
        value={password}
        onChange={handlePasswordChange}
      />
      <div style={{ padding: 10 }}>
        <NewPasswordFieldRequirement pass={lengthRqmt} text="Minimum 8 characters" />
        <NewPasswordFieldRequirement pass={numberRqmt} text="Contains at least 1 number" />
        <NewPasswordFieldRequirement pass={specialRqmt} text="Contains at least 1 special character" />
        <NewPasswordFieldRequirement pass={upperRqmt} text="Contains at least 1 uppercase letter" />
        <NewPasswordFieldRequirement pass={lowerRqmt} text="Contains at least 1 lowercase letter" />
      </div>
    </>
  );
}

export default NewPasswordField;
