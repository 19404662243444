import { ModuleId } from "@/model";
import { object, z, number, string } from "zod";
import { Interval } from "../Generic";

export type RulonItem = {
  data_set_id: string;
  module: string;
  input_status: string;
  output_calculation_time: string;
};

export type RulonGroupRunResponse = {
  input_validation: RulonItem[];
  available_modules: ModuleId[];
};

export type RulonChangeItem = {
  data_set_id: string;
  module: string;
};

const RulonRollupValidationScheme = object({
  data_set_id: z.string(),
  module: z.nativeEnum(ModuleId),
  output_calculation_time: z.string().datetime({ local: true, offset: true }).nullable(),
}).strict();

export type RulonRollupValidation = z.infer<typeof RulonRollupValidationScheme>;

const EurSummaryScheme = object({
  data_set_id: string(),
  initial_production: number(),
  low: number(),
  mid: number(),
  high: number(),
  rose_plot_x: number(),
  rose_plot_y: number(),
}).strict();

export const RulonRollupScheme = object({
  selection_validation: RulonRollupValidationScheme.array(),
  eur_summary: EurSummaryScheme.array(),
}).strict();

export type EurSummary = z.infer<typeof EurSummaryScheme>;

export type RulonRollupState = z.infer<typeof RulonRollupScheme>;

const rulonRollupItem = object({
  data_set_id: string(),
  module: z.nativeEnum(ModuleId),
});
const validateRulonRollupScheme = object({
  selection: rulonRollupItem.array(),
});

export type ValidateRulonRollup = z.infer<typeof validateRulonRollupScheme>;

const rollupIntervalPayloadScheme = object({
  interval: z.nativeEnum(Interval),
  selection: rulonRollupItem.array(),
});

export type RollupIntervalPayloadScheme = z.infer<typeof rollupIntervalPayloadScheme>;

const rulonRollupForecastDataScheme = object({
  low: number().array(),
  mid: number().array(),
  high: number().array(),
}).strict();

export type RulonRollupForecastData = z.infer<typeof rulonRollupForecastDataScheme>;

export const rulonRollupForecastScheme = object({
  dates: string().date().array(),
  rates: rulonRollupForecastDataScheme,
  cumulative_production: rulonRollupForecastDataScheme,
}).strict();

export type RulonRollupForecast = z.infer<typeof rulonRollupForecastScheme>;
