import TahkUncertainParamInput from "../../../../../../components/TahkUncertainParamInput";
import { ModuleTahkForecastGasOilOptions } from "../../model";

interface GasOilUncertainParameterInputsProps {
  value: ModuleTahkForecastGasOilOptions;
  onChange: (val: ModuleTahkForecastGasOilOptions) => void;
  tahkMode: boolean;
}

const GasOilUncertainParameterInputs = ({ value, onChange, tahkMode }: GasOilUncertainParameterInputsProps) => {
  if (!value) return <></>;
  return (
    <>
      <TahkUncertainParamInput
        name="OGIP"
        units="MMscf"
        min={0}
        disabled={!!value.drainage_radius_ft}
        hideDistributionSelect={tahkMode}
        value={value.ogip_mmscf}
        onChange={(v) => onChange({ ...value, ogip_mmscf: v })}
      />

      <TahkUncertainParamInput
        name="Water saturation"
        units="%"
        min={0}
        max={100}
        hideDistributionSelect={tahkMode}
        value={value.water_sat_perc}
        onChange={(v) => onChange({ ...value, water_sat_perc: v })}
      />
      <TahkUncertainParamInput
        name="Porosity"
        units="%"
        min={0}
        max={100}
        hideDistributionSelect={tahkMode}
        value={value.porosity_perc}
        onChange={(v) => onChange({ ...value, porosity_perc: v })}
      />
      <TahkUncertainParamInput
        name="Permeability"
        units="md"
        min={0}
        hideDistributionSelect={tahkMode}
        value={value.permeability_md}
        onChange={(v) => onChange({ ...value, permeability_md: v })}
      />
      <TahkUncertainParamInput
        name="Net pay"
        units="ft"
        min={0}
        hideDistributionSelect={tahkMode}
        value={value.net_pay_ft}
        onChange={(v) => onChange({ ...value, net_pay_ft: v })}
      />
      <TahkUncertainParamInput
        name="Initial pressure"
        units="psia"
        min={0}
        hideDistributionSelect={tahkMode}
        value={value.initial_pressure_psia}
        onChange={(v) => onChange({ ...value, initial_pressure_psia: v })}
      />
    </>
  );
};

export default GasOilUncertainParameterInputs;
