import { Dropdown, IDropdownOption, Stack } from "@fluentui/react";

import { ZMethod } from "@/model";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { renderLabelWithHelp } from "@/features/help-link";

import { ModuleGazPzPvtOptions } from "./model";
import { THEORETICAL_HELP_LINKS } from "@/constants/helpLinkUrl";

interface ModuleGazPzInputGridProps {
  value: ModuleGazPzPvtOptions | undefined;
  onChange: (value: ModuleGazPzPvtOptions) => void;
}

const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
] as IDropdownOption[];

const ModuleGazPzInputGrid = ({ value, onChange }: ModuleGazPzInputGridProps) => {
  if (!value) {
    return null;
  }

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <FossilyticsNumberField
          label="Initial pressure"
          suffix="psia"
          required
          value={value.initial_pressure_psia ?? null}
          onChange={(v) =>
            onChange({
              ...value,
              initial_pressure_psia: v,
            })
          }
        />
        <FossilyticsNumberField
          label="Formation temperature"
          suffix="°F"
          value={value.formation_temp_of}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  formation_temp_of: v,
                })
              : null
          }
        />
      </Stack>

      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <FossilyticsNumberField
          label="Gas gravity"
          suffix="g"
          value={value.gas_gravity}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  gas_gravity: v,
                })
              : null
          }
        />
        <FossilyticsNumberField
          label="N₂"
          suffix="%"
          min={0}
          max={100}
          value={value.n2_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  n2_perc: v,
                })
              : null
          }
        />
        <FossilyticsNumberField
          label="CO₂"
          suffix="%"
          min={0}
          max={100}
          value={value.co2_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  co2_perc: v,
                })
              : null
          }
        />
        <FossilyticsNumberField
          label="H₂S"
          suffix="%"
          min={0}
          max={100}
          value={value.h2s_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  h2s_perc: v,
                })
              : null
          }
        />
      </Stack>

      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <Dropdown
          label="Z Method"
          onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.gasDeviationFactor, props)}
          options={zMethodOptions}
          selectedKey={value.z_method}
          onChange={(_, v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  z_method: v.key as ZMethod,
                })
              : null
          }
        />
      </Stack>
    </Stack>
  );
};

export default ModuleGazPzInputGrid;
