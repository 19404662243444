import { calculateTahkCsgDataview } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { TahkCsgDataviewRequestPayload, TahkCsgDataviewResponse } from "../dataview";

export const postCalculateTahkCsgDataview = (payload: TahkCsgDataviewRequestPayload) => {
  return fetchApi<TahkCsgDataviewResponse, TahkCsgDataviewRequestPayload>({
    path: calculateTahkCsgDataview(payload.analysis_identity.project_id),
    body: payload,
    type: "post",
  });
};
