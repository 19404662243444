import { useMemo } from "react";

import useThemeStyling from "@/utils/useThemeStyling";
import { InputGridCalculation } from "@/models/gaz/autoRta";

import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

const useAutoRtaGasPvtCharts = (calculateValue: InputGridCalculation) => {
  const { palette } = useThemeStyling();
  const gasPvtZXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette.customColor.black]
  );

  const gasPvtZYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Compressibility Factor (Dim)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette.customColor.black]
  );

  const gasPvtZSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Gas Z`,
              type: "line",
              hideSymbol: true,
              color: palette.customColor.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.gas_pvt_charts.pressure.map((p, j) => [p, calculateValue.gas_pvt_charts.compressibility_factor[j]]),
            },
          ]
        : [],
    [calculateValue, palette.customColor.orangeLighter]
  );

  // Mu
  const gasPvtMuXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette]
  );

  const gasPvtMuYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Viscosity (cP)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );

  const gasPvtMuSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Gas Mu`,
              type: "line",
              hideSymbol: true,
              color: palette.customColor.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.gas_pvt_charts.pressure.map((p, j) => [p, calculateValue.gas_pvt_charts.viscosity[j]]),
            },
          ]
        : [],
    [calculateValue, palette]
  );

  // Pg - density
  const gasPvtPgXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette]
  );

  const gasPvtPgYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Density (lbm/ft³)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );

  const gasPvtPgSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Gas Pg`,
              type: "line",
              hideSymbol: true,
              color: palette.customColor.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.gas_pvt_charts.pressure.map((p, j) => [p, calculateValue.gas_pvt_charts.density[j]]),
            },
          ]
        : [],
    [calculateValue, palette]
  );

  // Bg - (Formation volume factor)
  const gasPvtBgXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette]
  );

  const gasPvtBgYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Formation volume factor (ft³/scf)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );

  const gasPvtBgSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Gas Bg`,
              type: "line",
              hideSymbol: true,
              color: palette.customColor.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.gas_pvt_charts.pressure.map((p, j) => [p, calculateValue.gas_pvt_charts.form_vol_factor[j]]),
            },
          ]
        : [],
    [calculateValue, palette]
  );

  // cg - compressibility (psi-1)
  const gasPvtcgXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette]
  );

  const gasPvtcgYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "compressibility (psi-1)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );

  const gasPvtcgSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Gas Cg`,
              type: "line",
              hideSymbol: true,
              color: palette.customColor.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.gas_pvt_charts.pressure.map((p, j) => [p, calculateValue.gas_pvt_charts.compressibility[j]]),
            },
          ]
        : [],
    [calculateValue, palette]
  );

  return {
    gasPvtZ: {
      xAxes: gasPvtZXAxes,
      yAxes: gasPvtZYAxes,
      series: gasPvtZSeries,
    },
    gasPvtMu: {
      xAxes: gasPvtMuXAxes,
      yAxes: gasPvtMuYAxes,
      series: gasPvtMuSeries,
    },
    gasPvtPg: {
      xAxes: gasPvtPgXAxes,
      yAxes: gasPvtPgYAxes,
      series: gasPvtPgSeries,
    },
    gasPvtBg: {
      xAxes: gasPvtBgXAxes,
      yAxes: gasPvtBgYAxes,
      series: gasPvtBgSeries,
    },
    gasPvtCg: {
      xAxes: gasPvtcgXAxes,
      yAxes: gasPvtcgYAxes,
      series: gasPvtcgSeries,
    },
  };
};
export default useAutoRtaGasPvtCharts;
