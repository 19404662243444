import { validateRulonRollup } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { RulonRollupState, ValidateRulonRollup } from "../State";
import { FluidType } from "@/models/Generic";

export const postValidateRulonRollup = (projectId: string, type: FluidType, body: ValidateRulonRollup) => {
  return fetchApi<RulonRollupState>({
    path: validateRulonRollup(projectId, type),
    body,
    type: "post",
  });
};
