import { WaterCompressibilityCorrelation, WaterViscosityCorrelation, ZCorrelation } from "@/models/InputGeneric";
import { z, object, number, string, nativeEnum, boolean } from "zod";

export enum FormationCompressibilityCorrelation {
  NewmanSandstone = "NewmanSandstone",
  NewmanLimestone = "NewmanLimestone",
  Hall = "Hall",
  Constant = "Constant",
  DobryninLaurent = "DobryninLaurent",
  DobryninWang = "DobryninWang",
}

// defined parameters
const defineParametersScheme = object({
  initial_pressure: number(),
  formation_temperature: number(),
  initial_water_saturation: number(),
  wellbore_radius: number(),
  net_pay: number(),
  porosity: number(),
  selected_formation_compressibility_correlation: nativeEnum(FormationCompressibilityCorrelation),
  formation_compressibility: number(),
});

export type DefinedParameters = z.infer<typeof defineParametersScheme>;

// Gas Pvt
export enum GasViscosityCorrelation {
  GonzalesEakin = "GonzalesEakin",
  Londono = "Londono",
  Standing = "Standing",
}

const gasPvtRecombinationScheme = object({
  use_gas_recombination: boolean(),
  specific_gravity_separator: number(),
  condensate_API: number(),
  condensate_gas_ratio: number(),
});

export type GasPvtRecombination = z.infer<typeof gasPvtRecombinationScheme>;

const gasPvtScheme = object({
  specific_gravity: number(),
  selected_z_correlation: nativeEnum(ZCorrelation),
  nitrogen: number(),
  carbon_dioxide: number(),
  hydrogen_sulphide: number(),
  selected_gas_viscosity_correlation: nativeEnum(GasViscosityCorrelation),
  section_gas_recombination: gasPvtRecombinationScheme,
});

export type GasPvt = z.infer<typeof gasPvtScheme>;

// langmuir isotherm
const langmuirIsothermScheme = object({
  langmuir_volume: number(),
  langmuir_pressure: number(),
  rock_density: number(),
  desorption_pressure: number(),
});

export type LangmuirIsotherm = z.infer<typeof langmuirIsothermScheme>;

// oil pvt

export enum OilDensityCorrelation {
  BlackOil = "BlackOil",
  StandingKatz = "StandingKatz",
}

export enum OilViscosityCorrelation {
  VasquezBeggs = "VasquezBeggs",
}

export enum SolutionGasOilCorrelation {
  VasquezBeggs = "VasquezBeggs",
  Standing = "Standing",
  Glaso = "Glaso",
  Lasater = "Lasater",
  Petrosky = "Petrosky",
}

const oilPvtScheme = object({
  oil_gravity_api: number(),
  bubble_pressure: number(),
  solution_gas_specific_gravity: number(),
  selected_oil_density_correlation: nativeEnum(OilDensityCorrelation),
  selected_oil_viscosity_correlation: nativeEnum(OilViscosityCorrelation),
  selected_solution_gas_oil_correlation: nativeEnum(SolutionGasOilCorrelation),
});
export type OilPvt = z.infer<typeof oilPvtScheme>;

// water pvt
const waterPvtScheme = object({
  salinity: number(),
  selected_water_compressibility_correlation: nativeEnum(WaterCompressibilityCorrelation),
  selected_water_viscosity_correlation: nativeEnum(WaterViscosityCorrelation),
});
export type WaterPvt = z.infer<typeof waterPvtScheme>;

// wellbore model
const wellboreScheme = object({
  use_wellbore_model: boolean(),
  tubing_diameter: number(),
  tubing_roughness: number(),
  tubing_depth: number(),
  well_angle: number(),
  wellhead_temperature: number(),
});
export type WellboreModel = z.infer<typeof wellboreScheme>;

export const autoRtaInputGridScheme = object({
  defined_parameters: defineParametersScheme,
  gas_pvt: gasPvtScheme,
  langmuir_isotherm: langmuirIsothermScheme,
  oil_pvt: oilPvtScheme,
  water_pvt: waterPvtScheme,
  wellbore_model: wellboreScheme,
});
export type AutoRtaInputGrid = z.infer<typeof autoRtaInputGridScheme>;

// response
const gasPvtDataTableScheme = object({
  pressure: number().array(),
  compressibility_factor: number().array(),
  viscosity: number().array(),
  density: number().array(),
  form_vol_factor: number().array(),
  compressibility: number().array(),
});

export type GasPvtDataTable = z.infer<typeof gasPvtDataTableScheme>;

const waterPvtDataTableScheme = object({
  pressure: number().array(),
  viscosity: number().array(),
  density: number().array(),
  form_vol_factor: number().array(),
  compressibility: number().array(),
});

export type WaterPvtDataTable = z.infer<typeof waterPvtDataTableScheme>;

const oilPvtDataTableScheme = object({
  pressure: number().array(),
  viscosity: number().array(),
  density: number().array(),
  form_vol_factor: number().array(),
  compressibility: number().array(),
  solution_gas_oil_ratio: number().array(),
  superficial_tension: number().array(),
});
export type OilPvtDataTable = z.infer<typeof oilPvtDataTableScheme>;

const langmuirIsothermDataTableScheme = object({
  pressure: number().array(),
  gas_content: number().array(),
  desorption_compressibility: number().array(),
});
export type LangmuirIsothermDataTable = z.infer<typeof langmuirIsothermDataTableScheme>;

const autoRtaForecastCalculationSummaryCardScheme = object({
  parameter: string(),
  value: number(),
  unit: string(),
});

export const inputGridCalculationScheme = object({
  gas_pvt_charts: gasPvtDataTableScheme,
  water_pvt_charts: waterPvtDataTableScheme,
  oil_pvt_charts: oilPvtDataTableScheme,
  langmuir_charts: langmuirIsothermDataTableScheme,
  summary_card: autoRtaForecastCalculationSummaryCardScheme.array(),
});
export type InputGridCalculation = z.infer<typeof inputGridCalculationScheme>;
