import { object, z } from "zod";
import { ModuleIdentityScheme } from "../Generic";
import { TahkCsgAnalysisStateScheme } from "./analysis";
import { tahkCsgDataviewScheme } from "./dataview";
import { TahkCsgForecastResponseScheme, tahkCsgForecastScheme } from "./forecast";
import { tahkCsgInputStateScheme } from "./input";

export const TahkCsgStateResponseScheme = object({
  dataview: tahkCsgDataviewScheme,
  inputs: tahkCsgInputStateScheme,
  forecast: tahkCsgForecastScheme,
  analysis: TahkCsgAnalysisStateScheme,
  // forecast result
  results: TahkCsgForecastResponseScheme.nullable(),
}).strict();

export type TahkCsgStateResponse = z.infer<typeof TahkCsgStateResponseScheme>;

const TahkCsgDataOptionScheme = object({
  data_options: object({
    analysis_identity: ModuleIdentityScheme,
    dataview: tahkCsgDataviewScheme,
  }),
});

export type TahkCsgDataOption = z.infer<typeof TahkCsgDataOptionScheme>;

export const TahkCsgValidationStateScheme = object({
  input_options: tahkCsgInputStateScheme,
  analysis_options: TahkCsgAnalysisStateScheme,
});
export type TahkCsgValidationState = z.infer<typeof TahkCsgValidationStateScheme>;
