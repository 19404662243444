import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import dictionary from "@/constants/dictionary";

import useThemeStyling from "@/utils/useThemeStyling";
import { ResultStatus, postGenericUpload } from "@/models/wells";

import IndividualItem from "../shared/components/IndividualItem";
import { Container, VisuallyHiddenInput } from "../shared/style";
import { saveBlob } from "@/util";

const ImportPage = () => {
  const { elevatedCard, palette } = useThemeStyling();

  const [files, setFiles] = useState<File[]>([]);
  const [res, setRes] = useState<ResultStatus | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const param = useParams();

  const handleOnChange = (e: any) => {
    setRes(undefined);
    setFiles(Array.from(e.target.files));
  };

  const handleOnSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      if (files.length > 0) {
        const res = await postGenericUpload({ file: files[0], url: `/${param.url}/${param.option}` });
        if (res.data) {
          const fileName = `${res.headers["content-disposition"].split("filename=")[1].replaceAll('"', "")}`;
          saveBlob(res.data, fileName);
          setRes("fulfilled");
          setFiles([]);
        } else {
          setRes("rejected");
        }
      }
    } catch (error: any) {
      setRes("rejected");
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }, [files, param.option, param.url]);

  const displayResponseStatus = useMemo(() => {
    if (!res) return <div />;
    if (res === "fulfilled") return <div style={{ color: palette.success.main }}>{dictionary.nav.convertSuccess}</div>;
    return <div style={{ color: palette.error.main }}>{dictionary.serverError}</div>;
  }, [palette, res]);

  return (
    <Container style={elevatedCard}>
      <h3>
        {param.option?.toUpperCase()} {dictionary.nav.tools}
      </h3>

      <div className="list-container">
        {displayResponseStatus}
        <div>
          {!res &&
            files?.map((file) => {
              return <IndividualItem key={file.name} name={file.name} />;
            })}
        </div>
      </div>
      <div className="button-container">
        <Button
          disabled={isLoading}
          style={{ color: "white" }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          {dictionary.wellImport.selectFile}
          <VisuallyHiddenInput onChange={handleOnChange} type="file" accept={".csv, .txt"} />
        </Button>

        <Button
          onClick={(e) => {
            e.preventDefault();
            handleOnSubmit();
          }}
          disabled={files.length === 0 || isLoading}
          style={{ color: "white", marginLeft: 20 }}
          variant="contained"
        >
          {dictionary.wellImport.submit}
        </Button>
      </div>
    </Container>
  );
};

export default ImportPage;
