import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, IDropdownOption, Stack, Text } from "@fluentui/react";

import { ModuleGildroHydrateOptions } from "./model";
import { GasViscosityMethod, OilDensityMethod, SolGasMethod, WaterViscosityMethod, ZMethod } from "../../../model";
import FossilyticsNumberField from "../../../components/fields/FossilyticsNumberField";
import useThemeStyling from "@/utils/useThemeStyling";

interface ModuleGildroHydrateInputGridTabProps {
  options: ModuleGildroHydrateOptions | undefined;
  onChange: (options: ModuleGildroHydrateOptions | undefined) => void;
}

const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
] as IDropdownOption[];

const oilDensityMethodOptions = [{ key: OilDensityMethod.EOS, text: "EOS" }] as IDropdownOption[];

const solGasMethodOptions = [
  { key: SolGasMethod.VASQUEZ_BEGGS, text: "Vasquez-Beggs" },
  { key: SolGasMethod.STANDING, text: "Standing" },
  { key: SolGasMethod.GLASO, text: "Glaso" },
  { key: SolGasMethod.LASATER, text: "Lasater" },
  { key: SolGasMethod.PETROSKY, text: "Petrosky" },
] as IDropdownOption[];

const wtrViscMethodOptions = [
  { key: WaterViscosityMethod.MCCAIN, text: "McCain" },
  { key: WaterViscosityMethod.LOUISIANA_STATE_UNI, text: "Louisiana State University" },
  { key: WaterViscosityMethod.BRILL_BEGGS, text: "Brill-Beggs" },
] as IDropdownOption[];

const gasViscMethodOptions = [
  { key: GasViscosityMethod.GONZALES_EAKIN, text: "Gonzales-Eakin" },
  { key: GasViscosityMethod.LONDONO, text: "Londono" },
  { key: GasViscosityMethod.STANDING, text: "Standing" },
] as IDropdownOption[];

const OIL_PVT_DEFAULT_VALUES = { oil_api_psia: 30, oil_pb_psia: 800 };
const WATER_PVT_DEFAULT_VALUES = { water_salinity_ppm: 0 };

const ModuleGildroHydrateInputGridTab = ({ options, onChange }: ModuleGildroHydrateInputGridTabProps) => {
  const [includeOil, setIncludeOil] = useState(false);
  const [includeWater, setIncludeWater] = useState(false);

  // Set defaults values
  useEffect(() => {
    if (options) return;

    onChange({
      formation_temp_of: 180,
      gas_gravity: 0.65,
      n2_perc: 0,
      co2_perc: 0,
      h2s_perc: 0,
      z_method: ZMethod.DPR,

      oil_api_psia: 30,
      oil_pb_psia: 800,
      oil_density_method: OilDensityMethod.EOS,
      sol_gas_method: SolGasMethod.VASQUEZ_BEGGS,
      water_salinity_ppm: 0,
      water_viscosity_method: WaterViscosityMethod.MCCAIN,
      tubing_diameter_in: 2.4,
      tubing_angle_deg: 90,
      tubing_depth_ft: 8900,
      wellhead_temp_of: 90,
      num_inc: 30,
      gas_viscosity_method: GasViscosityMethod.GONZALES_EAKIN,

      cases: [],
    });
  }, [options, onChange]);

  // Reset defaults when not selected
  useEffect(() => {
    if (
      options &&
      !includeOil &&
      (options.oil_api_psia !== OIL_PVT_DEFAULT_VALUES.oil_api_psia || options.oil_pb_psia !== OIL_PVT_DEFAULT_VALUES.oil_pb_psia)
    ) {
      onChange({ ...options, ...OIL_PVT_DEFAULT_VALUES });
    }
  }, [includeOil, onChange, options]);

  useEffect(() => {
    if (options && !includeWater && options.water_salinity_ppm !== WATER_PVT_DEFAULT_VALUES.water_salinity_ppm) {
      onChange({ ...options, ...WATER_PVT_DEFAULT_VALUES });
    }
  }, [includeWater, onChange, options]);

  const { palette, elevatedCard } = useThemeStyling();

  if (!options) {
    return null;
  }

  return (
    <Stack tokens={{ padding: 20, childrenGap: 20 }}>
      <Stack.Item style={elevatedCard}>
        <Text styles={{ root: { color: palette.primary.main } }}>Inputs</Text>

        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Formation temperature"
              suffix="°F"
              value={options.formation_temp_of}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      formation_temp_of: v,
                    })
                  : null
              }
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Gas gravity"
              suffix="g"
              value={options.gas_gravity}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      gas_gravity: v,
                    })
                  : null
              }
            />
            <FossilyticsNumberField
              label="N₂"
              suffix="%"
              min={0}
              max={100}
              value={options.n2_perc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      n2_perc: v,
                    })
                  : null
              }
            />
            <FossilyticsNumberField
              label="CO₂"
              suffix="%"
              min={0}
              max={100}
              value={options.co2_perc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      co2_perc: v,
                    })
                  : null
              }
            />
            <FossilyticsNumberField
              label="H₂S"
              suffix="%"
              min={0}
              max={100}
              value={options.h2s_perc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      h2s_perc: v,
                    })
                  : null
              }
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <Dropdown
              label="Z Method"
              options={zMethodOptions}
              selectedKey={options.z_method}
              onChange={(_, v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      z_method: v.key as ZMethod,
                    })
                  : null
              }
            />
          </Stack>
        </Stack>
      </Stack.Item>

      <Stack style={elevatedCard} tokens={{ childrenGap: 20 }}>
        <Text styles={{ root: { color: palette.primary.main } }}>Wellbore</Text>

        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <Checkbox label="Include Oil PVT" checked={includeOil} onChange={(_, v) => setIncludeOil(!!v)} />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Oil API"
              suffix="psia"
              disabled={!includeOil}
              value={options.oil_api_psia}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      oil_api_psia: v,
                    })
                  : null
              }
            />
            <FossilyticsNumberField
              label="Oil Pb"
              suffix="psia"
              disabled={!includeOil}
              value={options.oil_pb_psia}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      oil_pb_psia: v,
                    })
                  : null
              }
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <Dropdown
              label="Oil density method"
              options={oilDensityMethodOptions}
              selectedKey={options.oil_density_method}
              onChange={(_, v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      oil_density_method: v.key as OilDensityMethod,
                    })
                  : null
              }
            />
            <Dropdown
              label="Sol. gas method"
              options={solGasMethodOptions}
              selectedKey={options.sol_gas_method}
              onChange={(_, v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      sol_gas_method: v.key as SolGasMethod,
                    })
                  : null
              }
            />
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <Checkbox label="Include Water PVT" checked={includeWater} onChange={(_, v) => setIncludeWater(!!v)} />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Water salinity"
              suffix="ppm"
              disabled={!includeWater}
              value={options.water_salinity_ppm}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      water_salinity_ppm: v,
                    })
                  : null
              }
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <Dropdown
              label="Water viscosity method"
              options={wtrViscMethodOptions}
              selectedKey={options.water_viscosity_method}
              onChange={(_, v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      water_viscosity_method: v.key as WaterViscosityMethod,
                    })
                  : null
              }
            />
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Tubing diameter"
              suffix="in"
              value={options.tubing_diameter_in}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      tubing_diameter_in: v,
                    })
                  : null
              }
            />
            <FossilyticsNumberField
              label="Tubing angle"
              suffix="°"
              value={options.tubing_angle_deg}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      tubing_angle_deg: v,
                    })
                  : null
              }
            />
            <FossilyticsNumberField
              label="Tubing depth"
              suffix="ft"
              value={options.tubing_depth_ft}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      tubing_depth_ft: v,
                    })
                  : null
              }
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Wellhead temperature"
              suffix="°F"
              value={options.wellhead_temp_of}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      wellhead_temp_of: v,
                    })
                  : null
              }
            />
            <FossilyticsNumberField
              type="int"
              label="Number of increments"
              value={options.num_inc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      num_inc: v,
                    })
                  : null
              }
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <Dropdown
              label="Gas viscosity method"
              options={gasViscMethodOptions}
              selectedKey={options.gas_viscosity_method}
              onChange={(_, v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      gas_viscosity_method: v.key as GasViscosityMethod,
                    })
                  : null
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ModuleGildroHydrateInputGridTab;
