import UncertainParamInput from "../../../../../../../../components/UncertainParamInput";
import { ModuleKoldunMcsimGasOilOptions, ModuleKoldunMcsimUncertainParamType } from "../../../model";

import { koldunGasUncertainParameterMappingField } from "./model";
interface GasUncertainParameterInputsProps {
  value: ModuleKoldunMcsimGasOilOptions;
  onChange: (val: ModuleKoldunMcsimGasOilOptions) => void;
}

const GasUncertainParameterInputs = ({ value, onChange }: GasUncertainParameterInputsProps) => {
  if (!value) return <></>;
  return (
    <>
      {koldunGasUncertainParameterMappingField.map((param) => (
        <UncertainParamInput
          key={param.name}
          name={param.name}
          units={param.units}
          hideDistributionSelect={false}
          value={value[param.valueKey as keyof ModuleKoldunMcsimGasOilOptions] as ModuleKoldunMcsimUncertainParamType}
          onChange={(v) => onChange({ ...value, [param.valueKey]: v })}
        />
      ))}
    </>
  );
};

export default GasUncertainParameterInputs;
