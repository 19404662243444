import { useCallback, useMemo, useState } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";

import { mergedHeaderLeft, mergedHeaderRight, tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import helpLinkUrl from "@/constants/helpLinkUrl";
import dictionary from "@/constants/dictionary";
import { DistributionApplicationEnum, InputType, KoldunMeasure } from "@/models/koldunV2";
import { FormationCompressibilityCorrelationEnum } from "@/models/InputGeneric";
import { formatToScientific, mapEnumToDropdownReactGrid } from "@/utils/general";
import { UseModelComponentInputProps } from "../KoldunCsgContext";
import { defaultValueMap, ogipOnlyDistributionKey, restDistributionKey, volumetricOnlyDistributionKey } from "../../constants";

const tableMap: { [key: string]: string } = {
  measure: dictionary.koldunCsg.measure,
  number_of_layers: dictionary.koldunCsg.numberOfLayers,
  layer_depth: dictionary.koldunCsg.measureDepth,
  reservoir_area: dictionary.koldunCsg.area,
  formation_temperature: dictionary.koldunCsg.temperature,
  selected_formation_compressibility_correlation: dictionary.tahk.selectedFormationCompressibilityCorrelation,
  formation_compressibility: dictionary.tahk.formationCompressibility,
  permeability_vertical: dictionary.koldunCsg.permeability_vertical,
};

const useReservoirInput = ({ measures, setKoldunCsgState, loadingState, specifyInput }: UseModelComponentInputProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<number>(0);

  const reservoirInputColumns = Object.keys(tableMap).map((columnId, index) => {
    return { columnId: columnId, width: index === 0 ? 70 : 140 };
  });

  const getHeaderStyle = (header: string) => {
    switch (header) {
      case "selected_formation_compressibility_correlation":
        return mergedHeaderLeft;

      case "formation_compressibility":
        return mergedHeaderRight;

      default:
        return tableHeaderStyle;
    }
  };

  const reservoirInputRows: Row<any>[] = useMemo(() => {
    if (!measures) return [];
    const headerKeys = Object.keys(tableMap);
    return [
      {
        rowId: "header",
        cells: headerKeys.reduce((res: any, header: string) => {
          res.push({
            type: "custom",
            text: tableMap[header],
            style: getHeaderStyle(header),
            collSpan: header === "selected_formation_compressibility_correlation" ? 2 : 1,
            link: helpLinkUrl.input?.[header] ?? undefined,
          });

          return res;
        }, []),
        height: 50,
      },
      {
        rowId: "notation",
        cells: headerKeys.reduce((res: any, header) => {
          res.push({
            type: "header",
            text: dictionary.koldunUnits[header],
            style: tableHeaderNotationStyle,
          });

          return res;
        }, []),
      },
      ...measures.map((measure: KoldunMeasure, rowIndex) => {
        return {
          rowId: rowIndex,
          height: 30,
          cells: [
            {
              type: "number",
              value: rowIndex + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).reduce((res: any, header, index) => {
              const val = measure[header as keyof KoldunMeasure] as string | number;

              if (isNaN(Number(val)) && val) {
                res.push({
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: mapEnumToDropdownReactGrid(FormationCompressibilityCorrelationEnum),
                  style: tableCellStyle,
                  isOpen: isDropdownOpened === rowIndex + 1,
                  nonEditable: loadingState,
                });
              } else {
                res.push({
                  type: "text",
                  text: index === 0 ? String(val) : formatToScientific(val),
                  style: tableCellStyle,
                  nonEditable: loadingState,
                });
              }

              return res;
            }, []),
          ],
        };
      }),
    ];
  }, [isDropdownOpened, loadingState, measures]);

  const updateMeasureForDropdownChange = useCallback(
    (measure: { [key: string]: any }, columnId: string, prevCell: DropdownCell, dropDownNewCell: DropdownCell, rowId: number) => {
      const newDropdown = dropDownNewCell.isOpen ? rowId + 1 : 0;
      if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== isDropdownOpened) {
        setIsDropdownOpened(newDropdown);
      }
      if (dropDownNewCell.isOpen) return;
      if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
        measure[columnId] = dropDownNewCell.selectedValue;
      }
    },
    [isDropdownOpened]
  );

  const updateMeasureForNumberOfLayersChange = useCallback(
    (measure: { [key: string]: any }, newVal: number) => {
      const distributionKeys = [
        ...restDistributionKey,
        ...(specifyInput === InputType.Ogip ? ogipOnlyDistributionKey : volumetricOnlyDistributionKey),
      ];

      distributionKeys.forEach((distributionKey) => {
        if (measure[distributionKey].distribution_application === DistributionApplicationEnum.PerLayer) {
          if (newVal < measure.number_of_layers) {
            measure[distributionKey].distribution_parameters = measure[distributionKey].distribution_parameters.slice(0, newVal);
          } else {
            const parameterLength = measure[distributionKey].distribution_parameters.length;

            Array.from(Array(measure.number_of_layers - parameterLength).keys()).forEach((_) => {
              const val =
                measure[distributionKey].distribution_parameters[parameterLength - 1] ??
                defaultValueMap[distributionKey][measure[distributionKey].distribution_type];
              measure[distributionKey].distribution_parameters.push(val);
            });
          }
        }
      });
    },
    [specifyInput]
  );

  const onChangeReservoirInput = useCallback(
    (changes: CellChange[]) => {
      if (!measures) return;
      const updatedRows = [...measures];

      changes.forEach((change) => {
        const { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        const rowIdNum = rowId as number;
        const columnIdStr = columnId as string;

        const measure = updatedRows[rowIdNum] as { [key: string]: any };

        if (type === "dropdown") {
          updateMeasureForDropdownChange(measure, columnIdStr, prevCell, dropDownNewCell, rowIdNum);
        } else {
          let newVal = newCell.value ?? newCell.text ?? 0;
          if (columnIdStr === "number_of_layers") {
            if (newVal === 0) newVal = 1;
            updateMeasureForNumberOfLayersChange(measure, newVal);
          }
          measure[columnIdStr] = newVal;
        }
      });

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            measures: updatedRows,
          },
        };
      });
    },
    [measures, setKoldunCsgState, updateMeasureForDropdownChange, updateMeasureForNumberOfLayersChange]
  );

  return { onChangeReservoirInput, reservoirInputColumns, reservoirInputRows };
};

export default useReservoirInput;
