import { ModuleIdentity } from "@/models/Generic";
import {
  ContractRateEnum,
  FlowPressureTypeEnum,
  FormationCompressibilityCorrelationEnum,
  SkinModelEnum,
  ViscosityCorrelationEnum,
  WaterCompressibilityCorrelation,
  WaterViscosityCorrelation,
  WellInputEnum,
  WellboreModelEnum,
  ZCorrelation,
  matrixShrinkageScheme,
  powerLawScheme,
  relativePermeabilityScheme,
} from "@/models/InputGeneric";
import { z } from "zod";
import { koldunCsgForecastDataScheme } from "./Forecast";

export enum InputType {
  Ogip = "Ogip",
  Volumetric = "Volumetric",
}

// well input
const verticalFracturedWellScheme = z.object({
  fracture_half_length: z.number(),
  fracture_conductivity: z.number(),
});

const horizontalWellScheme = z.object({
  horizontal_length: z.number(),
});

const wellInputScheme = z.object({
  selected_well_type: z.nativeEnum(WellInputEnum),
  well_parameters: horizontalWellScheme.or(verticalFracturedWellScheme).nullable(),
});

export type KoldunCsgWellInput = z.infer<typeof wellInputScheme>;

// Distribution type

export enum DistributionTypeEnum {
  Lorenz = "Lorenz",
  Uniform = "Uniform",
  Triangular = "Triangular",
  Lognormal = "Lognormal",
  Normal = "Normal",
  Fixed = "Fixed",
}

export enum DistributionApplicationEnum {
  PerLayer = "PerLayer",
  PerMeasure = "PerMeasure",
}

const distributionBaseScheme = z.object({
  distribution_type: z.nativeEnum(DistributionTypeEnum),
  distribution_application: z.nativeEnum(DistributionApplicationEnum),
});

const distributionFixedValueScheme = z
  .object({
    value: z.number(),
  })
  .strict();

const distributionUniformValuesScheme = z
  .object({
    low_value: z.number(),
    high_value: z.number(),
  })
  .strict();

const distributionTriangularValuesScheme = z
  .object({
    low_value: z.number(),
    high_value: z.number(),
    mode_value: z.number(),
  })
  .strict();

const distributionLogNormalValuesScheme = z
  .object({
    mean_value: z.number(),
    standard_deviation: z.number(),
  })
  .strict();

const distributionLozenzScheme = z
  .object({
    value: z.number(),
    lorenz_factor: z.number(),
  })
  .strict();

const netPayDistributionScheme = z
  .object({
    distribution_parameters: z.array(distributionLozenzScheme.or(distributionFixedValueScheme)),
  })
  .merge(distributionBaseScheme);

const standartDistributionScheme = z
  .object({
    distribution_parameters: z.array(
      distributionFixedValueScheme.or(distributionTriangularValuesScheme).or(distributionUniformValuesScheme).or(distributionLogNormalValuesScheme)
    ),
  })
  .merge(distributionBaseScheme);

export type StandardDistributionScheme = z.infer<typeof standartDistributionScheme>;

const measureInputBaseScheme = z.object({
  number_of_layers: z.number(),
  reservoir_area: z.number(),
  formation_temperature: z.number(),
  selected_formation_compressibility_correlation: z.nativeEnum(FormationCompressibilityCorrelationEnum),
  formation_compressibility: z.number(),
  layer_depth: z.number(),

  permeability_vertical: z.number(),
  specific_gravity: z.number(),
  selected_z_correlation: z.nativeEnum(ZCorrelation),
  nitrogen: z.number(),
  carbon_dioxide: z.number(),
  hydrogen_sulphide: z.number(),
  selected_gas_viscosity_correlation: z.nativeEnum(ViscosityCorrelationEnum),
  salinity: z.number(),
  selected_water_compressibility_correlation: z.nativeEnum(WaterCompressibilityCorrelation),
  selected_water_viscosity_correlation: z.nativeEnum(WaterViscosityCorrelation),
  selected_skin_model: z.nativeEnum(SkinModelEnum),
  skin_value: z.number().nullable(),
  relative_permeability: relativePermeabilityScheme,
  geomechanics: matrixShrinkageScheme,
  wellbore_radius: z.number(),
  well_inputs: wellInputScheme,
  permeability_horizontal_distribution: standartDistributionScheme,
  net_pay_distribution: netPayDistributionScheme,
  initial_water_saturation_distribution: standartDistributionScheme,
  initial_pressure_distribution: standartDistributionScheme,

  porosity_distribution: standartDistributionScheme,
  langmuir_pressure_distribution: standartDistributionScheme,
  dependency_matrix_values: z.record(z.string(), z.number()),
});

const measureInputVolumetricScheme = z
  .object({
    langmuir_volume_distribution: standartDistributionScheme,
    desorption_pressure_distribution: standartDistributionScheme,
    rock_density_distribution: standartDistributionScheme,
  })
  .merge(measureInputBaseScheme);

const measureInputOgipScheme = z
  .object({
    ogip_distribution: standartDistributionScheme,
    under_saturation_distribution: standartDistributionScheme,
    initial_gas_content_distribution: standartDistributionScheme,
  })
  .merge(measureInputBaseScheme);

const measureScheme = measureInputVolumetricScheme.or(measureInputOgipScheme);

export const koldunCsgInputStateScheme = z.object({
  number_of_measures: z.number(),
  number_of_simulation: z.number(),
  specify_inputs: z.nativeEnum(InputType),
  power_law_skin: powerLawScheme.nullable(),
  dependency_matrices_keys: z.string().array(),
  measures: z.array(measureScheme),
});

export type KoldunMeasure = z.infer<typeof measureScheme>;

export type KoldunCsgInputState = z.infer<typeof koldunCsgInputStateScheme>;

const forecastEventScheme = z.object({
  date: z.string().datetime({ local: true, offset: true }),
  flowing_pressure: z.number(),
  rate: z.number().nullable(),
});

export type ForecastEvent = z.infer<typeof forecastEventScheme>;

const koldunCsgForecast = z.object({
  contract_rate_mode: z.nativeEnum(ContractRateEnum),
  selected_flow_pressure_type: z.nativeEnum(FlowPressureTypeEnum),
  production_start_date: z.string().datetime({ local: true, offset: true }),
  selected_wellbore_model: z.nativeEnum(WellboreModelEnum),
  pump_depth: z.number().nullable(),
  minimum_pump_head: z.number().nullable(),
  liquid_draw_down_rate: z.number().nullable(),
  initial_liquid_level: z.number().nullable(),
  forecast_events: z.array(forecastEventScheme),
});

export type KoldunCsgForecast = z.infer<typeof koldunCsgForecast>;

export const koldunCsgStateScheme = z
  .object({
    inputs: koldunCsgInputStateScheme,
    forecast: koldunCsgForecast,
    analysis_results: koldunCsgForecastDataScheme.nullable(),
  })
  .strict();

export type KoldunCsgApiState = z.infer<typeof koldunCsgStateScheme>;

export type KoldunCsgPayload = {
  analysis_id: ModuleIdentity;
  options: KoldunCsgInputState;
};

export type KoldunCsgForecastPayload = {
  analysis_id: ModuleIdentity;
  options: KoldunCsgApiState;
};
