import React from "react";
import { CellChange, Column, NumberCell, ReactGrid, Row } from "@silevis/reactgrid";
import { ModuleGazPzInit } from "./model";
import useThemeStyling from "@/utils/useThemeStyling";

interface ModuleGazPzDataGridProps {
  dataPoints: number[][];
  onDataPointsChange: (dataPoints: number[][]) => void;
  lastValidIndex: number;
  pz: ModuleGazPzInit | undefined;
}

function ModuleGazPzDataGrid({ dataPoints, onDataPointsChange, lastValidIndex, pz }: Readonly<ModuleGazPzDataGridProps>) {
  const { palette } = useThemeStyling();

  // Parse data from spreadsheet to points array
  const dataPointsCols = [
    { columnId: "x", width: 125 },
    { columnId: "y", width: 125 },
    { columnId: "z", width: 75 },
    { columnId: "pz", width: 75 },
  ] as Column[];

  const dataPointsRows = [
    {
      rowId: "header",
      cells: [
        { type: "header", text: "Cum. Production (x)" },
        { type: "header", text: "Static Pressure (y)" },
        { type: "header", text: "Z factor" },
        { type: "header", text: "P/z" },
      ],
    },
    {
      rowId: "units",
      cells: [
        { type: "header", text: "MMscf" },
        { type: "header", text: "psia" },
        { type: "header", text: "" },
        { type: "header", text: "psia" },
      ],
    },
    ...dataPoints.map((p, i) => ({
      rowId: i,
      cells: [
        {
          type: "number",
          value: p[0],
          nonEditable: i === 0,
          hideZero: i !== 0,
          style: { background: i === 0 ? palette.customColor.neutralLight : undefined },
        },
        {
          type: "number",
          value: p[1],
          nonEditable: i === 0,
          hideZero: i !== 0,
          style: { background: i === 0 ? palette.customColor.neutralLight : undefined },
        },
        {
          type: "number",
          value: pz && i <= lastValidIndex ? pz.z_factors[i] : 0,
          nonEditable: true,
          hideZero: true,
          style: { background: palette.customColor.neutralLight },
        },
        {
          type: "number",
          value: pz && i <= lastValidIndex ? pz.pzs[i] : 0,
          nonEditable: true,
          hideZero: true,
          style: { background: palette.customColor.neutralLight },
        },
      ],
    })),
  ] as Row[];

  const handleChanges = (changes: CellChange[]) => {
    const newPoints = JSON.parse(JSON.stringify(dataPoints));
    changes.forEach((change) => {
      const fieldIdx = ["x", "y"].indexOf(change.columnId as string);
      if (fieldIdx < 0) return;

      const numberCell = change.newCell as NumberCell;
      const pointIdx = change.rowId as number;
      newPoints[pointIdx][fieldIdx] = numberCell.value;
    });
    onDataPointsChange(newPoints);
  };

  return <ReactGrid columns={dataPointsCols} rows={dataPointsRows} enableRangeSelection stickyTopRows={2} onCellsChanged={handleChanges} />;
}

export default ModuleGazPzDataGrid;
