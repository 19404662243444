import { FossilyticsChartAxis, FossilyticsChartCustomDataZoom, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";

type UseLogRateProps = {
  logFlowSeries: FossilyticsChartSeries[];
  unit: string;
  xState: boolean;
};

const useLogRate = ({ logFlowSeries, unit, xState }: UseLogRateProps) => {
  const { palette } = useTheme();

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let name = "Days";
    let type = "value";
    let min = 0;

    if (xState) {
      name = "Date";
      type = "time";
      min = logFlowSeries[0]?.data[0][0];
    }

    return [{ name, type, color: palette.common.black, min }];
  }, [palette.common.black, xState, logFlowSeries]);

  const flowYAxesLog = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Rates (${unit}/d)`, type: "log", color: palette.common.black }],
    [palette.common.black, unit]
  );

  const findLastValueInFlowLinesForlogqgt = useMemo(() => {
    const newLine = logFlowSeries?.filter((line) => line.name === "Economic cutoff")[0]?.data ?? [];
    const logFlowSeriesLastPoint = logFlowSeries?.filter((seriesItem) => seriesItem.name === "Flow rates")[0]?.data ?? [];

    let percentage = 100;
    if (newLine && newLine.length > 0 && logFlowSeriesLastPoint.length > 0) {
      const newLineLastPoint = newLine[newLine.length - 1][0] as number;
      const lastNumberInData = logFlowSeriesLastPoint[logFlowSeriesLastPoint.length - 1][0];
      if ((lastNumberInData * 3) / newLineLastPoint < 1) {
        percentage = Math.floor(((lastNumberInData * 3) / newLineLastPoint) * 100);
      }
    }
    return percentage;
  }, [logFlowSeries]);

  const logRateFlowCustomZoom = useMemo<FossilyticsChartCustomDataZoom>(() => {
    return {
      customDataZoom: true,
      dataZoomPercentage: findLastValueInFlowLinesForlogqgt,
    };
  }, [findLastValueInFlowLinesForlogqgt]);

  return { logRateFlowCustomZoom, xAxes, flowYAxesLog };
};
export default useLogRate;
