import React, { useState } from "react";

import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";

import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/ReportGmailerrorred";

import styled from "@emotion/styled";
import { DataSet } from "@/model";
import { Menu, MenuItem } from "@mui/material";
import { DialogEnum } from "../types";
import dictionary from "@/constants/dictionary";

export const DataSetItem = styled.div<{ isDragging?: boolean }>`
  width: 90%;
  opacity: ${(props) => (props.isDragging ? 0.4 : 1)};
  margin-right: 5px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #edebe9;
  }

  .dataset-name {
    padding-left: 10px;
  }

  .MuiRadio-sizeSmall {
    padding: 5px;
  }

  span {
    visibility: hidden;
  }

  span input[type="radio"]:checked + span {
    visibility: visible;
  }

  &:hover span {
    visibility: visible;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  .radio-well {
    margin-right: 12px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const DataSetMoreButton = styled.div`
  position: relative;
  display: flex;
  margin-left: auto;

  button {
    padding-right: 5px;
  }
  .more-icon {
    width: 20px;
    height: 20px;
  }
`;

type DataSetRadioItemProps = {
  dataSet: string;
  currDataSet?: DataSet;
  setActiveDialog: (val: DialogEnum | undefined) => void;
  onClick: () => void;
  checked: boolean;
  isDragging?: boolean;
  disableMenu?: boolean;
  [key: string]: any;
};

type WellItemProps = {
  type: string;
  name: string;
};

export const WellItem = ({ name, type }: WellItemProps) => {
  const { palette } = useTheme();

  const renderIcon = () => {
    if (type === "oil") {
      return <CircleIcon fontSize="small" sx={{ color: palette.success.main }} />;
    }

    if (type === "gas") {
      return <WbSunnyOutlinedIcon fontSize="small" sx={{ color: palette.secondary.main }} />;
    }

    return <WarningIcon fontSize="small" sx={{ color: palette.secondary.main }} />;
  };
  return (
    <>
      {renderIcon()}
      <div className="dataset-name noselect">{name}</div>
    </>
  );
};
const DataSetRadioItem = ({ onClick, dataSet, setActiveDialog, currDataSet, checked, disableMenu = false, ...rest }: DataSetRadioItemProps) => {
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  return (
    <DataSetItem
      onClick={() => {
        onClick();
      }}
      onKeyDown={(event) => event.key === "Enter" && onClick()}
      key={dataSet}
      {...rest}
    >
      <Radio
        style={{
          visibility: "visible",
        }}
        checked={checked}
        onClick={() => {
          onClick();
        }}
        size="small"
        className="radio-well "
      />
      <WellItem type={currDataSet ? currDataSet.type : "none"} name={dataSet} />

      {!disableMenu && (
        <DataSetMoreButton>
          <IconButton
            aria-haspopup="true"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            aria-label="more"
          >
            <MoreHorizIcon className="more-icon" fontSize="medium" sx={{ color: palette.primary.main }} />
          </IconButton>
          <div>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
              <MenuItem
                onClick={() => {
                  setActiveDialog(DialogEnum.REMOVE_DATASET);

                  setAnchorEl(null);
                }}
                key={dictionary.nav.removeCurrentWell}
              >
                <DeleteIcon sx={{ color: palette.primary.main }} />
                <div
                  style={{
                    marginLeft: 5,
                  }}
                >
                  {dictionary.nav.removeCurrentWell}
                </div>
              </MenuItem>
            </Menu>
          </div>
        </DataSetMoreButton>
      )}
    </DataSetItem>
  );
};

export default DataSetRadioItem;
