import React from "react";

import { IDropdownOption } from "@fluentui/react";

import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import { useTheme } from "@mui/material/styles";

import FossilyticsDropdown from "@/components/fields/FossilyticsDropdown";

import dictionary from "@/constants/dictionary";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { camelToSnakeCase } from "@/utils/general";
import useThemeStyling from "@/utils/useThemeStyling";

import { InputLayout } from "./style";
import { LayerItem, TahkCsgAnalysisState } from "@/models/tahk";
import helpLinkUrl from "@/constants/helpLinkUrl";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

export type AnalysisParameterProps = {
  layerOption: IDropdownOption[];
  loadingState: boolean;
  selectedLayer: string | number | string[] | number[] | null | undefined;
  setSelectedLayer: (key: number) => void;
  currentLayer: {
    labelOrder: string[];
    data: {
      [key: string]: LayerItem;
    };
  } | null;
  onChangeAnalysisInput: (percentage: any, key: string) => void;
  analysis: TahkCsgAnalysisState | null;
  onChangeAnalysisInputLayer: (val: any, key: string, secondaryKey: keyof LayerItem) => void;
  onClickHistoryMatch: () => any;
  errorInputValidation: ErrorValidationDetail[];
  canClickHistoryMatch: boolean;
};

const AnalysisParameter = ({
  layerOption,
  loadingState,
  selectedLayer,
  setSelectedLayer,
  currentLayer,
  onChangeAnalysisInput,
  analysis,
  onChangeAnalysisInputLayer,
  onClickHistoryMatch,
  errorInputValidation,
  canClickHistoryMatch,
}: AnalysisParameterProps) => {
  const { elevatedCard } = useThemeStyling();
  const { palette } = useTheme();

  return (
    <div style={elevatedCard}>
      <h4
        style={{
          color: palette.primary.main,
        }}
      >
        {dictionary.tahk.historyMatch}
      </h4>

      {errorInputValidation?.map((err) => {
        return (
          <div style={{ color: "red" }} key={err.msg}>
            {err.msg}
          </div>
        );
      })}

      <FossilyticsDropdown
        label={dictionary.tahk.layer}
        options={layerOption}
        disabled={loadingState}
        selectedKey={selectedLayer}
        onChange={(v) => {
          setSelectedLayer(Number(v?.key));
        }}
      />

      {currentLayer && (
        <InputLayout>
          <div className="input-row input-row-header">
            <span>{dictionary.tahk.boundary}</span>
            <span className="text-center">{dictionary.tahk.units}</span>
            <span className="text-center">{dictionary.tahk.lower}</span>
            <span className="text-center">{dictionary.tahk.upper}</span>
            <span />
          </div>
          {currentLayer?.labelOrder.map((key) => {
            // user cannot change if value is null

            const currentData = currentLayer.data[camelToSnakeCase(key)];
            return (
              <div className="input-row" key={key}>
                <span className="boundary-label">
                  {dictionary.tahkParameterTable[key].text}
                  <sub>{dictionary.tahkParameterTable[key].sub}</sub> {dictionary.tahkParameterTable[key].suffix}
                </span>

                <span className="boundary-unit">{dictionary.tahkParameterTable[key].notation}</span>
                <FossilyticsNumberField
                  disabled={loadingState || !currentData?.selected_parameter}
                  value={currentData?.lower_boundary}
                  onChange={(v) => onChangeAnalysisInputLayer(v, camelToSnakeCase(key), "lower_boundary")}
                />
                <FossilyticsNumberField
                  disabled={loadingState || !currentData?.selected_parameter}
                  value={currentData?.upper_boundary}
                  onChange={(v) => onChangeAnalysisInputLayer(v, camelToSnakeCase(key), "upper_boundary")}
                />
                <Checkbox
                  onChange={(e) => {
                    onChangeAnalysisInputLayer(e.target.checked, camelToSnakeCase(key), "selected_parameter");
                  }}
                  checked={currentData?.selected_parameter}
                  disabled={loadingState || !currentData}
                  size="small"
                />
              </div>
            );
          })}
        </InputLayout>
      )}

      {/* bottom section */}

      <div>
        <div style={{ color: palette.primary.main, marginTop: 20 }}>{dictionary.tahk.historyMatchBasedOn}</div>
        <div className="slider-container">
          <span>{Math.round((analysis?.gamma ?? 0) * 100)}%</span>
          <label>{dictionary.tahk.waterData}</label>
          <Slider
            disabled={loadingState}
            aria-label="Volume"
            value={(analysis?.gamma ?? 0) * 100}
            onChange={(_, val) => {
              const percentage = Number(val) / 100;
              onChangeAnalysisInput(percentage, "gamma");
            }}
          />
          <span>{Math.round((1 - (analysis?.gamma ?? 0)) * 100)}%</span>
          <label>{dictionary.tahk.gasData}</label>
        </div>
        <div className="checkbox-container">
          {analysis && (
            <Checkbox
              checked={analysis?.selected_default_optimization_options}
              onChange={() => {
                onChangeAnalysisInput(!analysis?.selected_default_optimization_options, "selected_default_optimization_options");
              }}
              size="small"
              disabled={loadingState}
            />
          )}
          <label style={{ color: palette.primary.main }}>{dictionary.tahk.optimizationOptions}</label>
        </div>
        <FossilyticsNumberField
          label={dictionary.tahk.maxIterationNumber}
          disabled={loadingState || analysis?.selected_default_optimization_options}
          value={analysis?.maximum_iteration_number}
          onChange={(v) => onChangeAnalysisInput(v, "maximum_iteration_number")}
          min={1}
          helpUrl={helpLinkUrl.input.maxIterationNumber}
        />
        <FossilyticsNumberField
          label={dictionary.tahk.populationSize}
          disabled={loadingState || analysis?.selected_default_optimization_options}
          value={analysis?.population_size}
          onChange={(v) => onChangeAnalysisInput(v, "population_size")}
          helpUrl={helpLinkUrl.input.populationSize}
          min={1}
        />
        {!canClickHistoryMatch && (
          <div
            style={{
              color: palette.error.main,
              marginTop: 10,
            }}
          >
            {dictionary.errorMessage.historyMatchSelection}
          </div>
        )}

        <Button
          onClick={(e) => {
            e.preventDefault();
            onClickHistoryMatch();
          }}
          disabled={loadingState || !canClickHistoryMatch}
          style={{ color: "white", display: "flex", width: "100%", fontWeight: 600, marginTop: !canClickHistoryMatch ? 0 : 25 }}
          variant="contained"
          id={"history-match-button"}
        >
          {dictionary.tahk.historyMatch}
        </Button>
      </div>
    </div>
  );
};

export default AnalysisParameter;
