import styled from "@emotion/styled";

export const Container = styled.div<{}>`
  margin-left: 8px;
  .project-name {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;

    .name-text {
      padding-left: 8px;
    }

    .radio-container {
      .MuiRadio-sizeSmall {
        padding: 5px;
      }

      span {
        visibility: hidden;
      }

      span input[type="radio"]:checked + span {
        visibility: visible;
      }

      &:hover span {
        visibility: visible;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;

      .expanded {
        font-weight: 600;
      }
      .first-half {
        display: flex;
        align-items: center;
      }
    }
  }

  .project-name:hover {
    background-color: rgb(243, 242, 241);
  }

  .onDragBg {
    background-color: #fbdcb9;
  }
`;

export const PaddingContainer = styled.div<{ level: number }>`
  padding-left: ${(props) => props.level * 12}px !important;
`;
