import { spadDeclineAnalysisExport } from "@/constants/apiUrl";
import { Interval } from "@/models/Generic";

import { fetchApi } from "@/utils/apiFetcher";
import { SpadDeclineType, SpadDeclinePayload } from "../State";

type ExportSpadDecline = {
  body: SpadDeclinePayload;
  type: SpadDeclineType;
  interval: Interval;
  projectId: string;
};

export const postExportSpadDecline = ({ body, interval, type, projectId }: ExportSpadDecline) => {
  return fetchApi<Blob, SpadDeclinePayload>({
    path: spadDeclineAnalysisExport(type, projectId),
    body,
    type: "post",
    queryStringParameters: {
      interval,
    },
    responseType: "blob",
  });
};
