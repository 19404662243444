import { Dropdown, IDropdownOption, Stack } from "@fluentui/react";

import { GasViscosityMethod, WaterCompressibility, ZMethod } from "@/model";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { gasViscMethodOptions } from "@/modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/Components/ModuleKoldunMcsimGasOilInputGridTab/Components/model";
import { renderLabelWithHelp } from "@/features/help-link";

import { ModuleGazFmbPvtOptions } from "./model";
import { formationCompressibilityOptions } from "./constants";
import { THEORETICAL_HELP_LINKS } from "@/constants/helpLinkUrl";

interface ModuleGazFmbInputGridProps {
  value: ModuleGazFmbPvtOptions | undefined;
  onChange: (value: ModuleGazFmbPvtOptions) => void;
  handleInputNotChanged: () => void;
}

const waterCompressibilityOptions = [{ key: WaterCompressibility.BEGGS_BRILL, text: "Beggs/Brill" }] as IDropdownOption[];

const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
] as IDropdownOption[];

function ModuleGazFmbInputGrid({ value, onChange, handleInputNotChanged }: Readonly<ModuleGazFmbInputGridProps>) {
  if (!value) {
    return <></>;
  }

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <FossilyticsNumberField
          label="Initial pressure"
          suffix="psia"
          value={value.initial_pressure_psia}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  initial_pressure_psia: v,
                })
              : null;
          }}
        />
        <FossilyticsNumberField
          label="Formation temperature"
          suffix="°F"
          value={value.formation_temp_of}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  formation_temp_of: v,
                })
              : null;
          }}
        />
        <FossilyticsNumberField
          label="Net pay"
          suffix="ft"
          value={value.net_pay_ft}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  net_pay_ft: v,
                })
              : null;
          }}
        />
        <FossilyticsNumberField
          label="Wellbore radius"
          suffix="ft"
          value={value.wellbore_rad_ft}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  wellbore_rad_ft: v,
                })
              : null;
          }}
        />
      </Stack>

      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <FossilyticsNumberField
          label="Gas gravity"
          suffix="g"
          value={value.gas_gravity}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  gas_gravity: v,
                })
              : null;
          }}
        />
        <FossilyticsNumberField
          label="N₂"
          suffix="%"
          min={0}
          max={100}
          value={value.n2_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  n2_perc: v,
                })
              : null;
          }}
        />
        <FossilyticsNumberField
          label="CO₂"
          suffix="%"
          min={0}
          max={100}
          value={value.co2_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  co2_perc: v,
                })
              : null;
          }}
        />
        <FossilyticsNumberField
          label="H₂S"
          suffix="%"
          min={0}
          max={100}
          value={value.h2s_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  h2s_perc: v,
                })
              : null;
          }}
        />
      </Stack>

      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <FossilyticsNumberField
          label="Water saturation"
          suffix="%"
          min={0}
          max={100}
          value={value.water_sat_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  water_sat_perc: v,
                })
              : null;
          }}
        />
        <FossilyticsNumberField
          label="Porosity"
          suffix="%"
          min={0}
          max={100}
          value={value.porosity_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  porosity_perc: v,
                })
              : null;
          }}
        />

        <Dropdown
          label="Formation compressibility (psi⁻¹)"
          options={formationCompressibilityOptions}
          selectedKey={`${value.formation_compressibility_psi1}-${value.formation_rock_type}`}
          onChange={(_, v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  ...v.data,
                })
              : null;
          }}
        />
        <Dropdown
          label="Water compressibility (psi⁻¹)"
          options={waterCompressibilityOptions}
          selectedKey={value.water_compressibility_psi1}
          onChange={(_, v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  water_compressibility_psi1: v.key as WaterCompressibility,
                })
              : null;
          }}
        />
        <Dropdown
          label="Z Method"
          onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.gasDeviationFactor, props)}
          options={zMethodOptions}
          selectedKey={value.z_method}
          onChange={(_, v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  z_method: v.key as ZMethod,
                })
              : null;
          }}
        />
        <Dropdown
          label="Gas viscosity method"
          onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.gasViscosity, props)}
          options={gasViscMethodOptions}
          selectedKey={value.gas_viscosity_method}
          onChange={(_, v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  gas_viscosity_method: v.key as GasViscosityMethod,
                })
              : null;
          }}
        />
      </Stack>
    </Stack>
  );
}

export default ModuleGazFmbInputGrid;
