import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";

import { DataSet, ModuleId, Project, isDataSet } from "@/model";
import { FluidType, Interval } from "@/models/Generic";
import { ApiError } from "@/models/APIGeneric";
import { postInitializeRulonRollup } from "@/models/rulon/apiFetcher/initializeRollup";
import {
  EurSummary,
  RulonRollupForecast,
  RulonRollupScheme,
  RulonRollupState,
  RulonRollupValidation,
  postExportRulonRollup,
  rulonRollupForecastScheme,
} from "@/models/rulon";
import { postValidateRulonRollup } from "@/models/rulon/apiFetcher/validateRollup";

import dictionary from "@/constants/dictionary";
import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { moduleDictionary } from "@/components/Modules/constants";
import { formatToScientific } from "@/utils/general";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { postCalculateRulonRollup } from "@/models/rulon/apiFetcher/calculateRollup";
import { saveBlob } from "@/util";
import { parseErrorThrown } from "@/utils/errorHandling";

type UseRollupProps = {
  selectedDataSets: DataSet | DataSet[] | undefined;
  project?: Project;
  isLoading: boolean;
  setApiError: (error?: ApiError) => void;
  type: FluidType;
  apiError?: ApiError;
};

const rollupTableHeader = {
  data_set_id: dictionary.rulon.data_set_id,
  module: dictionary.rulon.module,
  output_calculation_time: dictionary.rulon.output_status,
};
const rollupSummaryTableHeader = ["data_set_id", "initial_production", "low", "mid", "high"];

const dropdownOption = [
  {
    value: ModuleId.SPAD_DECLINE_GAS,
    label: moduleDictionary[ModuleId.SPAD_DECLINE_GAS],
  },
  {
    value: ModuleId.SPAD_DECLINE_OIL,
    label: moduleDictionary[ModuleId.SPAD_DECLINE_OIL],
  },
];

const useRollup = ({ isLoading, selectedDataSets, setApiError, project, type, apiError }: UseRollupProps) => {
  const [rollupState, setRollupState] = useState<RulonRollupState>();
  const [internalLoading, setInternalLoading] = useState(false);

  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);
  const [activeDropdown, setActiveDropdown] = useState<number>(0);

  const [calculationForecast, setCalculationForecast] = useState<RulonRollupForecast>();
  const [errorState, setErrorState] = useState(false);
  const { palette } = useTheme();

  const client = useQueryClient();

  const notation = type === "oil" ? "STB" : "MMscf";

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  const { isLoading: isLoadingInitialize, isFetching } = useQuery({
    queryKey: ["initialize-rulon-rollup", dataSets, project, type, errorState],
    queryFn: async () => {
      return postInitializeRulonRollup(project?.id ?? "", type, dataSets);
    },
    select(data) {
      try {
        if (data?.data && !rollupState) {
          const parsed = RulonRollupScheme.parse(data.data);

          setRollupState(parsed);
          setLatestDataSets(dataSets);
        }
      } catch (error: any) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!project?.id && !!type && !errorState && !rollupState,
    // error handling
    throwOnError(error: any) {
      console.log(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
      setErrorState(true);
      return false;
    },
  });

  const loading = isLoadingInitialize || isFetching || isLoading || internalLoading;

  useEffect(() => {
    if (latestDataSets.length > 0 && !_.isEqual(latestDataSets, dataSets)) {
      setRollupState(undefined);
      client?.invalidateQueries();
    }
  }, [client, dataSets, latestDataSets]);

  const rollupColumns = Object.keys(rollupTableHeader).map((columnId, index) => {
    return { columnId: columnId, width: index === 0 ? 290 : 395 };
  });

  const rollupRows: Row<any>[] = useMemo(() => {
    const header = {
      rowId: "header",
      cells: Object.values(rollupTableHeader).map((header) => {
        return {
          type: "custom",
          text: header,
          style: tableHeaderStyle,
        };
      }),
      height: 50,
    };
    if (!rollupState) return [header];
    const modules = _.cloneDeep(rollupState.selection_validation);
    const headerKeys = Object.keys(rollupTableHeader);
    return [
      header,
      ...modules.map((layer, rowIndex) => {
        return {
          rowId: rowIndex,
          height: 30,
          cells: [
            ...headerKeys.map((header, index) => {
              let val = layer[header as keyof RulonRollupValidation];
              if (index === 1) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: dropdownOption,
                  style: tableCellStyle,
                  isOpen: activeDropdown === rowIndex + 1,
                  nonEditable: true,
                };
              }

              const textStyle = {
                ...tableCellStyle,
                color: "black",
              };

              // value format
              if (header === "output_calculation_time") {
                val = val ? `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}` : "-";
              }
              return {
                type: "text",
                text: val ?? "-",
                style: textStyle,
                nonEditable: loading || header !== "module",
              };
            }),
          ],
        };
      }),
    ];
  }, [activeDropdown, loading, rollupState]);

  const onChangeCell = useCallback(
    (changes: CellChange[]) => {
      if (!rollupState) return;
      const updatedRows = [...rollupState.selection_validation];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        if (type === "dropdown") {
          const newDropdown = dropDownNewCell.isOpen ? rowId + 1 : 0;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== activeDropdown) {
            setActiveDropdown(dropDownNewCell.isOpen ? rowId + 1 : 0);
          } else {
            setActiveDropdown(0);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            updatedRows[rowId].module = (dropDownNewCell.selectedValue as ModuleId) ?? "";
          }
        }
      }
      setRollupState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          input_validation: updatedRows,
        };
      });
    },
    [activeDropdown, rollupState]
  );

  const onClickValidate = useCallback(async () => {
    try {
      if (!project || !rollupState?.selection_validation) return;
      setInternalLoading(true);

      const res = await postValidateRulonRollup(project?.id, type, {
        selection: rollupState?.selection_validation.map((item) => ({ data_set_id: item.data_set_id, module: item.module })),
      });
      if (!_.isEqual(res.data, rollupState)) {
        const parsed = RulonRollupScheme.parse(res.data);
        setRollupState(parsed);
      }
    } catch (error: any) {
      console.log(error);

      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    } finally {
      setInternalLoading(false);
    }
  }, [apiError, project, rollupState, setApiError, type]);

  const summaryColumns = Object.keys(rollupSummaryTableHeader).map((columnId, index) => {
    return { columnId: columnId, width: index < 2 ? 150 : 100 };
  });

  const summaryRows: Row<any>[] = useMemo(() => {
    const header = [
      {
        rowId: "header",
        cells: [
          {
            type: "custom",
            text: "",
            style: { ...tableHeaderStyle, paddingTop: 30 },
          },
          {
            type: "custom",
            text: "",
            style: { ...tableHeaderStyle, paddingTop: 25 },
          },
          {
            type: "custom",
            text: dictionary.rulon.eur,
            style: { ...tableHeaderStyle, paddingTop: 15, border: "1px solid grey" },
            colspan: 3,
          },
        ],
        height: 30,
      },
      {
        rowId: "header",
        cells: [
          {
            type: "custom",
            text: dictionary.rulon.data_set_id,
            style: {
              ...tableHeaderStyle,
            },
          },
          {
            type: "custom",
            text: dictionary.rulon.initial_production,
            style: {
              ...tableHeaderStyle,
              border: "1px solid grey",
            },
          },
          {
            type: "custom",
            text: dictionary.rulon.low,
            style: {
              ...tableHeaderStyle,
              border: "1px solid grey",
            },
          },
          {
            type: "custom",
            text: dictionary.rulon.mid,
            style: {
              ...tableHeaderStyle,
              border: "1px solid grey",
            },
          },
          {
            type: "custom",
            text: dictionary.rulon.high,
            style: {
              ...tableHeaderStyle,
              border: "1px solid grey",
            },
          },
        ],
        height: 45,
      },
      {
        rowId: "notation",
        cells: [
          {
            type: "custom",
            text: "",
            style: tableHeaderStyle,
          },
          {
            type: "custom",
            text: notation,
            style: tableHeaderStyle,
          },
          {
            type: "custom",
            text: notation,
            style: tableHeaderStyle,
          },
          {
            type: "custom",
            text: notation,
            style: tableHeaderStyle,
          },
          {
            type: "custom",
            text: notation,
            style: tableHeaderStyle,
          },
        ],
        height: 30,
      },
    ];

    if (!rollupState) return [...header];
    const modules = _.cloneDeep(rollupState.eur_summary);

    return [
      ...header,
      ...modules.map((layer, rowIndex) => {
        return {
          rowId: rowIndex,
          height: 30,

          cells: [
            ...rollupSummaryTableHeader.map((header) => {
              let val = layer[header as keyof EurSummary];
              const textStyle = {
                ...tableCellStyle,
                color: "black",
              };

              return {
                type: "text",
                text: formatToScientific(val) ?? "-",
                style: textStyle,
                nonEditable: true,
              };
            }),
          ],
        };
      }),
    ];
  }, [notation, rollupState]);

  const summaryYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `(High-Low)/Mid`, type: "value", color: palette.customColor.black, min: 0, inverse: true }],
    [palette.customColor.black]
  );

  const summaryXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Np/Mid`, type: "value", color: palette.customColor.black, min: 0, position: "top" }],
    [palette.customColor.black]
  );

  const summarySeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!rollupState) return [];
    let flowRatesData: (string | number)[][] = rollupState.eur_summary.map((sum) => [sum.rose_plot_x, sum.rose_plot_y]);

    return [
      {
        name: "Rates",
        type: "scatter",
        color: palette.primary.main,
        z: 0,
        data: flowRatesData,
      },
    ];
  }, [palette.primary.main, rollupState]);

  const onClickCalculate = useCallback(
    async (interval: Interval) => {
      try {
        if (!project || !rollupState?.selection_validation) return;
        setInternalLoading(true);
        setErrorState(false);

        const res = await postCalculateRulonRollup(project?.id, type, {
          selection: rollupState?.selection_validation.map((item) => ({ data_set_id: item.data_set_id, module: item.module })),
          interval,
        });
        if (!_.isEqual(res.data, calculationForecast)) {
          const parsed = rulonRollupForecastScheme.parse(res.data);
          setCalculationForecast(parsed);
        }
      } catch (error: any) {
        console.log(error);
        setErrorState(true);

        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setInternalLoading(false);
      }
    },
    [apiError, calculationForecast, project, rollupState?.selection_validation, setApiError, type]
  );

  const haveRunCal = useRef(false);

  // run calculate on first load after the state is available
  useEffect(() => {
    if (!calculationForecast && !!rollupState && !haveRunCal.current) {
      onClickCalculate(Interval.monthly);
      haveRunCal.current = true;
    }
  }, [calculationForecast, onClickCalculate, rollupState, errorState]);

  const onClickExport = useCallback(
    async (interval: Interval) => {
      try {
        if (!project || !rollupState?.selection_validation) return;
        setInternalLoading(true);
        setErrorState(false);

        const res = await postExportRulonRollup(project?.id, type, {
          selection: rollupState?.selection_validation.map((item) => ({ data_set_id: item.data_set_id, module: item.module })),
          interval,
        });

        if (res.data) {
          const fileName = `${res.headers["content-disposition"].split("filename=")[1]}`;
          saveBlob(res.data, fileName);
        }
      } catch (error: any) {
        console.log(error);
        console.log(error);
        setErrorState(true);

        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setInternalLoading(false);
      }
    },
    [apiError, project, rollupState?.selection_validation, setApiError, type]
  );

  return {
    loading,
    onChangeCell,
    rollupColumns,
    rollupRows,
    onClickValidate,
    onClickCalculate,
    rollupState,
    summaryColumns,
    summaryRows,
    summaryYAxes,
    summaryXAxes,
    summarySeries,
    calculationForecast,
    onClickExport,
  };
};

export default useRollup;
