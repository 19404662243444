import { z, object, number, string } from "zod";
import { TahkCsgDataOption } from "./State";
import { TahkCsgInputState } from "./input";
import { TahkDataTableCurveScheme, TahkDataTableScheme } from "./generic";

export type TahkCsgForecastPayload = {
  options: {
    inputs: TahkCsgInputState;
    forecast: TahkCsgForecastState;
  };
} & TahkCsgDataOption;

export const forecastEventScheme = z.object({
  date: z.string().datetime({ local: true, offset: true }),
  pressure: z.number(),
  rate: z.number().nullable(),
});

export const tahkCsgForecastScheme = z.object({
  smart_fill: z.boolean(),
  flowing_schedule: forecastEventScheme.array(),
});

export type TahkCsgForecastState = z.infer<typeof tahkCsgForecastScheme>;

export type ForecastEvent = z.infer<typeof forecastEventScheme>;

// response

const TahkAnalysisCurveDataScheme = object({
  dates: string().array().nullable(),
  gas_rate: number().array().nullable(),
  water_rate: number().array().nullable(),
  cumulative_gas_production: number().array().nullable(),
  cumulative_water_production: number().array().nullable(),
  casing_pressure: number().array().nullable(),
  tubing_pressure: number().array().nullable(),
  bottomhole_pressure: number().array().nullable(),
});
export type TahkAnalysisCurveData = z.infer<typeof TahkAnalysisCurveDataScheme>;

const TahkAnalysisCurveModelScheme = object({
  dates: string().array(),
  gas_rate: number().array(),
  water_rate: number().array(),
  cumulative_gas_production: number().array(),
  cumulative_water_production: number().array(),
});

export type TahkAnalysisCurveModel = z.infer<typeof TahkAnalysisCurveModelScheme>;

export const TahkCsgForecastResponseScheme = object({
  tahk_analysis_curve_model: TahkAnalysisCurveModelScheme,
  tahk_analysis_curve_data: TahkAnalysisCurveDataScheme,
  data_table: TahkDataTableScheme.or(TahkDataTableCurveScheme).array(),
});

export type TahkCsgForecastResponse = z.infer<typeof TahkCsgForecastResponseScheme>;
