import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import useReservoirInput from "../../../hooks/input/useReservoirInput";
import { UseModelComponentInputProps } from "../../../hooks/KoldunCsgContext";

const ReservoirInputs = (props: UseModelComponentInputProps) => {
  const { onChangeReservoirInput, reservoirInputColumns, reservoirInputRows } = useReservoirInput(props);
  return (
    <div
      style={{
        padding: 10,
        marginTop: 20,
      }}
    >
      <ReactGridTable
        innerStyle={{
          display: "flex",
        }}
        viewWidth={"100%"}
        viewHeight={360}
        columns={reservoirInputColumns}
        rows={reservoirInputRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeReservoirInput}
      />
    </div>
  );
};

export default ReservoirInputs;
