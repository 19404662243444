import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import { UseModelComponentInputProps } from "../../../hooks/KoldunCsgContext";
import useWellInput from "../../../hooks/input/useWellInput";
import dictionary from "@/constants/dictionary";
import { calculateHeight } from "@/utils/cellRendering";

const WellInput = (props: UseModelComponentInputProps) => {
  const { inputColumns, inputRows, onChangeCells, onChangePowerLawSkin, powerLawColumns, powerLawRows, showPowerLawTable } = useWellInput(props);
  return (
    <div
      style={{
        padding: 10,
        display: "flex",
      }}
    >
      <ReactGridTable
        innerStyle={{
          marginTop: 20,
        }}
        viewWidth={950}
        viewHeight={360}
        columns={inputColumns}
        rows={inputRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeCells}
      />

      {showPowerLawTable && (
        <div style={{ width: 300, marginLeft: 20 }}>
          <h4 style={{ margin: 0 }}> {dictionary.tahk.powerLawSection}</h4>
          <div>{dictionary.koldunCsg.skinPowerLawTableError}</div>
          <ReactGridTable
            viewWidth={"100%"}
            viewHeight={calculateHeight(powerLawRows) + 50}
            columns={powerLawColumns}
            rows={powerLawRows}
            enableRangeSelection
            enableColumnSelection
            stickyTopRows={2}
            onCellsChanged={onChangePowerLawSkin}
          />
        </div>
      )}
    </div>
  );
};

export default WellInput;
