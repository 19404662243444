import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

import useThemeStyling from "@/utils/useThemeStyling";
import dictionary from "@/constants/dictionary";
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import { ContractRateEnum, FlowPressureTypeEnum, WellboreModelEnum } from "@/models/InputGeneric";

import DropdownField from "@/components/fields/DropdownField";
import { mapEnumToDropdown } from "@/utils/general";
import InputField from "@/components/fields/InputField";
import LoadingIndicator from "@/components/LoadingIndicator";

import useKoldunCsgForecastInput from "../../hooks/forecast/useKoldunCsgForecastInput";
import { useKoldunCsgState } from "../../hooks/KoldunCsgContext";

const ForecastInput = () => {
  const { state, onChangeForecast, loadingState, onClickCalculateForecast, setKoldunCsgState, forecastEndDate, validationError, setValidationError } =
    useKoldunCsgState();
  const { flowingPressureCol, forecastEventsRow, handleFlowingPressureCellChange } = useKoldunCsgForecastInput({ state, setKoldunCsgState });

  const { elevatedCard } = useThemeStyling();
  const { palette } = useTheme();

  const forecastTableError = useMemo(() => {
    return validationError.filter((err) => err.loc[3] === "forecast_events");
  }, [validationError]);

  return (
    <div
      style={{
        ...elevatedCard,
        width: 400,
        overflow: "auto",
      }}
    >
      <h4
        style={{
          color: palette.primary.main,
          marginBottom: 10,
        }}
      >
        {dictionary.koldunCsg.forecastOption}
      </h4>
      {state?.forecast ? (
        <>
          <InputField
            type="date"
            value={state?.forecast.production_start_date}
            label={dictionary.koldunCsg.production_start_date}
            debounceDelay={100}
            onChange={(val) => onChangeForecast("production_start_date", val)}
            errors={validationError}
            keyField="options.forecast.production_start_date"
          />
          <InputField type="date" disabled value={forecastEndDate} label={dictionary.koldunCsg.forecastEndDate} debounceDelay={100} />
          <DropdownField
            label={dictionary.koldunCsg.contractRateMode}
            options={mapEnumToDropdown(ContractRateEnum)}
            selectedKey={state?.forecast.contract_rate_mode}
            onChange={(val) => onChangeForecast("contract_rate_mode", val)}
            errors={validationError}
            keyField="options.forecast.contract_rate_mode"
          />

          <DropdownField
            label={dictionary.koldunCsg.selectedFlowPressureType}
            options={mapEnumToDropdown(FlowPressureTypeEnum)}
            selectedKey={state?.forecast.selected_flow_pressure_type}
            onChange={(val) => onChangeForecast("selected_flow_pressure_type", val)}
            errors={validationError}
            keyField="options.forecast.selected_flow_pressure_type"
          />
          <DropdownField
            label={dictionary.koldunCsg.wellboreModel}
            options={mapEnumToDropdown(WellboreModelEnum)}
            selectedKey={state?.forecast.selected_wellbore_model}
            onChange={(val) => onChangeForecast("selected_wellbore_model", val)}
            errors={validationError}
            keyField="options.forecast.selected_wellbore_model"
          />
          {state?.forecast?.selected_wellbore_model === WellboreModelEnum.PumpModel && (
            <>
              <InputField
                type="int"
                value={state?.forecast.pump_depth}
                label={dictionary.koldunCsg.pumpDepth}
                debounceDelay={100}
                onChange={(val) => onChangeForecast("pump_depth", val)}
                suffix="ft"
                errors={validationError}
                keyField="options.forecast.pump_depth"
              />
              <InputField
                type="int"
                value={state?.forecast.minimum_pump_head}
                label={dictionary.koldunCsg.minimumPumpHead}
                debounceDelay={100}
                onChange={(val) => onChangeForecast("minimum_pump_head", val)}
                suffix="ft"
                errors={validationError}
                keyField="options.forecast.minimum_pump_head"
              />
              <InputField
                type="int"
                value={state?.forecast.initial_liquid_level}
                label={dictionary.koldunCsg.initialLiquidLevel}
                debounceDelay={100}
                onChange={(val) => onChangeForecast("initial_liquid_level", val)}
                suffix="ft"
                errors={validationError}
                keyField="options.forecast.initial_liquid_level"
              />
              <InputField
                type="int"
                value={state?.forecast.liquid_draw_down_rate}
                label={dictionary.koldunCsg.liquidDrawDownRate}
                debounceDelay={100}
                onChange={(val) => onChangeForecast("liquid_draw_down_rate", val)}
                suffix="ft/month"
                errors={validationError}
                keyField="options.forecast.liquid_draw_down_rate"
              />
            </>
          )}

          {
            <div style={{ color: palette.error.main, marginTop: 20 }}>
              {forecastTableError.map((err) => {
                return (
                  <span key={err.msg}>
                    {dictionary.koldunCsg.forecastEventRow} {err.loc[4]}: {err.loc[err.loc.length - 1].replaceAll("_", " ")} {err.msg}
                    <br />
                  </span>
                );
              })}
            </div>
          }
          <ReactGridTable
            viewWidth={"100%"}
            viewHeight={400}
            columns={flowingPressureCol}
            rows={forecastEventsRow}
            enableRangeSelection
            enableColumnSelection
            stickyTopRows={1}
            onCellsChanged={(val) => {
              handleFlowingPressureCellChange(val);
              setValidationError([]);
            }}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              onClickCalculateForecast();
            }}
            style={{ color: "white", marginTop: 20, width: "100%" }}
            variant="contained"
            disabled={loadingState || validationError.length > 0}
          >
            {dictionary.koldunCsg.calculateForecast}
          </Button>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};
export default ForecastInput;
