import { z } from "zod";

export enum FlowPressureTypeEnum {
  TubingHeadPressure = "TubingHeadPressure",
  BottomHolePressure = "BottomHolePressure",
  CasingPressure = "CasingPressure",
}

export enum ContractRateEnum {
  WaterContractRate = "WaterContractRate",
  GasContractRate = "GasContractRate",
}

export enum FormationCompressibilityCorrelationEnum {
  NewmanSandstone = "NewmanSandstone",
  NewmanLimestone = "NewmanLimestone",
  Hall = "Hall",
  Constant = "Constant",
  DobryninLaurent = "DobryninLaurent",
  DobryninWang = "DobryninWang",
}

export enum ZCorrelation {
  DranchukPurvisRobinson = "DranchukPurvisRobinson",
  BeggsBrill = "BeggsBrill",
  Papay = "Papay",
  DranchukAbouKassem = "DranchukAbouKassem",
}

export const powerLawScheme = z.object({
  permeability_a: z.number().nullable(),
  permeability_b: z.number().nullable(),
  skin_a: z.number().nullable(),
  skin_b: z.number().nullable(),
});

export type PowerLaw = z.infer<typeof powerLawScheme>;

export enum ViscosityCorrelationEnum {
  GonzalesEakin = "GonzalesEakin",
  Londono = "Londono",
  Standing = "Standing",
}

export enum WaterCompressibilityCorrelation {
  BeggsBrill = "BeggsBrill",
  McCain = "McCain",
}

export enum WaterViscosityCorrelation {
  BeggsBrill = "BeggsBrill",
  McCain = "McCain",
  LouisianaStateUniversity = "LouisianaStateUniversity",
}

export enum SkinModelEnum {
  Constant = "Constant",
  PowerLaw = "PowerLaw",
}

//    RELATIVE PERMEABILITY
export enum RelativePermeabilityCorrelationEnum {
  Burdine = "Burdine",
  ModifiedBurdine = "ModifiedBurdine",
  Corey = "Corey",
}

const relativePermeabilityCoreyScheme = z.object({
  krg_max: z.number(),
  connate_water_saturation: z.number(),
  residual_gas_saturation: z.number(),
  krw_max: z.number(),
  ng: z.number(),
  nw: z.number(),
});

const relativePermeabilityBurdineScheme = z.object({
  krg_max: z.number(),
  connate_water_saturation: z.number(),
  lamb: z.number(),
  residual_gas_saturation: z.number(),
});

const relativePermeabilityModifiedBurdineScheme = z.object({
  krg_max: z.number(),
  connate_water_saturation: z.number(),
  residual_gas_saturation: z.number(),
  lamb: z.number(),
  n: z.number(),
});

export const relativePermeabilityScheme = z.object({
  selected_relative_permeability_correlation: z.nativeEnum(RelativePermeabilityCorrelationEnum),
  parameter: relativePermeabilityCoreyScheme.or(relativePermeabilityModifiedBurdineScheme).or(relativePermeabilityBurdineScheme),
});

export type RelativePermeability = z.infer<typeof relativePermeabilityScheme>;

// Geomechanics
export enum GeomechanicModelEnum {
  NoModel = "NoModel",
  PalmerMansoori = "PalmerMansoori",
  YilmazNur = "YilmazNur",
  BurgoyneShrivastava = "BurgoyneShrivastava",
}

const matrixShrinkagePalmerMansooriScheme = z.object({
  poissons_ratio: z.number(),
  grain_compressibility: z.number(),
  langmuir_volume_strain: z.number(),
  young_module: z.number(),
  shrinkage_exponent: z.number(),
  shrinkage_factor: z.number(),
});

const matrixShrinkageYilmazNurScheme = z.object({
  permeability_modulus: z.number(),
});

const matrixShrinkageBurgoyneShrivastavaScheme = z.object({
  shrinkage_factor: z.number(),
  maximum_productivity_multiplier: z.number(),
});

export const matrixShrinkageScheme = z.object({
  selected_geomechanic_model: z.nativeEnum(GeomechanicModelEnum),
  parameter: matrixShrinkagePalmerMansooriScheme.or(matrixShrinkageYilmazNurScheme).or(matrixShrinkageBurgoyneShrivastavaScheme).nullable(),
});

export type MatrixShrinkage = z.infer<typeof matrixShrinkageScheme>;

// Well input
export enum WellInputEnum {
  Vertical = "Vertical",
  VerticalFractured = "VerticalFractured",
  Horizontal = "Horizontal",
}

// wellbore
export enum WellboreModelEnum {
  NoModel = "NoModel",
  PumpModel = "PumpModel",
}
