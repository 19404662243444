import { initializeKoldunCsg } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { KoldunCsgApiState } from "../State";
import { ModuleIdentity } from "@/models/Generic";

export const postInitializeKoldunCsg = (module: ModuleIdentity) => {
  return fetchApi<KoldunCsgApiState, ModuleIdentity>({
    path: initializeKoldunCsg(module.project_id),
    body: module,
    type: "post",
  });
};
