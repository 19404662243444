import { dataviewCalculation } from "@/constants/apiUrl";
import { fetchApi } from "@/utils/apiFetcher";

import { DataviewPayload } from "..";
import { ChartResult } from "@/models/Generic";

export const postCalculateDataview = (projectId: string, moduleId: string, body: DataviewPayload) => {
  return fetchApi<ChartResult, DataviewPayload>({
    path: dataviewCalculation(projectId, moduleId),
    type: "post",
    body,
  });
};
