import dictionary from "@/constants/dictionary";
import { ApiError } from "@/models/APIGeneric";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

type ParseErrorThrownProps = {
  error: any;
  setApiError?: (error?: ApiError) => void;
  setValidationError?: (err: ErrorValidationDetail[]) => void;
  apiError?: ApiError;
};

export const parseErrorThrown = ({ error, setApiError, setValidationError, apiError }: ParseErrorThrownProps) => {
  if (error?.issues) {
    console.log(error.issues);
    const parseError = error?.issues?.map((issue: any) => issue.message).join(", ");
    if (apiError?.message !== parseError && error?.issues?.length > 0) {
      setApiError?.({
        message: parseError,
      });
    }
  } else if (error?.code === 422 && error?.detail?.length) {
    setValidationError?.(error.detail);
    console.log(error.detail);
    // API ERROR ON BELOW ONWARD
  } else if (error?.message || error?.detail) {
    setApiError?.({
      code: error?.code,
      message: !String(error?.message) ? error?.detail : error?.message,
      severity: error?.severity,
    });
  } else {
    setApiError?.({
      message: dictionary.serverError,
    });
  }
};
