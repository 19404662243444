import { z } from "zod";

export type SettingsKey = string;

type SettingsObject = {
  [setting in SettingsKey]?: any;
};

export type GroupItem = {
  project_id: string;
  group_id: string;
  data_set_ids: string[];
  module: string;
  data: SettingsObject;
  updated?: boolean;
};

type ProjectGroups = {
  [groupId: string]: GroupItem[];
};

type ProjectSetting = {
  forecast_start_date: string;
  forecast_end_date: string;
  [key: string]: string;
};

export type ProjectState = {
  project_setting: ProjectSetting;
  groups: ProjectGroups;
};

export type SettingStateV2 = {
  [projectId: string]: ProjectState;
};

export type AllWellSettingResponse = {
  [projectId: string]: {
    project_setting: ProjectSetting | null;
    groups: ProjectGroups;
  };
};

export type WellSettingLegacyProject = {
  project_id: string;
  group_id: string;
  data_set_ids: string;
  data: any;
};

export type SaveProjectSettingPayload = {
  project_id: string;
  data: {
    forecast_start_date: string | null;
    forecast_end_date: string | null;
  };
};

// permission

enum MenuActionEnum {
  file = "file",
  builtin = "builtin",
}

const permissionMenuScheme = z
  .object({
    id: z.string(),
    label: z.string().nullable(),
    api_url: z.string().nullable(),
    action: z.nativeEnum(MenuActionEnum).nullable().optional(),
  })
  .strict();

const permissionsScheme = z.record(z.string(), z.record(z.string(), permissionMenuScheme.array()));

export type NavPermissions = z.infer<typeof permissionsScheme>;
