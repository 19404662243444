import { FluidType } from "@/models/Generic";
import { SelectedForecastRollup } from "@/models/spad/decline";
import { Column } from "@silevis/reactgrid";
import _ from "lodash";

export const forecastDummy = {
  low: { initial_rate: 0, arps_exponent: 0, initial_decline_rate: 0, initial_day: 0 },
  mid: { initial_rate: 0, arps_exponent: 0, initial_decline_rate: 0, initial_day: 0 },
  high: { initial_rate: 0, arps_exponent: 0, initial_decline_rate: 0, initial_day: 0 },
};
export const analysisOptionDummy = {
  auto_update_smart_fit: false,
  auto_update_cluster_start_day: false,
  analysis_start_day: 0,
  operational_forecast: _.cloneDeep(forecastDummy),
  profile_forecast: _.cloneDeep(forecastDummy),
  economic_cutoff: 0.1,
  selected_forecast_rollup: SelectedForecastRollup.Operational,
};

export const dummyValidation = {
  general: [],
  operational_forecast: [],
  profile_forecast: [],
};

export const spadDeclineDefaultState = {
  hideSidebar: false,
  isLoading: false,
  dataSets: [],
  tabIndex: 0,
  setTabIndex: () => {},
  onChartDrag: (handleBar: any) => {},
  downloadExport: () => {},
  setParamsOps: () => {},
  setIsFromBeFlag: () => {},
  setParamsProfile: () => {},
  setSelectedDeclineTypePivotKey: () => {},
  setCsvData: () => {},
  setLocalLoading: () => {},

  setSpadDeclineState: () => {},
  onClickCalculate: () => {},
  onClickExport: () => {},
  validationError: dummyValidation,
  spadDeclineType: FluidType.gas,
};

export const spadReserveTableColumn = [
  { columnId: "case", width: 120 },
  { columnId: "actualQ", justifyContent: "center", width: 150 },
  { columnId: "qAtForecast", justifyContent: "center", width: 150 },
  { columnId: "eur", justifyContent: "center", width: 130 },
  { columnId: "remainingRecoverable", justifyContent: "center", width: 180 },
  { columnId: "ohip", justifyContent: "center", width: 190 },
] as Column[];

export const spadParameterResultTableColumn = [
  { columnId: "case", width: 120 },
  { columnId: "q", justifyContent: "center", width: 150 },
  { columnId: "Di", justifyContent: "center", width: 150 },
  { columnId: "b", justifyContent: "center", width: 130 },
  { columnId: "qx", justifyContent: "center", width: 180 },
  { columnId: "Dx", justifyContent: "center", width: 190 },
] as Column[];

export const tableTitleHeaderStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  fontWeight: "600",
};

export const tableHeaderStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  fontWeight: "600",
  border: {
    bottom: {
      width: "0px",
    },
  },
};
export const tableHeaderNotationStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  border: {
    top: {
      width: "0px",
    },
    bottom: {
      width: "3px",
    },
  },
};
export const tableRowStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
};
