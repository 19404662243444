import { spadDeclineAnalysisValidate } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { AnalysisOption, SpadDeclineType, SpadDeclinePayload } from "../State";

export const postValidateSpadDecline = (body: SpadDeclinePayload, type: SpadDeclineType, projectId: string) => {
  return fetchApi<AnalysisOption, SpadDeclinePayload>({
    path: spadDeclineAnalysisValidate(type, projectId),
    body,
    type: "post",
  });
};
