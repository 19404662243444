import { FossilyticsChartAxis, FossilyticsChartCustomDataZoom, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";

type UseCartesianRateProdProps = {
  unit: string;
  ratesCumSeries: FossilyticsChartSeries[];
};

const useCartesianRateProd = ({ unit, ratesCumSeries }: UseCartesianRateProdProps) => {
  const { palette } = useTheme();

  const ratesCumXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Cum. production (${unit})`, type: "value", color: palette.customColor.black, min: 0 }],
    [palette.customColor.black, unit]
  );

  const ratesCumYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Rates (${unit}/d)`, type: "value", color: palette.customColor.black, min: 0 }],
    [palette.customColor.black, unit]
  );
  const findLastValueInRateCumSeriesForqQ = useMemo(() => {
    const newLine = ratesCumSeries?.filter((line) => line.name === "Economic cutoff")[0]?.data;
    const rateCumSeriesLastPoint = ratesCumSeries?.filter((seriesItem) => seriesItem.name === "Flow rates")[0]?.data;

    let percentage = 100;
    if (newLine && newLine.length > 0 && rateCumSeriesLastPoint.length > 0) {
      const lastNumberInData = newLine[newLine.length - 1][0] as number;
      const flowRatesLastPoint = rateCumSeriesLastPoint[rateCumSeriesLastPoint.length - 1][0];
      if ((flowRatesLastPoint * 3) / lastNumberInData < 1) {
        percentage = Math.floor(((flowRatesLastPoint * 3) / lastNumberInData) * 100);
      }
    }
    return percentage;
  }, [ratesCumSeries]);

  const qQCustomZoom = useMemo<FossilyticsChartCustomDataZoom>(() => {
    return {
      customDataZoom: true,
      dataZoomPercentage: findLastValueInRateCumSeriesForqQ,
    };
  }, [findLastValueInRateCumSeriesForqQ]);

  return {
    ratesCumXAxes,
    qQCustomZoom,
    ratesCumYAxes,
  };
};

export default useCartesianRateProd;
