import React, { useCallback } from "react";
import styled from "@emotion/styled";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

import InputErrorWrapper, { InputErrorWrapperProps } from "../Error/InputErrorWrapper/InputErrorWrapper";
import FieldLabel, { FieldLabelProps } from "./FieldLabel";

const Container = styled.div<{ isHorizontal: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isHorizontal ? "row" : "column")};

  .MuiSelect-select {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .MuiFormHelperText-root {
    margin-left: 0px;
  }
`;

export type DropdownOption<T = any> = {
  key: string | number;

  id?: string;

  text: string;

  disabled?: boolean;

  //render this as header / sub group title
  isHeader?: boolean;
  /**
   * custom data if needed
   */
  data?: T;
};

type DropdownProps = {
  label: string;
  options: DropdownOption[];
  disabled?: boolean;
  selectedKey?: string;
  onChange: (value: string) => void;
  isHorizontal?: boolean;
  dataTestId?: string;
} & Omit<InputErrorWrapperProps, "children"> &
  FieldLabelProps;

const DropdownField = ({
  isHorizontal = false,
  label,
  onChange,
  options,
  selectedKey,
  disabled,
  errors,
  keyField,
  ariaLabel,
  calloutContent,
  helpUrl,
  dataTestId,
}: DropdownProps) => {
  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      return onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <InputErrorWrapper errors={errors} keyField={keyField}>
      {(errorValidation) => (
        <FormControl
          style={{
            width: "100%",
            marginTop: 10,
          }}
          disabled={disabled}
          error={!!errorValidation}
        >
          <Container isHorizontal={isHorizontal}>
            <FieldLabel
              width={isHorizontal ? "40%" : "unset"}
              label={label}
              ariaLabel={ariaLabel}
              calloutContent={calloutContent}
              helpUrl={helpUrl}
            />
            <Select
              error={!!errorValidation}
              size={"small"}
              disabled={disabled}
              labelId={label}
              id={label}
              value={selectedKey}
              sx={{
                width: isHorizontal ? "60%" : "unset",
              }}
              onChange={handleChange}
              data-testid={dataTestId}
            >
              {options.map((option) => {
                if (option.isHeader) {
                  return (
                    <ListSubheader key={option.key} style={{ fontWeight: "bold" }} color={"primary"}>
                      {option.text}
                    </ListSubheader>
                  );
                }
                return (
                  <MenuItem key={option.key} disabled={option.disabled} value={option.key}>
                    {option.text}
                  </MenuItem>
                );
              })}
            </Select>
            {errorValidation && <FormHelperText>{errorValidation}</FormHelperText>}
          </Container>
        </FormControl>
      )}
    </InputErrorWrapper>
  );
};

export default DropdownField;
