import { formatNumber } from "@/util";

import useThemeStyling from "@/utils/useThemeStyling";
import LoadingIndicator from "@/components/LoadingIndicator";

import { dataTableColumns } from "../../constants";
import DefinedParameters from "./DefinedParameters";
import ModelComponents from "./ModelComponents";

import useAutoRtaInputGridTab, { AutoRtaInputGridTabProps } from "../../hooks/rtaInputGrid/useAutoRtaInputGridTab";
import SummaryTable from "@/components/SummaryTable";

const AutoRtaInputGridContainer = (props: AutoRtaInputGridTabProps) => {
  const { inputGridValues, inputGridCalculate, loadInputGrid, updateInputGridValue } = useAutoRtaInputGridTab(props);
  const { elevatedCard, palette } = useThemeStyling();
  const loadingIndicator = props.isLoading || loadInputGrid;

  // don't display anything if no inputGridValues
  if (!inputGridValues && loadingIndicator) return <LoadingIndicator />;

  return (
    <div
      style={{
        padding: 20,
        overflow: "scroll",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <div style={{ ...elevatedCard }}>
          <h3 style={{ color: palette.primary.main, margin: 0 }}>Defined Parameters</h3>
          {loadingIndicator && !inputGridValues ? (
            <div className="center-flex" style={{ height: 350 }}>
              <LoadingIndicator />
            </div>
          ) : (
            inputGridValues && (
              <DefinedParameters
                isLoading={loadingIndicator}
                value={inputGridValues}
                onChange={(val, key) => {
                  updateInputGridValue(val, key, "defined_parameters");
                }}
                validationError={props.validationError}
              />
            )
          )}
        </div>
        <div style={{ ...elevatedCard, height: "fit-content" }}>
          <h3 style={{ color: palette.primary.main, margin: 0 }}>Summary</h3>
          {loadingIndicator && !inputGridCalculate ? (
            <div className="center-flex" style={{ height: 350 }}>
              <LoadingIndicator />
            </div>
          ) : (
            inputGridCalculate && (
              <SummaryTable
                rows={inputGridCalculate.summary_card.map((item: any, i) => {
                  return {
                    parameter: item.parameter,
                    value: formatNumber(item.value),
                    units: item.unit,
                  };
                })}
                headers={dataTableColumns}
              />
            )
          )}
        </div>
      </div>

      <div
        style={{
          ...elevatedCard,
        }}
      >
        <h3 style={{ color: palette.primary.main, margin: 0 }}>Model Components</h3>
        {loadingIndicator && !inputGridCalculate && !inputGridValues ? (
          <LoadingIndicator />
        ) : (
          inputGridCalculate &&
          inputGridValues && (
            <ModelComponents
              validationError={props.validationError}
              value={inputGridValues}
              calculateValue={inputGridCalculate}
              isLoading={loadingIndicator}
              updateInputGridValue={updateInputGridValue}
            />
          )
        )}
      </div>
    </div>
  );
};

export default AutoRtaInputGridContainer;
