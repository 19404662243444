import { API } from "aws-amplify";
import { GenericApiParam, CustomError, GenericApiResponse } from "./type";
import { APIName, calculateRollup, validateRollup } from "@/constants/apiUrl";
import { safeTimeStampToLS } from "@/utils/session";
import { ApiError } from "@/models/APIGeneric";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { parseErrorThrown } from "../errorHandling";
import { useRef } from "react";
import { captureException } from "../errorMonitoring";

let intervalTime = 500;

type PollResponse<Response> = {
  progress?: string;
  task_id?: string;
  task_status?: string;
  task_result?: Response | null;
  task_info?: {
    status: string;
    progress: number;
  } | null;
} & GenericApiResponse<Response>;

type PollParam<Request, QueryParams> = {
  withTaskInfo?: boolean;
  loadingBlockerText?: string;
  isBlocking?: boolean;
} & GenericApiParam<Request, QueryParams>;

type ProgressStatus = {
  progress?: number;
  pollStatus?: string;
};

type LoadingBlocker = {
  isBlocking?: boolean;
  loadingBlockerText?: string;
};

type ContinuePollParam = {
  path: string;
  taskId: string;
  withTaskInfo?: boolean;
  isBlocking?: boolean;
  body?: any;
};

type UsePollingProps = {
  setApiError: (error?: ApiError) => void;
  setLoadingState?: (val: boolean) => void;
  setLoadingBlocker?: (val: LoadingBlocker) => void;
  setErrorValidation?: (validation: ErrorValidationDetail[]) => void;
  setProgressStatus?: (val: ProgressStatus) => void;
  apiError?: ApiError;
};

export const usePolling = ({
  setApiError,
  setErrorValidation = () => {},
  setLoadingBlocker,
  setLoadingState,
  setProgressStatus,
  apiError,
}: UsePollingProps) => {
  const haveRetryTimes = useRef(0);

  const totalResult = useRef<string>("");
  const currentPage = useRef<number>(1);

  const continuePollRequest = <Response>({
    path,
    taskId,
    withTaskInfo,
    isBlocking = false,
    body,
  }: ContinuePollParam): Promise<PollResponse<Response>> => {
    safeTimeStampToLS();
    haveRetryTimes.current = 0;
    // reset all state when start
    setLoadingState?.(true);
    setErrorValidation?.([]);
    setApiError?.(undefined);
    setLoadingBlocker?.({
      isBlocking,
    });

    return new Promise((resolve, reject) => {
      const poll = async () => {
        try {
          const { data } = await API.get("afa", path + "/task_poll", {
            queryStringParameters: { task_id: taskId, page: currentPage.current },
            response: true,
          });
          if (data?.task_result) {
            const transformedResult = typeof data.task_result === "string" ? data.task_result : JSON.stringify(data.task_result);
            totalResult.current = totalResult.current + transformedResult;
            currentPage.current = data?.task_current_page + 1;
          }

          if (data.task_status === "SUCCESS" && data.task_current_page === data.task_total_page) {
            resolve(
              withTaskInfo
                ? {
                    ...data,
                    task_result: JSON.parse(totalResult.current),
                  }
                : JSON.parse(totalResult.current)
            );
            setLoadingState?.(false);
            setLoadingBlocker?.({
              isBlocking: false,
              loadingBlockerText: undefined,
            });
            return;
          } else if (data.task_status === "FAILURE" || data.task_status === "REVOKED") {
            reject(new Error(data));
            return;
          }
          // means it is either  "PENDING" || "STARTED" || "SUCCESS" but the total isn't fulfilled yet
          // When progress value is given (for long-term api)
          const isRollup = path === calculateRollup || path === validateRollup;
          const progress: ProgressStatus = {};

          let loadingBlockerText = "";
          if (isRollup) {
            const typedBody = body as { data_set_ids: string[] };
            const wells = typedBody?.data_set_ids?.length * (Number(data.task_info?.progress) / 100);
            loadingBlockerText = `${path === calculateRollup ? "Calculating" : "Validating"}: ${wells.toFixed()} of ${
              typedBody?.data_set_ids?.length
            } wells`;
          }
          setLoadingBlocker?.({ loadingBlockerText, isBlocking });
          if (data?.task_info?.progress) progress.progress = data.task_info.progress;
          if (data?.task_info?.status) progress.pollStatus = data.task_info.status;
          setProgressStatus?.(progress);

          poll();
        } catch (err: any) {
          captureException(err);
          if (haveRetryTimes.current < 4) {
            let nextIntervalTime = intervalTime * 2;
            if (nextIntervalTime > 10000) {
              // Max interval time is 10 seconds
              nextIntervalTime = 10000;
            }

            setTimeout(() => {
              haveRetryTimes.current = haveRetryTimes.current + 1;
              poll();
            }, intervalTime);

            intervalTime = nextIntervalTime;
          } else {
            const errorStructure = {
              code: err.response?.data?.code ?? err.response?.status,
              detail: err.response?.data?.detail,
              message: err.response?.data?.message,
              severity: err.response?.data?.severity,
            };
            captureException(errorStructure);
            parseErrorThrown({
              error: errorStructure,
              setApiError,
              setValidationError: setErrorValidation,
              apiError,
            });
            throw new CustomError(errorStructure);
          }
        }
      };

      poll();
    });
  };

  const createPoll = async <Response, Request = any, QueryParams = any>({
    path,
    body,
    config,
    queryStringParameters,
    responseType,
    withTaskInfo = true,
    loadingBlockerText = "",
    isBlocking,
  }: // will return PollResponse if withTaskInfo is true
  PollParam<Request, QueryParams>): Promise<PollResponse<Response>> => {
    try {
      // reset all state when start
      setLoadingState?.(true);
      setErrorValidation?.([]);
      safeTimeStampToLS();
      setApiError?.(undefined);
      setLoadingBlocker?.({
        loadingBlockerText,
        isBlocking,
      });
      // reset the result every time make a new poll request
      totalResult.current = "";
      haveRetryTimes.current = 0;
      currentPage.current = 1;
      const {
        data: { task_id },
      } = await API.post(APIName, path + "/task_start", {
        queryStringParameters,
        body,
        response: true,
        responseType,
        ...config,
      });

      return await continuePollRequest({
        path,
        taskId: task_id,
        isBlocking,
        withTaskInfo,
        body,
      });
    } catch (err: any) {
      const errorStructure = {
        code: err.response?.data?.code ?? err.response?.status,
        detail: err.response?.data?.detail,
        message: err.response?.data?.message,
        severity: err.response?.data?.severity,
      };
      captureException(errorStructure);
      parseErrorThrown({
        error: errorStructure,
        setApiError,
        setValidationError: setErrorValidation,
        apiError,
      });
      throw new CustomError(errorStructure);
    } finally {
      setLoadingState?.(false);
      if (isBlocking) {
        setLoadingBlocker?.({
          loadingBlockerText: undefined,
          isBlocking: false,
        });
      }
    }
  };

  return { createPoll, continuePollRequest };
};

export const createPoll = async <Response, Request = any, QueryParams = any>({
  path,
  body,
  config,
  queryStringParameters,
  responseType,
  withTaskInfo,
}: PollParam<Request, QueryParams>): Promise<PollResponse<Response>> => {
  try {
    safeTimeStampToLS();
    const {
      data: { task_id },
    } = await API.post(APIName, path + "/task_start", {
      queryStringParameters,
      body,
      response: true,
      responseType,
      ...config,
    });
    return new Promise((resolve, reject) => {
      const poll = async () => {
        try {
          const { data } = await API.get(APIName, path + "/task_poll", {
            queryStringParameters: { task_id },
            response: true,
          });
          if (data.task_status === "SUCCESS") {
            resolve({
              data: withTaskInfo ? data : data.task_result,
            });
            return;
          } else if (data.task_status === "FAILURE") {
            reject(data as Error);
            return;
          } else if (data.task_status === "PENDING" || data.task_status === "STARTED") {
            // When progress value is given (for long-term api)
            if (data.task_info?.progress) {
              resolve({ progress: data.task_info.progress });
            }

            let nextIntervalTime = intervalTime * 2;
            if (nextIntervalTime > 10000) {
              // Max interval time is 10 seconds
              nextIntervalTime = 10000;
            }

            setTimeout(() => {
              poll();
            }, intervalTime);

            intervalTime = nextIntervalTime;
          }
        } catch (error: any) {
          if (error.response)
            reject(
              new CustomError({
                code: error.response.data?.code ?? error.response.status,
                detail: error.response.data?.detail,
                message: error.response.data.message,
                severity: error.response.data.severity,
              })
            );

          // if something went wrong need to send this as default for displaying error
          reject(
            new CustomError({
              code: 0,
              message: "",
              severity: "error",
            })
          );
          return;
        }
      };

      poll();
    });
  } catch (err: any) {
    // indicate the error comes from BE
    if (err.response)
      throw new CustomError({
        code: err.response.data?.code ?? err.response.status,
        detail: err.response.data?.detail,
        message: err.response.data.message,
        severity: err.response.data.severity,
      });

    // if something went wrong need to send this as default for displaying error
    throw new CustomError({
      code: 0,
      message: "",
      severity: "error",
    });
  }
};
