import { useMemo, useState } from "react";

import { ModuleAutoRTAYaxisField, DimensionlessRateFieldName } from "../../model";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

import useThemeStyling from "@/utils/useThemeStyling";
import { AnalysisCalculation, DimensionlessRateArrays, DimensionlessTimeArrayModel } from "@/models/gaz/autoRta";
import { normalizedPressureXaxisOptions } from "../../constants";

type NormalizedRateProps = {
  analysisCalculation?: AnalysisCalculation;
};

const useAutoRtaAnalysisNormalizedRate = ({ analysisCalculation }: NormalizedRateProps) => {
  const { palette } = useThemeStyling();
  const [selectedXaxis, setSelectedXaxis] = useState<string>("decline_time");

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: normalizedPressureXaxisOptions.filter((opt) => opt.key === selectedXaxis)[0].text,
        type: "log",
        color: palette.customColor.black,
      },
    ],
    [palette, selectedXaxis]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "qD",
        type: "log",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );
  const moduleDimensionlessRateField = useMemo<{ [k in keyof DimensionlessRateArrays]?: ModuleAutoRTAYaxisField }>(
    () => ({
      normalized_rate: {
        name: "qD",
        yAxis: "qD",
        color: palette.customColor.red,
        defaultDisabled: true,
      },
      decline_rate: {
        name: "qDd",
        yAxis: "qDd",
        color: palette.customColor.blueMid,
        defaultDisabled: false,
      },
      decline_rate_integral: {
        name: "qDdi",
        yAxis: "qDdi",
        color: palette.customColor.green,
        defaultDisabled: false,
      },
      decline_rate_integral_derivative: {
        name: "qDdid",
        yAxis: "qDdid",
        color: palette.customColor.orangeLighter,
        defaultDisabled: false,
      },
    }),
    [palette]
  );

  const dotRateSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleDimensionlessRateField)
        .map((v) => v as keyof DimensionlessRateArrays)
        .map((f) => ({
          name: `${DimensionlessRateFieldName(f)}`,
          type: "scatter",
          symbolSize: 5,
          lineWidth: 4,
          color: moduleDimensionlessRateField[f]!.color,
          data: analysisCalculation?.transient_analysis_curves_data.dimensionless_rate_arrays
            ? analysisCalculation.transient_analysis_curves_data.dimensionless_time_arrays[selectedXaxis as keyof DimensionlessTimeArrayModel].map(
                (d, i) => [d, analysisCalculation.transient_analysis_curves_data.dimensionless_rate_arrays[f][i]]
              )
            : [],
          defaultDisabled: moduleDimensionlessRateField[f]?.defaultDisabled,
        })),
    [moduleDimensionlessRateField, analysisCalculation, selectedXaxis]
  );

  const lineRateSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleDimensionlessRateField)
        .map((v) => v as keyof DimensionlessRateArrays)
        .map((f) => ({
          name: `${DimensionlessRateFieldName(f)} Line`,
          type: "line",
          lineWidth: 2,
          symbolSize: 0.1,
          color: moduleDimensionlessRateField[f]!.color,
          data: analysisCalculation?.transient_analysis_curves_model.dimensionless_rate_arrays
            ? analysisCalculation.transient_analysis_curves_model.dimensionless_time_arrays[selectedXaxis as keyof DimensionlessTimeArrayModel].map(
                (d, i) => [d, analysisCalculation.transient_analysis_curves_model.dimensionless_rate_arrays[f][i]]
              )
            : [],
          defaultDisabled: moduleDimensionlessRateField[f]?.defaultDisabled,
        })),
    [moduleDimensionlessRateField, analysisCalculation, selectedXaxis]
  );

  const logRateFlowSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      ...dotRateSeries.map((dotItems, i) => ({
        ...dotItems,
      })),
      ...lineRateSeries.map((lineItems, i) => ({
        ...lineItems,
      })),
    ],
    [dotRateSeries, lineRateSeries]
  );

  return {
    id: "normalized_rate",
    xAxes,
    yAxes,
    series: logRateFlowSeries,
    onChangeDropdownOption: (val: string) => setSelectedXaxis(val),
    selectedOption: selectedXaxis,
  };
};

export default useAutoRtaAnalysisNormalizedRate;
