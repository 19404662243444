import dictionary from "@/constants/dictionary";
import ModelComponentInput from "./ModelComponentInput";
import MonteCarloInput from "./MonteCarlo/MonteCarloInput";
import useThemeStyling from "@/utils/useThemeStyling";

type KoldunCsgInputPageProps = {
  forecastEndDate?: string;
};
const KoldunCsgInputPage = ({ forecastEndDate }: KoldunCsgInputPageProps) => {
  const { palette } = useThemeStyling();
  return (
    <div
      style={{
        padding: 10,
      }}
    >
      {!forecastEndDate && <div style={{ color: palette.error.main, marginBottom: 10 }}>{dictionary.errorMessage.forecastEndDate}</div>}
      <ModelComponentInput />
      <MonteCarloInput />
    </div>
  );
};

export default KoldunCsgInputPage;
