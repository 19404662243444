import { IDropdownProps, Label, Stack, TooltipHost, Icon } from "@fluentui/react";

import { HelpLink, userManualDom } from "@/constants/helpLinkUrl";

import styles from "./help-link.module.css";

export const generateWikiLink = (helpLink: HelpLink) => {
  return `${userManualDom}${helpLink.code}`;
};

export const openHelpLink = (helpLink: HelpLink) => {
  window.open(generateWikiLink(helpLink), "_blank")?.focus();
};

export const renderLabelWithHelp = (helpLink: HelpLink, props?: IDropdownProps) => (
  <Stack className={styles.labelContainer} verticalAlign="center" horizontal>
    <TooltipHost content={helpLink.name}>
      <Label className={styles.linkLabel} onClick={() => openHelpLink(helpLink)}>
        {props?.label}{" "}
        <Icon
          iconName="info"
          styles={{
            root: {
              fontSize: 12,
            },
          }}
        />
      </Label>
    </TooltipHost>
  </Stack>
);
