import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { AutoRtaInputGrid, GasViscosityCorrelation } from "@/models/gaz/autoRta";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

import { mapEnumToDropdown } from "@/utils/general";
import { ZCorrelation } from "@/models/InputGeneric";

export type GasPvtInputFieldProps = {
  value: AutoRtaInputGrid;
  isLoading: boolean;
  updateInputGridValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
};

const GasPvtInputField = ({ value, isLoading, updateInputGridValue, validationError }: GasPvtInputFieldProps) => {
  const gasRecombination = value?.gas_pvt?.section_gas_recombination.use_gas_recombination;

  return (
    <div style={{ minWidth: 180, maxWidth: 297 }}>
      <InputField
        label="Specific gravity"
        suffix="dim"
        debounceDelay={1000}
        value={value.gas_pvt.specific_gravity}
        onChange={(v) => updateInputGridValue(v, "specific_gravity", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField={"inputs.gas_pvt.specific_gravity"}
      />
      <InputField
        label="N₂"
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={value.gas_pvt.nitrogen}
        onChange={(v) => updateInputGridValue(v, "nitrogen", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField={"inputs.gas_pvt.nitrogen"}
      />
      <InputField
        label="CO₂"
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={value.gas_pvt.carbon_dioxide}
        onChange={(v) => updateInputGridValue(v, "carbon_dioxide", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField={"inputs.gas_pvt.carbon_dioxide"}
      />
      <InputField
        label="H₂S"
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={value.gas_pvt.hydrogen_sulphide}
        onChange={(v) => updateInputGridValue(v, "hydrogen_sulphide", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField={"inputs.gas_pvt.hydrogen_sulphide"}
      />

      <DropdownField
        label="Gas viscosity correlation"
        options={mapEnumToDropdown(GasViscosityCorrelation)}
        selectedKey={value.gas_pvt.selected_gas_viscosity_correlation}
        onChange={(v) => updateInputGridValue(v, "selected_gas_viscosity_correlation", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField="inputs.gas_pvt.selected_gas_viscosity_correlation"
      />
      <DropdownField
        label="Z correlation"
        options={mapEnumToDropdown(ZCorrelation)}
        selectedKey={value.gas_pvt.selected_z_correlation}
        onChange={(v) => updateInputGridValue(v, "selected_z_correlation", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField="inputs.gas_pvt.selected_z_correlation"
      />

      <div style={{ marginTop: 30 }}>
        {value?.gas_pvt && (
          <FormControlLabel
            control={
              <Checkbox
                id="gas-recombination-autorta"
                onChange={(e) => {
                  updateInputGridValue(
                    {
                      ...value?.gas_pvt?.section_gas_recombination,
                      use_gas_recombination: e.target.checked,
                    },
                    "section_gas_recombination",
                    "gas_pvt"
                  );
                  updateInputGridValue(e.target.checked, "use_wellbore_model", "wellbore_model");
                }}
                defaultChecked
                size="small"
                checked={gasRecombination}
                disabled={isLoading}
              />
            }
            label="Gas Recombination"
          />
        )}

        {gasRecombination && value?.gas_pvt.section_gas_recombination && (
          <div>
            <div className="equal-size">
              <InputField
                label="Condensate Gas Ratio (CGR)"
                suffix="stb/MMscf"
                debounceDelay={1000}
                value={value.gas_pvt.section_gas_recombination.condensate_gas_ratio}
                onChange={(v) =>
                  updateInputGridValue(
                    { ...value.gas_pvt.section_gas_recombination, condensate_gas_ratio: v },
                    "section_gas_recombination",
                    "gas_pvt"
                  )
                }
                disabled={isLoading}
                errors={validationError}
                keyField={"inputs.gas_pvt.section_gas_recombination.condensate_gas_ratio"}
              />
              <InputField
                label="Gas gravity Separator"
                suffix="dim"
                debounceDelay={1000}
                value={value.gas_pvt.section_gas_recombination.specific_gravity_separator}
                disabled={isLoading}
                onChange={(v) =>
                  updateInputGridValue(
                    { ...value.gas_pvt.section_gas_recombination, specific_gravity_separator: v },
                    "section_gas_recombination",
                    "gas_pvt"
                  )
                }
                errors={validationError}
                keyField={"inputs.gas_pvt.section_gas_recombination.specific_gravity_separator"}
              />
              <InputField
                label="Condensate API"
                suffix="API"
                debounceDelay={1000}
                value={value.gas_pvt.section_gas_recombination.condensate_API}
                disabled={isLoading}
                onChange={(v) =>
                  updateInputGridValue({ ...value.gas_pvt.section_gas_recombination, condensate_API: v }, "section_gas_recombination", "gas_pvt")
                }
                errors={validationError}
                keyField={"inputs.gas_pvt.section_gas_recombination.specific_gravity_separator"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GasPvtInputField;
