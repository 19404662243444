import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import useTahkCsgRelativePermeability, { TahkCsgRelativePermeabilityProps } from "../../../hooks/input/charts/useTahkCsgRelativePermeability";
import FossilyticsChart from "@/components/FossilyticsChart";
import { calculateHeight } from "@/utils/cellRendering";

const RelativePermeability = (props: TahkCsgRelativePermeabilityProps) => {
  const { relativePermeabilityColumns, relativePermeabilityRows, onChangeRelativePermeabilityCell, yAxis, xAxis, series } =
    useTahkCsgRelativePermeability(props);

  const tableHeight = 500 + series.length * 5;
  const minHeight = calculateHeight(relativePermeabilityRows) + 135;

  return (
    <div style={{ marginTop: 20, flexDirection: "column" }}>
      <ReactGridTable
        innerStyle={{
          display: "flex",
        }}
        viewWidth={"100%"}
        viewHeight={minHeight < 400 ? minHeight : 400}
        columns={relativePermeabilityColumns}
        rows={relativePermeabilityRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeRelativePermeabilityCell}
      />
      <div
        style={{
          height: tableHeight,
        }}
        className="chartWrapper chartWidth"
      >
        <FossilyticsChart id="gas_pvt_cg" isLoading={props.loadingState} xAxes={xAxis} yAxes={yAxis} series={series} />
      </div>
    </div>
  );
};

export default RelativePermeability;
