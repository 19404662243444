import React, { createContext, useContext, useState, useMemo, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { ApiError } from "@/models/APIGeneric";
import { DataSet, ModuleId, Project, isDataSet } from "@/model";
import { AutoRtaInputGrid, InputGridCalculation, postInitializeAutoRta } from "@/models/gaz/autoRta";

import { autoRtaDefaultState } from "../constants";
import { FluidType } from "@/models/Generic";
import { autoRtaScheme, AutoRtaState } from "@/models/gaz/autoRta/State";
import { parseErrorThrown } from "@/utils/errorHandling";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

type AutoRtaStateProps = {
  autoRtaState: null | AutoRtaState;
  setAutoRtaState: React.Dispatch<React.SetStateAction<AutoRtaState | null>>;
  dataSet?: DataSet[];
  isLoading: boolean;
  setApiError: (err?: ApiError) => void;
  project?: Project;
  fluidType: FluidType;
  apiError?: ApiError;
  validationError: ErrorValidationDetail[];
  setValidationError: React.Dispatch<React.SetStateAction<ErrorValidationDetail[]>>;

  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;
};

const AutoRtaStateContext = createContext<AutoRtaStateProps>(autoRtaDefaultState);

export type ModelComponentsParamProps = {
  value: AutoRtaInputGrid;
  calculateValue: InputGridCalculation;
  isLoading: boolean;
  updateInputGridValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
};
type AutoRtaContextProps = {
  children: React.ReactNode;
  selectedDataSets?: DataSet | DataSet[];
  setApiError: (err?: ApiError) => void;
  project?: Project;
  currentModule?: ModuleId;
  isLoading: boolean;
  apiError?: ApiError;

  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;
};

function AutoRtaProvider({
  children,
  selectedDataSets,
  setApiError,
  project,
  isLoading: loadingState,
  currentModule,
  apiError,
  setProgress,
  setIsLoading,
  setPollStatus,
}: Readonly<AutoRtaContextProps>) {
  const [autoRtaState, setAutoRtaState] = useState<AutoRtaState | null>(null);
  const [validationError, setValidationError] = useState<ErrorValidationDetail[]>([]);

  const latestDataSet = useRef<DataSet[]>([]);

  const dataSet = useMemo(() => {
    if (!selectedDataSets) return [];
    return isDataSet(selectedDataSets) ? [selectedDataSets] : selectedDataSets;
  }, [selectedDataSets]);

  const client = useQueryClient();

  const fluidType = useMemo(() => {
    return currentModule === ModuleId.GAZ_AUTORTA ? FluidType.gas : FluidType.oil;
  }, [currentModule]);

  const { isLoading, isFetching } = useQuery({
    queryKey: ["initialize-gaz-auto-rta", dataSet],
    queryFn: async () => {
      return postInitializeAutoRta({
        data_set_ids: dataSet?.map((data) => data.id) ?? [],
        projectId: project?.id ?? "",
        type: fluidType,
      });
    },
    select(data) {
      try {
        if (data?.data && !autoRtaState) {
          const parsed = autoRtaScheme.parse(data.data);

          setAutoRtaState(parsed);
          latestDataSet.current = dataSet ?? [];
        }
      } catch (error) {
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedDataSets && !!project?.id,
  });

  useEffect(() => {
    if (latestDataSet.current.length > 0 && !_.isEqual(latestDataSet, dataSet)) {
      setAutoRtaState(null);
      client?.invalidateQueries();
    }
  }, [client, dataSet]);

  const state = useMemo(() => {
    return {
      autoRtaState,
      dataSet,
      setAutoRtaState,
      isLoading: isFetching || isLoading || loadingState,
      setApiError,
      project,
      fluidType,
      apiError,
      validationError,
      setValidationError,
      setIsLoading,
      setPollStatus,
      setProgress,
    };
  }, [
    dataSet,
    apiError,
    fluidType,
    autoRtaState,
    isLoading,
    isFetching,
    setApiError,
    project,
    loadingState,
    validationError,
    setValidationError,
    setIsLoading,
    setPollStatus,
    setProgress,
  ]);

  return <AutoRtaStateContext.Provider value={state}>{children}</AutoRtaStateContext.Provider>;
}

export default AutoRtaProvider;

export function useAutoRtaState() {
  return useContext(AutoRtaStateContext);
}
