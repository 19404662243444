import { Link } from "react-router-dom";
import { Text } from "@fluentui/react";
import useThemeStyling from "@/utils/useThemeStyling";

import "./ModuleLink.css";
import { ModuleSection } from "./constants";
import { isDevelopment } from "@/utils/general";

interface ModuleLinkProps {
  to: string;
  text: string;
  secondaryText: string;
  topText?: string;
  disabled: boolean;
  onClickModule: () => void;
}

function ModuleLink({ to, text, secondaryText, topText, disabled, onClickModule }: Readonly<ModuleLinkProps>) {
  const { palette } = useThemeStyling();

  let pathFill = disabled ? palette.customColor.neutralTertiary : palette.primary.main;

  return (
    <Link
      style={{
        // only show machine learning module in development
        visibility: !isDevelopment && text === ModuleSection.MACHINE_LEARNING ? "hidden" : "visible",
      }}
      id={`${text}-${secondaryText?.split(" ")?.join("-")}`}
      className={disabled ? "ModuleLink disabled" : "ModuleLink"}
      to={to}
      onClick={onClickModule}
      aria-disabled={disabled}
    >
      <div className="ModuleLink-hex-outer">
        <svg style={{ zIndex: -1 }} version="1.1" xmlns="http://www.w3.org/2000/svg" width="105" height="122" viewBox="0 0 173.20508075688772 200">
          <path fill={pathFill} d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"></path>
        </svg>
        <div className="ModuleLink-hex-inner" style={{ color: disabled ? palette.customColor.neutralTertiary : palette.primary.main }}>
          <Text styles={{ root: { color: "black", fontWeight: "bold" } }} variant="small" block nowrap>
            {topText}
          </Text>
          <Text styles={{ root: { color: "white", fontWeight: "bold" } }} variant="large" block nowrap>
            {text}
          </Text>
          <Text styles={{ root: { color: "white" } }} block nowrap>
            {secondaryText}
          </Text>
        </div>
      </div>
    </Link>
  );
}

export default ModuleLink;
