import React from "react";
import { shallow } from "zustand/shallow";

import ModulePage from "@/modules/ModulePage";
import { useAppStore } from "@/features/app";

import dictionary from "@/constants/dictionary";

import KoldunCsgProvider, { useKoldunCsgState } from "./hooks/KoldunCsgContext";
import KoldunCSgInputPage from "./components/Input";
import KoldunCSGForecastPage from "./components/Forecast";

const KoldunCsgPage = () => {
  const { onChangeTab, tabIndex, dataSets, loadingState, forecastEndDate } = useKoldunCsgState();
  return (
    <ModulePage
      title={dictionary.koldunCsg.title}
      onTabChange={onChangeTab}
      tabIndex={tabIndex}
      tabs={
        dataSets && [
          {
            headerText: "Input",
            itemIcon: "ScatterChart",
            disabled: loadingState,
            content: <KoldunCSgInputPage forecastEndDate={forecastEndDate} />,
            canSaveAsImg: true,
          },
          {
            headerText: "Forecast",
            itemIcon: "LineChart",
            disabled: loadingState || !forecastEndDate,
            content: <KoldunCSGForecastPage />,
            canSaveAsImg: true,
          },
        ]
      }
    />
  );
};

const KoldunCsgWrapper = () => {
  const {
    selectedDataSets,
    isLoading,
    group,
    setLoadingBlocker,
    currentUserTask,
    isLoadingBlocker,
    project,
    setApiError,
    apiError,
    setIsLoading,
    setProgress,
    setPollStatus,
    setLoadingBlockerText,
  } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      group: state.group,
      project: state.project,
      setApiError: state.setApiError,
      apiError: state.apiError,

      currentUserTask: state.currentUserTask,
      isLoadingBlocker: state.isLoadingBlocker,
      setLoadingBlocker: state.setLoadingBlocker,
      setIsLoading: state.setIsLoading,
      setProgress: state.setProgress,
      setPollStatus: state.setPollStatus,
      setLoadingBlockerText: state.setLoadingBlockerText,
    }),
    shallow
  );

  return (
    <KoldunCsgProvider
      apiError={apiError}
      group={group}
      project={project}
      setApiError={setApiError}
      isLoading={isLoading}
      selectedDataSets={selectedDataSets}
      currentUserTask={currentUserTask}
      isLoadingBlocker={isLoadingBlocker}
      setLoadingBlocker={setLoadingBlocker}
      setIsLoading={setIsLoading}
      setPollStatus={setPollStatus}
      setProgress={setProgress}
      setLoadingBlockerText={setLoadingBlockerText}
    >
      <KoldunCsgPage />
    </KoldunCsgProvider>
  );
};

export default KoldunCsgWrapper;
