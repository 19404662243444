import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

import { InputApiState, TahkCsgInputResponse, TahkCsgInputState, TahkCsgStateResponse, ValidateInputPayload } from "@/models/tahk";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { ModuleIdentity } from "@/models/Generic";
import { usePolling } from "@/utils/apiFetcher";
import { validateTahkCsgInput } from "@/constants/apiUrl";
import { PollHelper } from "../../context/TahkCsgContext";
import { parseErrorThrown } from "@/utils/errorHandling";
import { InputApiStateScheme } from "@/models/tahk/apiFetcher/input";

type TahkCsgInputProps = {
  tabIndex: number;
  isLoading: boolean;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  tahkCsgState?: TahkCsgStateResponse | null;
  analysisIdentity?: ModuleIdentity;
} & PollHelper;

const useTahkCsgInput = ({
  tabIndex,
  isLoading,
  setTahkCsgState,
  setApiError,
  tahkCsgState,
  analysisIdentity,
  setIsLoading,
  setPollStatus,
  setProgress,
  apiError,
}: TahkCsgInputProps) => {
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const [isUpdatedFromBE, setIsUpdatedFromBE] = useState(false);

  const lastInputRef = useRef<TahkCsgInputState>();
  const [calculationResult, setCalculationResult] = useState<TahkCsgInputResponse | null>();

  useEffect(() => {
    if (tahkCsgState?.inputs && !_.isEqual(tahkCsgState.inputs, lastInputRef.current)) {
      setErrorInputValidation([]);
      lastInputRef.current = { ...tahkCsgState?.inputs };
    }
  }, [tahkCsgState?.inputs]);

  const inputs = useMemo(() => {
    if (tahkCsgState?.inputs) {
      return tahkCsgState.inputs;
    }
    return undefined;
  }, [tahkCsgState?.inputs]);

  const analysis = useMemo(() => {
    return tahkCsgState?.analysis;
  }, [tahkCsgState?.analysis]);

  const { createPoll } = usePolling({
    setApiError,
    setErrorValidation: setErrorInputValidation,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    apiError,
  });

  const { isLoading: isLoadingValidation, isFetching: isFetchingValidation } = useQuery({
    queryKey: ["validate-input-tahk-csg", inputs, analysis, analysisIdentity],
    queryFn: async () => {
      if (inputs && analysis && analysisIdentity && tahkCsgState?.dataview) {
        // we can return immediately last state and not fetch to api
        if (isUpdatedFromBE) {
          setIsUpdatedFromBE(false);
          return {
            task_result: {
              inputs: {
                input_options: inputs,
                analysis_options: analysis,
              },
              calculation: calculationResult,
            },
          };
        }

        const payload = {
          options: {
            input_options: inputs,
            analysis_options: analysis,
          },
          data_options: {
            analysis_identity: analysisIdentity,
            dataview: tahkCsgState?.dataview,
          },
        };
        return createPoll<InputApiState, ValidateInputPayload>({
          path: validateTahkCsgInput(payload.data_options.analysis_identity.project_id),
          body: payload,
          type: "post",
        });
      }
      return {
        task_result: {
          inputs: {
            input_options: inputs,
            analysis_options: analysis,
          },
          calculation: calculationResult,
        },
      };
    },
    select(res: any) {
      try {
        const data = res?.task_result;
        if (data) {
          const parsed = InputApiStateScheme.parse(data);
          const inputData = parsed.inputs;
          const inputEqual = _.isEqual(inputs, inputData.input_options);
          const analysisEqual = _.isEqual(analysis, inputData.analysis_options);

          if (!analysisEqual || !inputEqual) {
            setTahkCsgState((prev) => {
              if (!prev) return prev;
              const newState = { ...prev };
              if (!analysisEqual) {
                newState.analysis = inputData.analysis_options ?? prev?.analysis;
              }
              if (!inputEqual) {
                newState.inputs = inputData.input_options ?? prev?.inputs;
              }
              return newState;
            });
            if (!inputEqual) setIsUpdatedFromBE(true);
          }
          if (!_.isEqual(parsed.calculation, calculationResult)) setCalculationResult(parsed.calculation);
        }
        return data;
      } catch (error) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
        });
      }
    },

    refetchOnWindowFocus: false,
    enabled: !!inputs && tabIndex === 1 && !!analysis && !!analysisIdentity && !!tahkCsgState?.dataview,
  });

  const loadingState = useMemo(() => {
    return isLoading || isFetchingValidation || isLoadingValidation;
  }, [isFetchingValidation, isLoading, isLoadingValidation]);

  const tahkCsgInputCalculation = useMemo(() => {
    return calculationResult;
  }, [calculationResult]);

  const layerError = useMemo(() => {
    if (errorInputValidation.length === 0) return [];
    return errorInputValidation.map((err) => err.msg);
  }, [errorInputValidation]);

  return {
    loadingState,
    errorInputValidation,
    tahkCsgInputCalculation,
    layerError,
  };
};

export default useTahkCsgInput;
