import { useMemo } from "react";
import { Row } from "@silevis/reactgrid";

import { analysisDataColumns, analysisTableHeader, dataTableHeaderStyles } from "../../constants";

import { AnalysisCalculation } from "@/models/gaz/autoRta";

const useAutoRtaAnalysisDataTable = (analysisCalculation?: AnalysisCalculation) => {
  const modelCurvesRows = useMemo<Row[]>(() => {
    if (!analysisCalculation?.transient_analysis_curves_model) return [];

    const headerRow: Row = {
      rowId: "header",
      cells: Object.values(analysisTableHeader).map((name) => ({
        type: "header",
        text: name,
        style: dataTableHeaderStyles,
      })),
    };

    const curvesModel = analysisCalculation.transient_analysis_curves_model;
    const pressureArrayKeys = curvesModel.dimensionless_pressure_arrays;
    const timeArrayKeys = curvesModel.dimensionless_time_arrays;
    const rateArrayKeys = curvesModel.dimensionless_rate_arrays;

    const allCurvesData: { [key: string]: any } = {
      ...pressureArrayKeys,
      ...timeArrayKeys,
      ...rateArrayKeys,
    };

    return [
      headerRow,
      ...analysisCalculation.transient_analysis_curves_model.dimensionless_pressure_arrays.beta_derivative.map((i: number, j) => ({
        rowId: j,
        cells: Object.keys(analysisTableHeader).map((key) => {
          const isExponent = Number(allCurvesData[key][j]) > 1000 || Number(allCurvesData[key][j]) < 0.01;
          const text = isExponent ? `${Number(allCurvesData[key][j]).toExponential(2)}` : Number(allCurvesData[key][j]).toPrecision(3);
          return {
            type: "text",
            text,
            nonEditable: true,
            hideZero: false,
            style: { display: "flex", justifyContent: "center", zIndex: 0 },
          };
        }),
      })),
    ] as Row[];
  }, [analysisCalculation?.transient_analysis_curves_model]);

  const dataTableRows = useMemo<Row[]>(() => {
    if (!analysisCalculation?.transient_analysis_curves_data) return [];

    const curvesData = analysisCalculation.transient_analysis_curves_data;
    const pressureArrayKeys = curvesData.dimensionless_pressure_arrays;
    const timeArrayKeys = curvesData.dimensionless_time_arrays;
    const rateArrayKeys = curvesData.dimensionless_rate_arrays;

    const allCurvesData: { [key: string]: any } = {
      ...pressureArrayKeys,
      ...timeArrayKeys,
      ...rateArrayKeys,
    };

    const headerRow: Row = {
      rowId: "header",
      cells: Object.values(analysisTableHeader).map((name) => ({
        type: "header",
        text: name,
        style: dataTableHeaderStyles,
      })),
    };

    return [
      headerRow,
      ...analysisCalculation.transient_analysis_curves_data.dimensionless_pressure_arrays.beta_derivative.map((i: number, j) => ({
        rowId: j,
        cells: Object.keys(analysisTableHeader).map((key) => {
          const isExponent = Number(allCurvesData[key][j]) > 1000 || Number(allCurvesData[key][j]) < 0.01;
          const text = isExponent ? `${Number(allCurvesData[key][j]).toExponential(2)}` : Number(allCurvesData[key][j]).toPrecision(3);

          return {
            type: "text",
            text,
            nonEditable: true,
            hideZero: false,
            style: { display: "flex", justifyContent: "center", zIndex: 0 },
          };
        }),
      })),
    ] as Row[];
  }, [analysisCalculation]);

  return {
    dataTableRows,
    columns: analysisDataColumns,
    modelCurvesRows,
  };
};

export default useAutoRtaAnalysisDataTable;
