import { useMemo } from "react";

import { Field } from "@/model";

import { ModuleDataViewField } from "../../../ModuleDataView";
import useThemeStyling from "@/utils/useThemeStyling";

const useModuleData = () => {
  const { palette } = useThemeStyling();
  const moduleFields = useMemo<{ [k in Field]?: ModuleDataViewField }>(
    () => ({
      [Field.WATER_RATE]: {
        name: "Water rate",
        yAxis: "Water Rate (bbl/day)",
        color: palette.customColor.blue,
      },
      [Field.OIL_RATE]: {
        name: "Oil rate",
        yAxis: "Oil Rate (bbl/day)",
        color: palette.customColor.green,
      },
    }),
    [palette]
  );
  return { moduleFields };
};

export default useModuleData;
