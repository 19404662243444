import { validateTahkCsgAnalysis } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { TahkCsgValidationState } from "../State";
import { TahkCsgAnalysisValidationRequestPayload } from "../analysis";

export const postValidateAnalysisTahkCsg = (payload: TahkCsgAnalysisValidationRequestPayload) => {
  return fetchApi<TahkCsgValidationState>({
    path: validateTahkCsgAnalysis(payload.data_options.analysis_identity.project_id),
    body: payload,
    type: "post",
  });
};
