import { useMemo } from "react";
import useTahkCsgGasPvt, { TahkCsgGasPvtProps } from "../../../hooks/input/charts/useTahkCsgGasPvt";

import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import Tabs from "@/components/Tabs";
import FossilyticsChart from "@/components/FossilyticsChart";
import CustomTable from "@/components/CustomTable";

import useLayeredChart, { Layer } from "../../../hooks/input/useLayeredChart";
import { gasPvtDataTableCols } from "../../../constants/grid";
import FossilyticsDropdown from "@/components/fields/FossilyticsDropdown";
import dictionary from "@/constants/dictionary";
import { calculateHeight } from "@/utils/cellRendering";

const GasPvt = (props: TahkCsgGasPvtProps) => {
  const { gasPvtColumns, gasPvtRows, onChangeGasCustomCell, gasPvtDataTableRows, selectedLayer, setSelectedLayer, layerOption } =
    useTahkCsgGasPvt(props);

  const {
    series: zSeries,
    xAxis: zXAxis,
    yAxis: zYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "compressibility_factor",
      labelKey: "compressibilityFactor",
    },

    chartType: "gas_pvt_charts",
  });

  const {
    series: viscositySeries,
    xAxis: viscosityXAxis,
    yAxis: viscosityYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "viscosity",
      labelKey: "viscosity",
    },

    chartType: "gas_pvt_charts",
  });

  const {
    series: densitySeries,
    xAxis: densityXAxis,
    yAxis: densityYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "density",
      labelKey: "density",
    },

    chartType: "gas_pvt_charts",
  });

  const {
    series: formationVolSeries,
    xAxis: formationVolXAxis,
    yAxis: formationVolYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "form_vol_factor",
      labelKey: "formulationVolumeFactor",
    },

    chartType: "gas_pvt_charts",
  });

  const {
    series: compressibilitySeries,
    xAxis: compressibilityXAxis,
    yAxis: compressibilityYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "compressibility",
      labelKey: "compressibility",
    },

    chartType: "gas_pvt_charts",
  });

  const tableHeight = 500 + layerOption.length * 5;
  const minHeight = calculateHeight(gasPvtRows) + 135;

  const tabList = useMemo(() => {
    return [
      {
        label: <span>Z</span>,
        key: "Z",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart id="gas_pvt_z" isLoading={props.loadingState} xAxes={zXAxis} yAxes={zYAxis} series={zSeries} />
          </div>
        ),
      },
      {
        label: (
          <span>
            μ<sub>{"g"}</sub>
          </span>
        ),
        key: "μg",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart id="gas_pvt_mg" isLoading={props.loadingState} xAxes={viscosityXAxis} yAxes={viscosityYAxis} series={viscositySeries} />
          </div>
        ),
      },
      {
        label: (
          <span>
            p<sub>{"g"}</sub>
          </span>
        ),
        key: "pg",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart id="gas_pvt_pg" isLoading={props.loadingState} xAxes={densityXAxis} yAxes={densityYAxis} series={densitySeries} />
          </div>
        ),
      },
      {
        label: (
          <span>
            B<sub>{"g"}</sub>
          </span>
        ),
        key: "Bg",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart
              id="gas_pvt_bg"
              isLoading={props.loadingState}
              xAxes={formationVolXAxis}
              yAxes={formationVolYAxis}
              series={formationVolSeries}
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            c<sub>{"g"}</sub>
          </span>
        ),
        key: "Cg",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart
              id="gas_pvt_cg"
              isLoading={props.loadingState}
              xAxes={compressibilityXAxis}
              yAxes={compressibilityYAxis}
              series={compressibilitySeries}
            />
          </div>
        ),
      },
      {
        label: <span>Data Table</span>,
        key: "Data table",
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="chartWrapper "
          >
            <FossilyticsDropdown
              label={dictionary.tahk.layer}
              options={layerOption}
              disabled={props.loadingState}
              selectedKey={selectedLayer}
              onChange={(v) => {
                setSelectedLayer(Number(v?.key));
              }}
            />
            <CustomTable
              style={{
                display: "flex",
              }}
              rows={gasPvtDataTableRows}
              columns={gasPvtDataTableCols}
              isLoading={props.loadingState}
            />
          </div>
        ),
      },
    ];
  }, [
    compressibilitySeries,
    compressibilityXAxis,
    compressibilityYAxis,
    densitySeries,
    densityXAxis,
    densityYAxis,
    formationVolSeries,
    formationVolXAxis,
    formationVolYAxis,
    gasPvtDataTableRows,
    layerOption,
    props.loadingState,
    selectedLayer,
    setSelectedLayer,
    viscositySeries,
    viscosityXAxis,
    viscosityYAxis,
    zSeries,
    zXAxis,
    zYAxis,
    tableHeight,
  ]);

  return (
    <div style={{ marginTop: 20, flexDirection: "column" }} className="center-flex">
      <ReactGridTable
        className="noScrollbarAll"
        innerStyle={{
          display: "flex",
          zIndex: 99,
        }}
        viewHeight={minHeight < 400 ? minHeight : 400}
        viewWidth={"100%"}
        columns={gasPvtColumns}
        rows={gasPvtRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeGasCustomCell}
      />

      <Tabs tabList={tabList} />
    </div>
  );
};

export default GasPvt;
