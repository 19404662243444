import { validateKoldunCsg } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { KoldunCsgInputState, KoldunCsgPayload } from "../State";

export const postValidateKoldunCsg = (payload: KoldunCsgPayload) => {
  return fetchApi<KoldunCsgInputState, KoldunCsgPayload>({
    path: validateKoldunCsg(payload.analysis_id.project_id),
    body: payload,
    type: "post",
  });
};
