import { useMemo } from "react";

import useThemeStyling from "@/utils/useThemeStyling";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { InputGridCalculation } from "@/models/gaz/autoRta";

const useAutoRtaLangmuirIsothermCharts = (calculateValue: InputGridCalculation) => {
  const { palette } = useThemeStyling();

  // Gas Content
  const langmuirIsothermGasContentXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette]
  );

  const langmuirIsothermGasContentYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "gas content (scf/ton)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );

  const langmuirIsothermGasContentSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `langmuirIsotherm gc`,
              type: "line",
              hideSymbol: true,
              color: palette.customColor.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.langmuir_charts.pressure.map((p, j) => [p, calculateValue.langmuir_charts.gas_content[j]]),
            },
          ]
        : [],
    [calculateValue, palette]
  );

  //cd
  const langmuirIsothermcdXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette]
  );

  const langmuirIsothermcdYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "gas content (scf/ton)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );

  const langmuirIsothermcdSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `langmuirIsotherm cd`,
              type: "line",
              hideSymbol: true,
              color: palette.customColor.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.langmuir_charts.pressure.map((p, j) => [p, calculateValue.langmuir_charts.desorption_compressibility[j]]),
            },
          ]
        : [],
    [calculateValue, palette]
  );

  return {
    langmuirIsothermGasContent: {
      xAxes: langmuirIsothermGasContentXAxes,
      yAxes: langmuirIsothermGasContentYAxes,
      series: langmuirIsothermGasContentSeries,
    },
    langmuirIsothermcd: {
      xAxes: langmuirIsothermcdXAxes,
      yAxes: langmuirIsothermcdYAxes,
      series: langmuirIsothermcdSeries,
    },
  };
};
export default useAutoRtaLangmuirIsothermCharts;
