/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Checkbox, Dropdown, IDropdownOption, Stack } from "@fluentui/react";

import { ModuleGazFmbPvtWellboreOptions } from "./model";
import { OilDensityMethod, SolGasMethod, WaterViscosityMethod } from "../../../model";
import FossilyticsNumberField from "../../../components/fields/FossilyticsNumberField";
import useThemeStyling from "@/utils/useThemeStyling";

interface ModuleGazFmbWellboreInputGridProps {
  value: ModuleGazFmbPvtWellboreOptions | undefined;
  onChange: (val: ModuleGazFmbPvtWellboreOptions | undefined) => void;
  enabled: boolean;
  setEnabled: (val: boolean) => void;
  handleInputNotChanged: () => void;
}

const oilDensityMethodOptions = [{ key: OilDensityMethod.EOS, text: "EOS" }] as IDropdownOption[];

const solGasMethodOptions = [
  { key: SolGasMethod.VASQUEZ_BEGGS, text: "Vasquez-Beggs" },
  { key: SolGasMethod.STANDING, text: "Standing" },
  { key: SolGasMethod.GLASO, text: "Glaso" },
  { key: SolGasMethod.LASATER, text: "Lasater" },
  { key: SolGasMethod.PETROSKY, text: "Petrosky" },
] as IDropdownOption[];

const wtrViscMethodOptions = [
  { key: WaterViscosityMethod.MCCAIN, text: "McCain" },
  { key: WaterViscosityMethod.LOUISIANA_STATE_UNI, text: "Louisiana State University" },
  { key: WaterViscosityMethod.BRILL_BEGGS, text: "Brill-Beggs" },
] as IDropdownOption[];

const ModuleGazFmbWellboreInputGrid = ({ value, onChange, enabled, setEnabled, handleInputNotChanged }: ModuleGazFmbWellboreInputGridProps) => {
  // Reset defaults when not selected
  useEffect(() => {
    if (value?.include_oil || !value) return;
    onChange({ ...value, oil_api_psia: 30, oil_pb_psia: 1000 } as ModuleGazFmbPvtWellboreOptions);
  }, [onChange, value?.include_oil]);

  useEffect(() => {
    if (value?.include_water || !value) return;
    onChange({ ...value, water_salinity_ppm: 0 } as ModuleGazFmbPvtWellboreOptions);
  }, [onChange, value?.include_water]);

  const { palette } = useThemeStyling();

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Checkbox
        label="Wellbore model"
        styles={{ text: { color: palette.primary.main } }}
        checked={enabled}
        onChange={(_, v) => {
          handleInputNotChanged();
          return setEnabled(!!v);
        }}
      />

      {enabled && value ? (
        <>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <Checkbox label="Include Oil PVT" checked={value.include_oil} onChange={(_, v) => onChange({ ...value, include_oil: !!v })} />
            </Stack>

            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <FossilyticsNumberField
                label="Oil API"
                suffix="psia"
                disabled={!value.include_oil}
                value={value.oil_api_psia}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        oil_api_psia: v,
                      })
                    : null
                }
              />
              <FossilyticsNumberField
                label="Oil Pb"
                suffix="psia"
                disabled={!value.include_oil}
                value={value.oil_pb_psia}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        oil_pb_psia: v,
                      })
                    : null
                }
              />
            </Stack>

            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <Dropdown
                label="Oil density method"
                options={oilDensityMethodOptions}
                selectedKey={value.oil_density_method}
                onChange={(_, v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        oil_density_method: v.key as OilDensityMethod,
                      })
                    : null
                }
              />
              <Dropdown
                label="Sol. gas method"
                options={solGasMethodOptions}
                selectedKey={value.sol_gas_method}
                onChange={(_, v) => {
                  onChange({
                    ...value,
                    sol_gas_method: v?.key as SolGasMethod,
                  });
                }}
              />
            </Stack>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <Checkbox label="Include Water PVT" checked={value.include_water} onChange={(_, v) => onChange({ ...value, include_water: !!v })} />
            </Stack>

            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <FossilyticsNumberField
                label="Water salinity"
                suffix="ppm"
                disabled={!value.include_water}
                value={value.water_salinity_ppm}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        water_salinity_ppm: v,
                      })
                    : null
                }
              />
            </Stack>

            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <Dropdown
                label="Water viscosity method"
                options={wtrViscMethodOptions}
                selectedKey={value.water_viscosity_method}
                onChange={(_, v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        water_viscosity_method: v.key as WaterViscosityMethod,
                      })
                    : null
                }
              />
            </Stack>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <FossilyticsNumberField
                label="Tubing diameter"
                suffix="in"
                value={value.tubing_diameter_in}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        tubing_diameter_in: v,
                      })
                    : null
                }
              />
              <FossilyticsNumberField
                label="Tubing roughness"
                suffix="in"
                value={value.tubing_roughness_in}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        tubing_roughness_in: v,
                      })
                    : null
                }
              />
              <FossilyticsNumberField
                label="Tubing depth"
                suffix="ft"
                value={value.tubing_depth_ft}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        tubing_depth_ft: v,
                      })
                    : null
                }
              />
            </Stack>

            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <FossilyticsNumberField
                label="Wellhead temperature"
                suffix="°F"
                value={value.wellhead_temp_of}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        wellhead_temp_of: v,
                      })
                    : null
                }
              />
              <FossilyticsNumberField
                label="Well angle"
                suffix="°"
                value={value.well_angle_deg}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        well_angle_deg: v,
                      })
                    : null
                }
              />
              <FossilyticsNumberField
                type="int"
                label="Number of increments"
                value={value.num_inc}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        num_inc: v,
                      })
                    : null
                }
              />
            </Stack>

            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}></Stack>
          </Stack>
        </>
      ) : undefined}
    </Stack>
  );
};

export default ModuleGazFmbWellboreInputGrid;
