import { initializeRulonRollup } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { RulonRollupState } from "../State";
import { FluidType } from "@/models/Generic";

export const postInitializeRulonRollup = (projectId: string, type: FluidType, data_set_ids: string[]) => {
  return fetchApi<RulonRollupState>({
    path: initializeRulonRollup(projectId, type),
    body: {
      data_set_ids,
    },
    type: "post",
  });
};
