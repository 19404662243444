import useAutoRtaAnalysis from "../../hooks/rtaAnalysis/useAutoRtaAnalysis";
import AnalysisSetting from "./AutoRtaAnalysisSetting";
import AnalysisCalculation from "./AnalysisCalculation";
import LoadingIndicator from "@/components/LoadingIndicator";

const AnalysisContainer = () => {
  const { analysisInputState, analysisCalculation, onChangeAnalysisInput, loadingState, onClickCalculateAnalysis } = useAutoRtaAnalysis();

  // don't display anything if no analysisInputState
  if (!analysisInputState && loadingState) return <LoadingIndicator />;

  return (
    <div style={{ height: "100%", display: "grid", gridTemplateColumns: "470px 1fr", gridGap: 20 }}>
      {analysisInputState && (
        <AnalysisSetting
          onChangeInput={onChangeAnalysisInput}
          analysisInput={analysisInputState}
          isLoading={loadingState}
          onClickCalculateAnalysis={onClickCalculateAnalysis}
          analysisSummaryCardParameter={analysisCalculation?.summary_card}
        />
      )}

      <AnalysisCalculation isLoading={loadingState} analysisCalculation={analysisCalculation} />
    </div>
  );
};

export default AnalysisContainer;
