import _ from "lodash";

import { InputType, KoldunMeasure } from "@/models/koldunV2";
import {
  defaultDependencyMatrixOgip,
  defaultDependencyMatrixVolumetric,
  defaultDistributionValue,
  defaultValueMap,
  ogipOnlyDistributionKey,
  volumetricOnlyDistributionKey,
} from "./constants";

export const generateNewMeasures = (measures: KoldunMeasure[], type: InputType) => {
  let mappedMeasures = _.cloneDeep(measures);
  mappedMeasures = mappedMeasures.map((measure: any) => {
    // KoldunMeasure: using any at the moment to resolve typescript
    const deleteEnumList = type === InputType.Ogip ? volumetricOnlyDistributionKey : ogipOnlyDistributionKey;
    const addEnumList = type === InputType.Ogip ? ogipOnlyDistributionKey : volumetricOnlyDistributionKey;

    deleteEnumList.forEach((key: string) => {
      delete measure[key as keyof KoldunMeasure];
    });

    addEnumList.forEach((key: string) => {
      measure[key as keyof KoldunMeasure] = {
        ...defaultDistributionValue,
        distribution_parameters: [defaultValueMap[key].Fixed],
      };
    });

    return {
      ...measure,
      dependency_matrix_values: type === InputType.Ogip ? defaultDependencyMatrixOgip : defaultDependencyMatrixVolumetric,
    };
  });

  return mappedMeasures;
};
