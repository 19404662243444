import React from "react";
import { shallow } from "zustand/shallow";

import ModulePage from "@/modules/ModulePage";

import { useAppStore } from "@/features/app";

import SpadDeclineProvider, { useSpadDeclineState } from "./hooks/SpadDeclineContext";

import SpadDeclineAnalysis from "./components/SpadDeclineAnalysisContainer";
import dictionary from "@/constants/dictionary";
import DataviewPage from "../../dataview";

const ModuleSpadDeclineContent = ({ title }: { title: string }) => {
  const { isLoading, dataSets, setTabIndex, tabIndex } = useSpadDeclineState();

  return (
    <ModulePage
      title={title}
      onTabChange={setTabIndex}
      tabIndex={tabIndex}
      tabs={
        dataSets && [
          {
            headerText: dictionary.dataView.title,
            itemIcon: "ScatterChart",
            disabled: isLoading,
            content: <DataviewPage />,
            canSaveAsImg: true,
          },
          {
            headerText: "Analysis",
            itemIcon: "LineChart",
            disabled: isLoading,
            content: <SpadDeclineAnalysis />,
            canSaveAsImg: true,
          },
        ]
      }
    />
  );
};

export const ModuleSpadDecline = ({ title }: { title: string }) => {
  const { selectedDataSets, currentModule, hideSidebar, project, isLoading, group, setCsvData, setApiError, apiError, dataSets, projects } =
    useAppStore(
      (state) => ({
        selectedDataSets: state.selectedDataSets,
        hideSidebar: state.hideSidebar,
        currentModule: state.currentModule,
        project: state.project,
        isLoading: state.isLoading,
        group: state.group,
        setCsvData: state.setCsvData,
        apiError: state.apiError,
        projects: state.projects,
        dataSets: state.dataSets,
        setApiError: state.setApiError,
      }),
      shallow
    );

  return (
    <SpadDeclineProvider
      isLoading={isLoading}
      hideSidebar={hideSidebar}
      currentModule={currentModule}
      selectedDataSets={selectedDataSets}
      group={group}
      project={project}
      setCsvData={setCsvData}
      apiError={apiError}
      projects={projects}
      dataSets={dataSets}
      setApiError={setApiError}
    >
      <ModuleSpadDeclineContent title={title} />
    </SpadDeclineProvider>
  );
};
