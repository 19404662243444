import { useEffect, useState, useMemo, useCallback } from "react";
import { IDropdownOption } from "@fluentui/react";

import { DataResponse, DataSet, isDataSet } from "../../../../model";
import { useSettingState } from "../../../../SettingsState";

import { FossilyticsChartAxis, FossilyticsChartSeries, FossilyticsChartLine } from "../../../../components/FossilyticsChart";
import { ModuleSpadForecastLines, ModuleSpadForecastLinesSeries, ModuleSpadWorPlots, ModuleSpadWorResults } from "../model";
import { FossilyticsGridColumn, FossilyticsGridColumnGroup } from "../../../../components/FossilyticsGrid";

import { getSpadWorAnalysis } from "@/constants/apiUrl";

// hooks
import useThemeStyling from "@/utils/useThemeStyling";

export enum AxisType {
  LINEAR = "value",
  LOG = "log",
}

export const axisTypeOptions = [
  { key: AxisType.LINEAR, text: "Linear" },
  { key: AxisType.LOG, text: "Log" },
] as IDropdownOption<AxisType>[];

type useSpadWorDataProps = {
  selectedDataSets?: DataSet | DataSet[];
  setCsvData: () => void;
  postRequest: (path: string, queryStringParameters?: { [k: string]: any }, body?: { [k: string]: any }, responseType?: string) => Promise<any>;
};

const useSpadWorData = ({ selectedDataSets, postRequest, setCsvData }: useSpadWorDataProps) => {
  const { palette } = useThemeStyling();
  const dataSet = useMemo<DataSet | undefined>(() => (isDataSet(selectedDataSets) ? selectedDataSets : undefined), [selectedDataSets]);

  const [cleaned, setCleaned] = useState<DataResponse>();
  const [currentTab, setCurrentTab] = useState<number>(0);

  // map selected analyses
  const [analyses, setAnalyses] = useSettingState<string[]>("spad_wor_analyses", false, { default: ["WOR"] });
  const [worAxisType, setWorAxisType] = useSettingState<AxisType>("spad_wor_wor_axis_type", false, {
    default: AxisType.LOG,
  });
  const [target, setTarget] = useSettingState<number>("spad_wor_target_cutoff", false);
  const validTarget = useMemo(() => target && target !== 0, [target]);

  // set below data on every api fetch
  const [plots, setPlots] = useState<ModuleSpadWorPlots>();
  const [forCastLines, setNewForCastLines] = useState<ModuleSpadForecastLines>();
  const [results, setResults] = useSettingState<ModuleSpadWorResults>("spad_wor_results", false);

  // clear all state if user change data set
  useEffect(() => {
    setAnalyses(["WOR"]);
    setPlots(undefined);
    setNewForCastLines(undefined);
    setResults(undefined);
  }, [dataSet, setAnalyses, setResults]);

  // clean data everytime selected data set change
  // to fetch new data input initialize
  useEffect(() => {
    setCleaned(undefined);
  }, [selectedDataSets]);

  // Each item in results maps to analyses
  useEffect(() => {
    if (!dataSet || !cleaned || currentTab !== 1) return;

    setPlots(undefined);
    setResults(undefined);
    setNewForCastLines(undefined);
    const fetchAllAnalysis = async () => {
      try {
        if (analyses && analyses.length > 0) {
          // fetch to api per analysis
          const response = await Promise.all(
            analyses.map((a) =>
              postRequest(
                getSpadWorAnalysis,
                {
                  data_set_id: dataSet.id,
                },
                {
                  analysis: a,
                  is_log: worAxisType === AxisType.LOG,
                  target: validTarget ? target! : 0.99,
                }
              )
            )
          );

          const newPlots: ModuleSpadWorPlots = {};
          const newResults: ModuleSpadWorResults = {};
          const newForCastLine: ModuleSpadForecastLines = {};

          response.forEach((r, i) => {
            if (r) {
              const { plot, rates, ...result } = r;
              newPlots[analyses[i]] = plot;
              newResults[analyses[i]] = result;
              newForCastLine[analyses[i]] = rates;
            }
          });

          setPlots(newPlots);
          setResults(newResults);
          setNewForCastLines(newForCastLine);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAllAnalysis();
  }, [analyses, cleaned, dataSet, postRequest, setResults, target, validTarget, worAxisType, currentTab]);

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [
      {
        name: "Cum. oil prod (bbl)",
        type: "value",
        color: palette.customColor.black,
        min: 0,
      },
    ];
  }, [palette]);

  const getCombinedYAxisName = (type: string) => {
    if (type === "WOR") return `WOR (bbl/bbl)`;
    if (type === "fo") return "fo";
    return "1/fw (bbl/bbl)";
  };
  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return !analyses
      ? []
      : analyses.map((a) => {
          let type = AxisType.LINEAR;
          if (a === "WOR") {
            if (worAxisType) {
              type = worAxisType;
            }
          }
          return {
            name: getCombinedYAxisName(a),
            type,
            color: palette.customColor.black,
            position: a === "WOR" ? "left" : "right",
            offset: a === "1/fw" ? 50 : 0,
            nameLocation: "end",
          };
        });
  }, [palette, analyses, worAxisType]);

  const newYAxes = useMemo<FossilyticsChartAxis[][]>(() => {
    let yAxesArr: FossilyticsChartAxis[][] = [];

    if (analyses) {
      analyses.forEach((analysisItem) => {
        let newArr: FossilyticsChartAxis[] = [];
        let type = AxisType.LINEAR;
        let waterOilType = AxisType.LINEAR;
        let min: number | undefined = 0;
        let waterOilMin = 0;

        if (analysisItem === "WOR") {
          if (worAxisType) {
            type = worAxisType;
          }
          min = worAxisType === AxisType.LINEAR ? 0 : undefined;
        }

        let yAxes = { name: `${analysisItem} (bbl/bbl)`, type, color: palette.customColor.black, min };

        let waterOilyAxes = {
          name: `Water/Oil Rate (bbl/bbl)`,
          type: waterOilType,
          color: palette.customColor.black,
          min: waterOilMin,
        };

        newArr.push(yAxes, waterOilyAxes);
        yAxesArr.push(newArr);
      });
    }
    return yAxesArr;
  }, [palette, analyses, worAxisType]);

  const forecastLineSeries = useMemo<ModuleSpadForecastLinesSeries[]>(() => {
    let arr: ModuleSpadForecastLinesSeries[] = [];

    if (analyses && forCastLines) {
      analyses.forEach((analysisItem) => {
        let forecastLineSeries: FossilyticsChartSeries[] = [];

        let temModuleSpadForecastLinesSeries: ModuleSpadForecastLinesSeries = {
          name: "",
          forecastLineSeriesItem: [],
        };

        let lowLines: FossilyticsChartSeries = {
          name: "forecast_low",
          type: "line",
          color: palette.customColor.red,
          hideSymbol: true,
          data: forCastLines[analysisItem]?.forecast_low ?? [],
          yAxisIndex: 1,
          lineWidth: 3,
        };

        let midLines: FossilyticsChartSeries = {
          name: "forecast_mid",
          type: "line",
          color: palette.customColor.blue,
          hideSymbol: true,
          data: forCastLines[analysisItem]?.forecast_mid ?? [],
          yAxisIndex: 1,
          lineWidth: 3,
        };

        let highLines: FossilyticsChartSeries = {
          name: "forecast_high",
          type: "line",
          color: palette.customColor.green,
          hideSymbol: true,
          data: forCastLines[analysisItem]?.forecast_high ?? [],
          yAxisIndex: 1,
          lineWidth: 3,
        };

        forecastLineSeries.push(lowLines, midLines, highLines);
        temModuleSpadForecastLinesSeries.name = analysisItem;
        temModuleSpadForecastLinesSeries.forecastLineSeriesItem = forecastLineSeries;

        arr.push(temModuleSpadForecastLinesSeries);
      });
    }

    return arr;
  }, [analyses, palette, forCastLines]);

  const series = useMemo<FossilyticsChartSeries[]>(() => {
    return !analyses
      ? []
      : analyses.map((a) => {
          let color = palette.primary.main;
          if (a === "WOR" || a === "1/fw") {
            color = palette.customColor.blue;
          } else if (a === "fo") {
            color = palette.customColor.green;
          }

          return {
            name: a,
            type: "scatter",
            color,
            data: plots?.[a] ?? [],
            yAxisIndex: 0,
          };
        });
  }, [analyses, palette, plots]);

  const waterOilSeries = useMemo<FossilyticsChartSeries[]>(() => {
    let seriesArr: FossilyticsChartSeries[] = [];
    let newWaterSeries: number[][] = [];
    let newOilSeries: number[][] = [];

    cleaned?.data.forEach((arr) => {
      let tempArr: number[] = [arr[5], arr[1]];
      newWaterSeries.push(tempArr);
    });

    cleaned?.data.forEach((arr) => {
      let tempArr: number[] = [arr[5], arr[2]];
      newOilSeries.push(tempArr);
    });

    let waterSeries = {
      name: "Water",
      type: "scatter",
      color: palette.customColor.blueLight,
      data: newWaterSeries,
      yAxisIndex: 1,
    };

    let oilSeries = {
      name: "Oil",
      type: "scatter",
      color: palette.customColor.greenLight,
      data: newOilSeries,
      yAxisIndex: 1,
    };

    seriesArr.push(waterSeries);
    seriesArr.push(oilSeries);

    return seriesArr;
  }, [palette, cleaned]);

  // Reserves table
  const reservesTableGroups = useMemo<FossilyticsGridColumnGroup[]>(
    () => [{ header: "", colspan: 1 }, ...(!analyses ? [] : analyses.map((a) => ({ header: a, colspan: 2 })))],
    [analyses]
  );

  const reservesTableCols = useMemo<FossilyticsGridColumn[]>(
    () => [
      { key: "case", type: "header", header: "Case" },
      ...(!analyses
        ? []
        : analyses.flatMap((a) => [
            { key: `eur-${a}`, editable: false, type: "number" as const, width: 80, header: `EUR (bbl)` },
            {
              key: `rem_rec-${a}`,
              editable: false,
              type: "number" as const,
              width: 160,
              header: `Rem. recoverable (bbl)`,
            },
          ])),
    ],
    [analyses]
  );

  const reservesTableData = useMemo<any[][]>(
    () => [
      ["Low", ...(results ? Object.values(results).flatMap((r) => [r?.eur[0], r?.remaining_recoverable[0]]) : [])],
      ["Mid", ...(results ? Object.values(results).flatMap((r) => [r?.eur[1], r?.remaining_recoverable[1]]) : [])],
      ["High", ...(results ? Object.values(results).flatMap((r) => [r?.eur[2], r?.remaining_recoverable[2]]) : [])],
    ],
    [results]
  );

  const combinedSeries = useMemo<FossilyticsChartSeries[]>(() => {
    return [
      ...(series.map((s, i) => ({ ...s, yAxisIndex: i })) ?? []),
      ...(validTarget
        ? analyses.flatMap((a, i) =>
            results?.[a]
              ? [
                  {
                    name: `${a} Low case`,
                    color: palette.customColor.blue,
                    type: "line",
                    hideSymbol: true,
                    data: validTarget ? results[a]!.line_low : [],
                    yAxisIndex: i > 0 ? 1 : 0,
                  },
                  {
                    name: `${a} Mid case`,
                    color: palette.customColor.green,
                    type: "line",
                    hideSymbol: true,
                    data: validTarget ? results[a]!.line_mid : [],
                    yAxisIndex: i > 0 ? 1 : 0,
                  } as FossilyticsChartSeries,
                  {
                    name: `${a} High case`,
                    color: palette.customColor.red,
                    type: "line",
                    hideSymbol: true,
                    data: validTarget ? results[a]!.line_high : [],
                    yAxisIndex: i > 0 ? 1 : 0,
                  },
                ]
              : []
          )
        : []),
    ];
  }, [analyses, results, series, palette, validTarget]);

  const combinedChartProps = useMemo(() => {
    return {
      xAxes,
      yAxes,
      series: [...combinedSeries],
    };
  }, [combinedSeries, xAxes, yAxes]);

  const mappedAnalyses = useMemo(() => {
    if (!analyses) return [];
    return analyses?.map((currentAnalysis, analysisIndex) => {
      const seriesItem = series[analysisIndex];
      const forecastLineSeriesItem = forecastLineSeries[analysisIndex];

      const lines: FossilyticsChartLine[] =
        validTarget && results && results[currentAnalysis]
          ? [
              { name: "Low case", color: palette.customColor.blue, line: results[currentAnalysis]!.line_low },
              { name: "Mid case", color: palette.customColor.green, line: results[currentAnalysis]!.line_mid },
              { name: "High case", color: palette.customColor.red, line: results[currentAnalysis]!.line_high },
            ]
          : [];

      const finalSeries = [seriesItem, ...waterOilSeries, ...(forecastLineSeriesItem?.forecastLineSeriesItem ?? [])];
      return {
        series: finalSeries,
        lines,
        xAxes,
        yAxes: newYAxes[analysisIndex],
        key: currentAnalysis,
      };
    });
  }, [
    analyses,
    forecastLineSeries,
    newYAxes,
    results,
    series,
    palette.customColor.blue,
    palette.customColor.green,
    palette.customColor.red,
    validTarget,
    waterOilSeries,
    xAxes,
  ]);

  const onClickTab = useCallback(
    (tabIndex: number) => {
      setCurrentTab(tabIndex);
      if (tabIndex !== 0) setCsvData();
    },
    [setCsvData]
  );

  return {
    dataSet,
    cleaned,
    analyses,
    validTarget,
    reservesTableGroups,
    reservesTableCols,
    reservesTableData,
    worAxisType,
    target,
    mappedAnalyses,
    combinedChartProps,
    currentTab,

    setAnalyses,
    setWorAxisType,
    setTarget,
    setCleaned,
    setCurrentTab: onClickTab,
  };
};

export default useSpadWorData;
