import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { Updater, useImmer } from "use-immer";
import { shallow } from "zustand/shallow";
import _ from "lodash";

import { useAppStore } from "./features/app";
import {
  ProjectState,
  SettingStateV2,
  SettingsKey,
  getWellsSettings,
  GroupItem,
  postSaveLegacyWellSettings,
  AllWellSettingResponse,
} from "./models/settings";
import { CustomError } from "./utils/apiFetcher";

const defaultSetting = {
  forecast_end_date: "",
  forecast_start_date: "",
};

const getProjectContainer = ({ state, projectId }: { state: SettingStateV2; projectId: string }): ProjectState => {
  let projectContainer: ProjectState = state[projectId];
  if (!projectContainer) {
    return {
      project_setting: defaultSetting,
      groups: {},
    };
  }

  if (!projectContainer.project_setting) {
    projectContainer.project_setting = defaultSetting;
  }

  return projectContainer;
};

const getSettingDataContainer = ({
  state,
  projectId,
  dataSetIds,
  groupId,
}: {
  state: SettingStateV2;
  projectId: string;
  dataSetIds: string[];
  groupId: string;
}): GroupItem => {
  let projectContainer: ProjectState = state[projectId];

  const res = projectContainer?.groups?.[groupId]?.find((c) => {
    return _.isEqual(dataSetIds, c.data_set_ids);
  });

  return (
    res ?? {
      project_id: projectId,
      group_id: groupId,
      data_set_ids: dataSetIds,
      module: "",
      data: {},
    }
  );
};

interface SettingsContextValue {
  settings: SettingStateV2;
  setSetting: ({
    key,
    projectId,
    value,
    dataSetIds,
    groupId,
    fromUpdate,
  }: {
    projectId: string;
    key: string;
    value: React.SetStateAction<any>;
    dataSetIds?: string[];
    groupId?: string;
    fromUpdate?: boolean;
  }) => void;
  setSettings: Updater<SettingStateV2>;
  settingsChangedAt?: Date;
  settingsSavedAt?: Date;
  loadAllProjects: () => Promise<void>;
}

const settingsContextDefaultValue: SettingsContextValue = {
  settings: {},
  setSetting: () => {},
  setSettings: () => {},
  loadAllProjects: () => new Promise((r) => r()),
};
const SettingsContext = React.createContext<SettingsContextValue>(settingsContextDefaultValue);

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { setApiError } = useAppStore(
    (state) => ({
      projects: state.projects,
      currentModule: state.currentModule,
      setApiError: state.setApiError,
    }),
    shallow
  );

  const [settings, setSettings] = useImmer<SettingStateV2>({});

  const baseSettingsRef = useRef<SettingStateV2>({});
  // this is to store well settings from db
  const databaseSetting = useRef<AllWellSettingResponse>({});

  const [contextValue, setContextValue] = useImmer<SettingsContextValue>({
    ...settingsContextDefaultValue,
    settings,
    setSettings: (arg) => {
      setSettings(arg);
    },
  });

  const loadAllProjects = useCallback(async () => {
    try {
      const allSettings = await getWellsSettings();
      if (allSettings.data) {
        const saveProject: SettingStateV2 = {};

        Object.keys(_.cloneDeep(allSettings.data)).forEach((projectId) => {
          if (allSettings.data?.[projectId]) {
            const currProject = _.cloneDeep(allSettings.data[projectId]);
            saveProject[projectId] = {
              ...currProject,
              project_setting: currProject.project_setting ?? defaultSetting,
            };
          }
        });
        setSettings(saveProject);
        databaseSetting.current = allSettings.data;
        baseSettingsRef.current = saveProject;
      }
    } catch (error) {
      setApiError(error as CustomError);
    }
  }, [setApiError, setSettings]);

  useEffect(() => {
    loadAllProjects().catch((e) => console.error(e));
  }, [loadAllProjects]);

  // Update settings state
  useEffect(() => {
    setContextValue((draft) => {
      draft.settings = settings;
    });
  }, [setContextValue, settings]);

  // auto save to backend when user change something
  const saveSettings = useCallback(
    async ({ data, dataSetId, groupId, projectId }: { projectId: string; groupId: string; dataSetId: string; data: any }) => {
      const groupList = databaseSetting.current[projectId].groups[groupId];
      const prevDataIndex = groupList?.findIndex((group) => _.isEqual(group.data_set_ids, [dataSetId])) ?? -1;
      if (prevDataIndex < 0) return;
      const prevData = groupList?.[prevDataIndex]?.data;
      if (prevData) {
        const newData = {
          ...prevData,
          ...data,
        };
        databaseSetting.current[projectId].groups[groupId][prevDataIndex].data = _.cloneDeep(newData);
        const payload = {
          data_set_ids: dataSetId,
          group_id: groupId,
          project_id: projectId,
          data: newData,
        };
        try {
          await postSaveLegacyWellSettings([payload]);
        } catch (error) {
          setApiError(error as CustomError);
        }
      }
    },
    [setApiError]
  );

  // setSetting function
  useEffect(() => {
    setContextValue((contextDraft) => {
      contextDraft.setSetting = ({ projectId, key, value, dataSetIds, groupId = "", fromUpdate }) => {
        // only save to backend if the changes is triggered by user
        if (fromUpdate && dataSetIds) {
          saveSettings({
            data: {
              [key]: value,
            },
            dataSetId: dataSetIds[0],
            projectId,
            groupId,
          });
        }
        setSettings((draft) => {
          if (dataSetIds) {
            let groupSettingContainer = draft[projectId]?.groups?.[groupId]?.find((c) => {
              return _.isEqual(dataSetIds, c.data_set_ids);
            });

            if (!groupSettingContainer) {
              groupSettingContainer = {
                project_id: projectId,
                group_id: groupId,
                data_set_ids: dataSetIds,
                module: "",
                data: {},
              };

              draft[projectId]?.groups?.[groupId]?.push(groupSettingContainer);
            }

            const newValue = value instanceof Function ? value(groupSettingContainer.data[key]) : value;
            groupSettingContainer.data[key] = newValue;

            return draft;
          }

          if (!draft[projectId]) {
            draft[projectId] = {
              project_setting: defaultSetting,
              groups: {},
            };
          }

          if (!draft[projectId].project_setting) {
            draft[projectId].project_setting = defaultSetting;
          }

          const newValue = value instanceof Function ? value(draft[projectId].project_setting[key]) : value;
          draft[projectId].project_setting[key] = newValue;

          return draft;
        });
      };
    });
  }, [saveSettings, setContextValue, setSettings]);

  return <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>;
};

type Dispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface UseSettingStateOptsWithDefault<T> {
  default: T;
}

type UseSettingStateOpts<T> = Partial<UseSettingStateOptsWithDefault<T>>;

export function useManualSettingState<T>({
  global,
  key,
  _dataSetIds,
  groupId,
  opts,
  projectId,
}: {
  key: string;
  global: boolean;
  opts?: UseSettingStateOptsWithDefault<T> | UseSettingStateOpts<T>;
  projectId?: string;
  groupId?: string;
  _dataSetIds?: string[];
}): [T | undefined, (val: T | undefined) => void] {
  const dataSetIds = useMemo(() => (global ? undefined : _dataSetIds), [global, _dataSetIds]);
  const projectIdRef = useRef(projectId);
  const groupIdRef = useRef(groupId);
  const dataSetIdsRef = useRef(dataSetIds);
  const globalRef = useRef(global);

  useEffect(() => {
    projectIdRef.current = projectId;
    dataSetIdsRef.current = dataSetIds;
    groupIdRef.current = groupId;
    globalRef.current = global;
  }, [dataSetIds, projectId, global, groupId]);

  const { settings, setSetting } = useSettings();

  const keyRef = useRef(key);
  const defaultRef = useRef(opts?.default);

  const value = useMemo(() => {
    if (!projectId || (!global && !dataSetIds)) return defaultRef.current;
    if (dataSetIds) {
      const project = getSettingDataContainer({
        state: settings,
        projectId,
        dataSetIds,
        groupId: groupId ?? "",
      })?.data[keyRef.current];
      return project ?? defaultRef.current;
    }
    return (
      getProjectContainer({
        state: settings,
        projectId,
      }).project_setting[keyRef.current] ?? defaultRef.current
    );
  }, [dataSetIds, global, groupId, projectId, settings]);

  const setValue = useCallback(
    (v: T | undefined) => {
      if (projectIdRef.current) {
        if (v instanceof Function) console.log("funccc");
        setSetting({
          projectId: projectIdRef.current,
          key: keyRef.current,
          value: v,
          dataSetIds: dataSetIdsRef.current,
          groupId: groupIdRef.current ?? "",
          fromUpdate: true,
        });
      }
    },
    [setSetting]
  );

  useEffect(() => {
    if (defaultRef.current) {
      if (!projectId || (!global && !dataSetIds) || !projectIdRef.current) {
        return;
      }
      if (dataSetIds) {
        const groupSettingContainer = getSettingDataContainer({
          state: settings,
          projectId,
          dataSetIds,
          groupId: groupId ?? "",
        });
        // initialise state
        if (Object.values(groupSettingContainer.data).length === 0) {
          setSetting({
            projectId: projectIdRef.current,
            key: keyRef.current,
            value: defaultRef.current,
            dataSetIds: dataSetIdsRef.current,
            groupId: groupIdRef.current ?? "",
            fromUpdate: false,
          });
          return;
        }
      }

      const projectSettingContainer = getProjectContainer({
        state: settings,
        projectId,
      });

      if (
        Object.values(projectSettingContainer.groups).length === 0 &&
        !projectSettingContainer.project_setting.forecast_end_date &&
        !projectSettingContainer.project_setting.forecast_start_date
      ) {
        setSetting({
          projectId: projectIdRef.current,
          key: keyRef.current,
          value: defaultRef.current,
          dataSetIds: dataSetIdsRef.current,
          groupId: groupIdRef.current ?? "",
          fromUpdate: false,
        });
        return;
      }
    }
  }, [dataSetIds, global, groupId, projectId, setSetting, settings]);

  return [value, setValue];
}

export function useSettingState<T>(key: SettingsKey, global: boolean): [T | undefined, (val: T | undefined) => void];
export function useSettingState<T>(key: SettingsKey, global: boolean, opts: UseSettingStateOptsWithDefault<T>): [T, Dispatch<T>];
export function useSettingState<T>(key: SettingsKey, global: boolean, opts: UseSettingStateOpts<T>): [T | undefined, (val: T | undefined) => void];
export function useSettingState<T>(
  key: SettingsKey,
  global: boolean,
  opts: UseSettingStateOpts<T> | UseSettingStateOptsWithDefault<T> = {}
): [T | undefined, (val: T | undefined) => void] {
  const { project, selectedDataSets, group } = useAppStore(
    (state) => ({
      project: state.project,
      selectedDataSets: state.selectedDataSets,
      group: state.group,
    }),
    shallow
  );

  const dataSetIds = useMemo(
    () => (!selectedDataSets || Array.isArray(selectedDataSets) ? selectedDataSets : [selectedDataSets])?.map((ds) => ds.id),
    [selectedDataSets]
  );
  // we need to set default group id because existing logic only set group if there is more than 1 group in a project
  return useManualSettingState({ key, global, opts, projectId: project?.id, _dataSetIds: dataSetIds, groupId: group?.id ?? project?.groups[0].id });
}
