import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useRef } from "react";
import { DirectionalHint, Pivot, PivotItem, TooltipHost } from "@fluentui/react";
import { shallow } from "zustand/shallow";
import styled from "@emotion/styled";

import Page from "../Page";
import { disabledHeaderButtonProps } from "../util";
import { DataSet, isDataSet, isDataSets } from "../model";
import { useAppStore } from "@/features/app";

import "./ModulePage.css";

interface ModulePageTab {
  headerText: string;
  itemIcon: string;
  disabled: boolean;
  disableHideSidebar?: boolean;
  content: JSX.Element;
  canSaveAsImg?: boolean;
}

interface ModulePageProps {
  title: string;
  tabs?: ModulePageTab[];
  tabIndex?: number;
  onTabChange?: (index: number) => void;
  // if true: ignore internal state
  isCustom?: boolean;
}

const StyledTitle = styled.span`
  font-weight: 600;
  font-size: 24px;
`;

function ModulePage({ title, tabs = [], tabIndex, onTabChange, isCustom = false }: Readonly<ModulePageProps>) {
  const navigate = useNavigate();

  const [selectedTabKey, setSelectedTabKey] = React.useState(0);

  const key = window.location.search;
  const queryTabIndex = new URLSearchParams(key).get("tab");

  const { currentModule, selectedDataSets, hideSidebar, setHideSidebar, setCanSaveAsImg, group, project } = useAppStore(
    (state) => ({
      currentModule: state.currentModule,
      selectedDataSets: state.selectedDataSets,
      hideSidebar: state.hideSidebar,
      group: state.group,
      project: state.project,
      setHideSidebar: state.setHideSidebar,
      setCanSaveAsImg: state.setCanSaveAsImg,
    }),
    shallow
  );

  const lastModule = useRef("");

  const haveUpdateLastModule = useRef(false);

  // check latest module to set active tab key
  // this is to retain which tab user prev access
  useEffect(() => {
    if (currentModule) {
      if (lastModule.current !== currentModule) {
        lastModule.current = currentModule;
        haveUpdateLastModule.current = false;
      }
      if (key && selectedTabKey !== Number(queryTabIndex) && !haveUpdateLastModule.current) {
        // prevent infinite loop
        haveUpdateLastModule.current = true;
        onTabChange?.(Number(queryTabIndex));
        setSelectedTabKey(Number(queryTabIndex));
      } else {
        haveUpdateLastModule.current = false;
      }
    }
  }, [currentModule, key, onTabChange, queryTabIndex, selectedTabKey]);

  useEffect(() => {
    setCanSaveAsImg(!!tabs[selectedTabKey]?.canSaveAsImg);
  }, [selectedTabKey, tabIndex, tabs, setCanSaveAsImg]);

  useEffect(() => {
    if (typeof tabIndex !== "number" || Number(tabIndex) === Number(selectedTabKey) || queryTabIndex) return;
    setSelectedTabKey(tabIndex);
  }, [selectedTabKey, tabIndex, queryTabIndex]);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      const key = item.props.itemKey!;
      if (key === "x") {
        setHideSidebar(false);
        navigate("..");
      } else if (key === "t") {
        setHideSidebar(!hideSidebar);
      } else if (key === "non-clickable") {
        // we use this for non interactable module name
        // no-op
      } else {
        const keyNum = parseInt(key);
        setHideSidebar(false);
        if (!isCustom) {
          setSelectedTabKey(keyNum);
          if (window.history.pushState) {
            const url = new URL(window.location.href);
            url.searchParams.set("tab", key);
            window.history.pushState(null, "", url.toString());
          }
        }
        onTabChange?.(keyNum);
      }
    }
  };

  const disableHideSidebar = useMemo(() => {
    return tabs && selectedTabKey in tabs && tabs[selectedTabKey].disableHideSidebar;
  }, [selectedTabKey, tabs]);

  const moduleNotSupported = useMemo(() => {
    return (
      currentModule &&
      (selectedDataSets === undefined ||
        (isDataSet(selectedDataSets) && selectedDataSets.modules_available.indexOf(currentModule) < 0) ||
        (isDataSets(selectedDataSets) && selectedDataSets.some((ds) => ds.modules_available.indexOf(currentModule) < 0)))
    );
  }, [currentModule, selectedDataSets]);

  useEffect(() => {
    // Navigate back to modules if selected data sets do not support current module
    if (moduleNotSupported && !window.location.href.includes("V2")) {
      navigate("..");
    }
  }, [navigate, moduleNotSupported]);

  const groupOrDataSetName = (selectedDataSets as DataSet[]) instanceof Array ? group?.name : selectedDataSets && (selectedDataSets as DataSet).name;

  if (moduleNotSupported) return <></>;

  return (
    <Page title={title}>
      <Pivot
        className="ModulePage"
        styles={{ root: { marginBlockStart: 10, borderBlockEnd: `1px solid rgb(243,242,241)` } }}
        selectedKey={String(selectedTabKey)}
        onLinkClick={handleLinkClick}
        id="moduleBaseSection"
      >
        <PivotItem
          itemKey="t"
          itemIcon={hideSidebar ? "ClosePane" : "ClosePaneMirrored"}
          headerButtonProps={disableHideSidebar ? disabledHeaderButtonProps : undefined}
          onRenderItemLink={(props, defaultRender) => (
            <TooltipHost content={hideSidebar ? "Show sidebar" : "Hide sidebar"} directionalHint={DirectionalHint.bottomAutoEdge}>
              {defaultRender?.(props)}
            </TooltipHost>
          )}
        />

        {/* todo in future:  make component is not rendered when user haven't access the tab */}
        {/* currently we can't do that, because we persist state between tabs */}

        {tabs.map((tab, i) => {
          return (
            <PivotItem
              className="noScrollbar"
              key={`${tab.headerText}-${i.toString()}`}
              itemKey={i.toString()}
              alwaysRender
              headerButtonProps={tab.disabled ? disabledHeaderButtonProps : undefined}
              itemIcon={tab.itemIcon}
              headerText={tab.headerText}
            >
              {tab.content}
            </PivotItem>
          );
        })}

        <PivotItem
          itemKey="non-clickable"
          alwaysRender
          key={"non-clickable"}
          headerButtonProps={{
            styles: { root: { float: "right", fontWeight: 600 } },
            className: "pivot-non-clickable",
            onRenderText: () => {
              return (
                <StyledTitle key={"custom-text-inner-nav"}>
                  {title} {project?.name}
                  {groupOrDataSetName && ` - ${groupOrDataSetName}`}{" "}
                </StyledTitle>
              );
            },
          }}
        />
      </Pivot>
    </Page>
  );
}

export default ModulePage;
