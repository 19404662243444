import { z, object, string, number } from "zod";

const mlSerie = object({
  label: string(),
  unit: string().nullable(),
  data: number().array(),
});

const chartData = object({
  dates: string().date().array(),
  series: mlSerie.array(),
});

export type ChartData = z.infer<typeof chartData>;
export const mlForecastScheme = object({
  forecast_result: chartData,
  analysed_data: chartData,
});

export type MLForecastState = z.infer<typeof mlForecastScheme>;
