export const defaultTahkCsgState = {
  tabIndex: 0,
  onChangeTab: () => {},
  setTahkCsgState: () => {},
  setApiError: () => {},
  setTahkCsgCalculation: () => {},
  setCsvData: () => {},
  dataSets: [],
  isLoading: false,
  tahkCsgState: null,

  setPollStatus: () => {},
  setProgress: () => {},

  setIsLoading: () => {},
  pollRequest: () => {},
};
