import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";

import dictionary from "@/constants/dictionary";
import { RulonRollupForecast, RulonRollupForecastData } from "@/models/rulon";
import { formatToScientific } from "@/utils/general";

import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

const forecastTableHeader = {
  date: dictionary.rulon.date,
  low: dictionary.rulon.low,
  mid: dictionary.rulon.mid,
  high: dictionary.rulon.high,
};

type UseForecastDataProps = {
  calculation?: RulonRollupForecast;
  fluidType: string;
  type: "rates" | "cumulative_production";
};

const useForecastData = ({ calculation, type, fluidType }: UseForecastDataProps) => {
  const { palette } = useTheme();
  const notation = fluidType === "oil" ? "STB/D" : "MMscf/D";

  const forecastColumns = Object.keys(forecastTableHeader).map((columnId, index) => {
    return { columnId: columnId, width: index === 0 ? 180 : 120 };
  });

  const forecastRow = useMemo(() => {
    const headerValue = Object.values(forecastTableHeader);
    const headerRow = [
      {
        rowId: "header",
        cells: headerValue.map((header) => {
          return {
            type: "custom",
            text: header,
            style: tableHeaderStyle,
          };
        }),
        height: 40,
      },
      {
        rowId: "header",
        cells: [
          { type: "custom", text: "", style: tableHeaderStyle },
          ...headerValue.slice(1, headerValue.length).map((header) => {
            return {
              type: "custom",
              text: notation,
              style: tableHeaderStyle,
            };
          }),
        ],
        height: 40,
      },
    ];
    if (!calculation) return [...headerRow];
    const row = _.cloneDeep(type === "rates" ? calculation.rates : calculation.cumulative_production);

    return [
      ...headerRow,
      ...calculation.dates.map((_, rowIndex) => {
        return {
          rowId: rowIndex,
          height: 30,

          cells: [
            ...Object.keys(forecastTableHeader).map((header, index) => {
              const textStyle = {
                ...tableCellStyle,
                color: "black",
              };

              if (index === 0) {
                return {
                  type: "date",
                  date: calculation.dates ? new Date(calculation.dates[rowIndex]) : undefined,
                  style: {
                    ...textStyle,
                  },
                  nonEditable: true,
                };
              }

              return {
                type: "text",
                text: formatToScientific(row[header as keyof RulonRollupForecastData][rowIndex]) ?? "-",
                style: textStyle,
                nonEditable: true,
              };
            }),
          ],
        };
      }),
    ];
  }, [calculation, notation, type]);

  const forecastYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: ``, type: "value", color: palette.customColor.black, min: 0 }],
    [palette.customColor.black]
  );

  const forecastXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Date`, type: "time", color: palette.customColor.black }],
    [palette.customColor.black]
  );

  const forecastSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!calculation) return [];

    return [
      {
        name: "Low",
        type: "line",
        color: palette.primary.main,
        lineWidth: 2,
        symbolSize: 1,
        data: calculation.dates.map((date, index) => [new Date(date), calculation[type].low[index]]),
      },
      {
        name: "Mid",
        type: "line",
        color: palette.grey[500],
        lineWidth: 2,
        symbolSize: 1,
        data: calculation.dates.map((date, index) => [new Date(date), calculation[type].mid[index]]),
      },
      {
        name: "High",
        type: "line",
        color: palette.error.main,
        lineWidth: 2,
        symbolSize: 1,
        data: calculation.dates.map((date, index) => [new Date(date), calculation[type].high[index]]),
      },
    ];
  }, [calculation, palette.primary.main, palette.grey, palette.error.main, type]);

  return { forecastColumns, forecastRow, forecastSeries, forecastXAxes, forecastYAxes };
};

export default useForecastData;
