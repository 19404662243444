import React, { useCallback, useMemo, useRef, useState } from "react";
import Tree from "rc-tree";

import Radio from "@mui/material/Radio";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";

import { DataSet, Project } from "@/model";
import { Container, PaddingContainer } from "./style";
import { DialogEnum, FlatenGroupProject, SelectItemProp } from "../../types";
import { transformProjectsToTreeNodes, TreeNode } from "./helper";
import DataSetRadioItem from "../DataSetRadioItem";

type DnDProjectTree = {
  projects: Project[];
  onDragEnd: (info: any) => void;
  dataSets?: DataSet[];
  onDragWellList: (payload: any) => void;
} & GenericDndProps;

type GenericDndProps = {
  setActiveDialog: (val: DialogEnum | undefined) => void;
  mappedItemKeyTotal: FlatenGroupProject;
  onSelectItem: (payload: SelectItemProp) => void;
  selectedKeys: string[];
};

type DndItemProps = {
  item: TreeNode;
  expandedKeys: string[];
  onExpand: (val: string, forceOpen?: boolean) => void;
} & GenericDndProps;

const DndItem = ({ item, expandedKeys, selectedKeys, onExpand, onSelectItem, setActiveDialog, mappedItemKeyTotal }: DndItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const paddingContainerRef = useRef<any>(null);

  const { palette } = useTheme();

  const isExpanded = expandedKeys.indexOf(item.key) !== -1;
  const isSelected = selectedKeys.indexOf(item.key) !== -1;

  const renderMenuList = useCallback(
    (level: number, canCreateSubGroup: boolean) => {
      if (level === 0) {
        return [
          {
            text: "New Groups",
            icon: <AddBoxIcon sx={{ color: palette.primary.main }} />,
            onClick: () => {
              setActiveDialog(DialogEnum.NEW_GROUP);
            },
          },
          {
            text: "Project Settings",
            icon: <SettingsIcon sx={{ color: palette.primary.main }} />,
            onClick: () => {
              setActiveDialog(DialogEnum.PROJECT_SETTING);
            },
          },
          {
            text: "Rename Project",
            icon: <EditIcon sx={{ color: palette.primary.main }} />,
            onClick: () => setActiveDialog(DialogEnum.EDIT_PROJECT),
          },
          {
            text: "Delete Project",
            icon: <DeleteIcon sx={{ color: palette.primary.main }} />,
            onClick: () => setActiveDialog(DialogEnum.DELETE_PROJECT),
          },
        ];
      }
      return [
        ...(canCreateSubGroup
          ? [
              {
                text: "New Subgroups",
                icon: <AddBoxIcon sx={{ color: palette.primary.main }} />,
                onClick: () => setActiveDialog(DialogEnum.NEW_GROUP),
              },
            ]
          : []),
        {
          text: "Rename Group",
          icon: <EditIcon sx={{ color: palette.primary.main }} />,
          onClick: () => setActiveDialog(DialogEnum.EDIT_GROUP),
        },
        {
          text: "Delete Group",
          icon: <DeleteIcon sx={{ color: palette.primary.main }} />,
          onClick: () => setActiveDialog(DialogEnum.DELETE_GROUP),
        },
      ];
    },
    [palette.primary.main, setActiveDialog]
  );

  if (item.type === "dataset") {
    return (
      <PaddingContainer level={item.level - 1}>
        <DataSetRadioItem
          id={item.key}
          key={item.key}
          onClick={() => {
            onSelectItem({
              type: item.type,
              id: item.parents[0],
              prevSelection: isSelected,
              groupId: item.parents,
              dataSetId: item.id,
              key: item.key,
              item,
            });
          }}
          dataSet={item.title}
          currDataSet={item.dataSet}
          setActiveDialog={setActiveDialog}
          checked={isSelected}
        />
      </PaddingContainer>
    );
  }

  return (
    <PaddingContainer
      ref={paddingContainerRef}
      // highlight drag over project/ group so user know which one to drag into
      onDrop={() => {
        paddingContainerRef.current.className = "project-name";
      }}
      onDragOver={() => {
        paddingContainerRef.current.className = "onDragBg project-name";
      }}
      onDragLeave={(event) => {
        paddingContainerRef.current.className = "project-name";
      }}
      level={item.level - 1}
      className="project-name"
    >
      <div id={item.key} className="radio-container">
        <div style={{ display: "flex", width: "100%" }} className="first-half">
          <Radio
            style={{
              visibility: "visible",
            }}
            size="small"
            checked={isSelected}
            onClick={(e) => {
              e.preventDefault();

              if (!isExpanded) onExpand(item.id);
              onSelectItem({
                type: item.type,
                id: item.id,
                prevSelection: isSelected,
                key: item.key,
                item,
              });
            }}
            key={item.title + isSelected}
          />
          <div
            onClick={() => {
              onExpand(item.id);
            }}
            className={"collapsible-btn-"}
            onKeyDown={(event) => {
              if (event.key === "Enter") onExpand(item.id);
            }}
            style={{ display: "flex", width: "100%" }}
          >
            {isExpanded ? <ExpandMoreIcon sx={{ color: "rgb(133, 133, 133)" }} /> : <ChevronRightIcon sx={{ color: "rgb(133, 133, 133)" }} />}
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
              className={`name-text ${isExpanded ? "expanded" : "collapsed"}`}
            >
              {item.title} ({mappedItemKeyTotal?.[item.id]?.total ?? 0})
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <IconButton
            aria-haspopup="true"
            onClick={(event) => {
              event.preventDefault();
              onExpand(item.id, true);
              setAnchorEl(event.currentTarget);
              onSelectItem({
                type: item.type,
                id: item.id,
                prevSelection: false,
                key: item.key,
                item,
              });
            }}
            aria-label="more"
          >
            <MoreHorizIcon fontSize="small" sx={{ color: palette.primary.main }} />
          </IconButton>
          <div>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              {renderMenuList(item.level - 1, item.data_set_ids?.length === 0).map((menu) => {
                return (
                  <MenuItem
                    onClick={() => {
                      menu.onClick();
                      setAnchorEl(null);
                    }}
                    key={menu.text}
                  >
                    {menu.icon}
                    <div
                      style={{
                        marginLeft: 5,
                      }}
                    >
                      {menu.text}
                    </div>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
      </div>
    </PaddingContainer>
  );
};

const DndNav = ({
  onDragWellList,
  setActiveDialog,
  dataSets,
  projects,
  mappedItemKeyTotal,
  onSelectItem,
  selectedKeys,
  onDragEnd,
}: DnDProjectTree) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const treeData = useMemo(() => {
    if (dataSets && projects) return transformProjectsToTreeNodes(projects, dataSets);
    return [];
  }, [dataSets, projects]);

  const onExpand = useCallback(
    (key: string, forceOpen?: boolean) => {
      const newExpandedKeys = [...expandedKeys];
      const currentKeyIndex = expandedKeys.indexOf(key);
      if (currentKeyIndex === -1 || forceOpen) {
        newExpandedKeys.push(key);
      } else {
        newExpandedKeys.splice(currentKeyIndex, 1);
      }
      setExpandedKeys(newExpandedKeys);
    },
    [expandedKeys]
  );

  const treeInstance = React.useRef<any>();

  // this is to make sure we can drag between tree
  React.useLayoutEffect(() => {
    if (treeInstance.current) {
      const onNodeDragEnter = treeInstance.current.onNodeDragEnter;

      if (typeof onNodeDragEnter === "function") {
        treeInstance.current.onNodeDragEnter = function (...argv: any[]) {
          onDragWellList(argv[1].props.data);
          if (treeInstance.current.dragNode) {
            return onNodeDragEnter.call(this, ...argv);
          }
        };
      }
    }
  }, [onDragWellList]);

  const renderDatasetItem = useCallback(
    (item: any) => {
      return (
        <DndItem
          setActiveDialog={setActiveDialog}
          mappedItemKeyTotal={mappedItemKeyTotal}
          expandedKeys={expandedKeys}
          item={item}
          onExpand={onExpand}
          onSelectItem={onSelectItem}
          selectedKeys={selectedKeys}
          key={item.key}
        />
      );
    },
    [expandedKeys, mappedItemKeyTotal, onExpand, onSelectItem, selectedKeys, setActiveDialog]
  );

  return (
    <Container>
      <Tree
        ref={treeInstance}
        onDragStart={(info: any) => {
          const item = info.node;
          if (item.type === "dataset") {
            onSelectItem({
              type: item.type,
              id: item.parents[0],
              prevSelection: item.selected,
              groupId: item.parents,
              dataSetId: item.id,
              key: item.key,
              item,
              isDrag: true,
            });
          } else {
            onSelectItem({
              type: item.type,
              id: item.id,
              prevSelection: item.selected,
              key: item.key,
              item,
            });
          }
        }}
        expandedKeys={expandedKeys}
        onDrop={onDragEnd}
        draggable
        virtual
        // @ts-ignore
        treeData={treeData}
        titleRender={(item) => renderDatasetItem(item)}
      />
    </Container>
  );
};

export default DndNav;
